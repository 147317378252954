import {
  Col,
  Row,
  TabContent,
  TabPane,
  Breadcrumb,
  BreadcrumbItem
} from 'reactstrap';
import { Link } from 'react-router-dom';
import Button from 'components/Button';
import Form from 'components/Form';
import TextField from 'components/TextField';
import { Loader } from 'react-bootstrap-typeahead';
import ImageUpload from 'components/ImageUpload';
import AsyncTypeaheadField from 'components/AsyncTypeaheadField';
import PasswordField from 'components/PasswordField';
import ImagePreview from 'components/ImagePreview';
import SelectField from 'components/SelectField';
import useControllerUsersFormPage from './_presenters/useControllerUsersFormPage/useControllerUsersFormPage';
import NavUsersFormPage from './_presenters/enum/navUsersFormPage';
import classNames from 'classnames';
import Modal from 'components/Modal';

const CustomerUsersCreatePage = () => {
  const {
    formMethods,
    accessPoliciesOptions,
    accessPolicyRefetch,
    isLoadingPolicies,
    isSaving,
    isFetching,
    userCanPerformAction,
    userCanDelete,
    isDeleting,
    handleOnDelete,
    showDeleteConfirmation,
    setShowDeleteConfirmation,
    params,
    controlPrincipalUser,
    customerUsers,
    handleOnSubmit,
    refetch,
    activeTab,
    setActiveTab
  } = useControllerUsersFormPage();

  return (
    <>
      <div className="mx-3">
        <Form onSubmit={handleOnSubmit} {...formMethods}>
          <Row className="d-flex align-items-center">
            <Col className="mb-3">
              <Breadcrumb className="mt-4">
                <BreadcrumbItem active>
                  <Link to="/customer/users" className="text-decoration-none">
                    Usuários
                  </Link>
                </BreadcrumbItem>
                <BreadcrumbItem className="text-gray-600 fw-medium">
                  {(params.id && 'Detalhes da conta') || 'Cadastrar usuário'}
                </BreadcrumbItem>
              </Breadcrumb>
              {(params.id && (
                <span className="small-typography text-gray-600">
                  Todas as informações sobre o seu usuário concentram-se aqui.
                </span>
              )) || (
                <span className="small-typography text-gray-600">
                  Cadastre e veja as informações sobre os seus usuários.
                </span>
              )}
            </Col>
            <Col className="text-end">
              {userCanPerformAction && !params.id && (
                <Button
                  color="primary"
                  type="submit"
                  className="btn btn-primary text-white button-register"
                >
                  Cadastrar
                </Button>
              )}
            </Col>
          </Row>
          {params.id && (
            <div>
              <div className="d-flex align-items-center mt-2">
                <div className="navlink">
                  <div
                    className={classNames('me-5 navlink', {
                      active: activeTab === NavUsersFormPage.INFORMATIONS
                    })}
                    onClick={() => setActiveTab(NavUsersFormPage.INFORMATIONS)}
                  >
                    Informações
                  </div>
                </div>
                <div className="navlink">
                  <div
                    className={classNames('navlink', {
                      active: activeTab === NavUsersFormPage.CREDENTIALS
                    })}
                    onClick={() => setActiveTab(NavUsersFormPage.CREDENTIALS)}
                  >
                    Credenciais
                  </div>
                </div>
              </div>
              <hr className="color-line" />
              <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                  <div className="mt-3">
                    <div className="shadow bg-white form mb-3">
                      {userCanPerformAction && (
                        <div className="d-flex align-items-center p-3">
                          <ImagePreview imageUrl={customerUsers?.picture} />
                          <ImageUpload
                            url={`/customer/users/${params.id}/picture`}
                            onSuccess={() => refetch()}
                            keyName="picture"
                          />
                        </div>
                      )}
                    </div>
                    <div className="shadow bg-white form">
                      <Col className="mx-4 mt-3">
                        <TextField
                          name="firstName"
                          label="Nome"
                          placeholder="Digite o nome"
                          disabled={!userCanPerformAction}
                          defaultValue={customerUsers?.firstName}
                        />
                        <TextField
                          name="lastName"
                          label="Sobrenome"
                          placeholder="Digite o sobrenome"
                          disabled={!userCanPerformAction}
                          defaultValue={customerUsers?.lastName}
                        />
                        <TextField
                          name="email"
                          label="E-mail"
                          placeholder="Digite o e-mail"
                          disabled={!userCanPerformAction}
                          defaultValue={customerUsers?.email}
                        />
                        <div className="mb-3">
                          <SelectField
                            name="principalUser"
                            yesNoOptions
                            label="Super Usuário"
                            disabled={!userCanPerformAction}
                            defaultValue={String(controlPrincipalUser)}
                            onChange={(value) =>
                              formMethods.setValue('principalUser', value)
                            }
                          />
                        </div>
                        <div className="mb-3">
                          <AsyncTypeaheadField
                            isLoading={isLoadingPolicies}
                            name="policies"
                            label="Políticas de acesso"
                            placeholder="Selecione"
                            options={accessPoliciesOptions}
                            onSearch={(search: string) =>
                              accessPolicyRefetch({
                                params: { search }
                              } as any)
                            }
                            disabled={
                              !userCanPerformAction ||
                              controlPrincipalUser === 'true'
                            }
                            multiple
                          />
                        </div>
                        <SelectField
                          name="isActive"
                          label="Usuário ativo"
                          disabled={!userCanPerformAction}
                          yesNoOptions
                          defaultValue={
                            String(customerUsers?.isActive) || String(true)
                          }
                        />
                      </Col>
                    </div>
                    <div className="d-flex justify-content-between align-items-center mt-4">
                      {userCanDelete && (
                        <Button
                          type="button"
                          color="red-400"
                          loading={isDeleting}
                          className="me-2 text-white"
                          onClick={() => setShowDeleteConfirmation(true)}
                        >
                          Excluir conta
                        </Button>
                      )}
                      {userCanPerformAction && (
                        <Button
                          color="primary"
                          className="me-2 text-white"
                          loading={isSaving}
                          type="submit"
                        >
                          Salvar as alterações
                        </Button>
                      )}
                    </div>
                  </div>
                </TabPane>
                <TabPane tabId="2">
                  <div className="mt-2">
                    <PasswordField
                      label="Senha Nova"
                      type="password"
                      name="password"
                      placeholder="Digite a sua senha nova"
                    />
                    <PasswordField
                      label="Confirme a senha nova"
                      type="password"
                      name="confirmPassword"
                      placeholder="Confirme a sua senha nova"
                    />
                  </div>
                  <div className="text-end">
                    {userCanPerformAction && (
                      <Button
                        color="primary"
                        type="submit"
                        className="btn btn-primary text-white"
                      >
                        Salvar alterações
                      </Button>
                    )}
                  </div>
                </TabPane>
              </TabContent>
            </div>
          )}
          {isFetching && <Loader />}
          {!params.id && (
            <div className="shadow bg-white form">
              <Col className="mx-4 mt-3">
                <TextField
                  name="firstName"
                  label="Nome"
                  placeholder="Digite o nome"
                  disabled={!userCanPerformAction}
                  defaultValue={customerUsers?.firstName}
                />
                <TextField
                  name="lastName"
                  label="Sobrenome"
                  placeholder="Digite o sobrenome"
                  disabled={!userCanPerformAction}
                  defaultValue={customerUsers?.lastName}
                />
                <TextField
                  name="email"
                  label="E-mail"
                  placeholder="Digite o e-mail"
                  disabled={!userCanPerformAction}
                  defaultValue={customerUsers?.email}
                />
                <PasswordField
                  label="Digite a sua senha"
                  type="password"
                  name="password"
                  placeholder="Digite a sua senha"
                />
                <PasswordField
                  label="Confirme a sua senha"
                  type="password"
                  name="confirmPassword"
                  placeholder="Confirme a sua senha"
                />
                <div className="mb-3">
                  <SelectField
                    name="principalUser"
                    yesNoOptions
                    label="Super Usuário"
                    disabled={!userCanPerformAction}
                    value={String(controlPrincipalUser)}
                    onChange={(value) =>
                      formMethods.setValue('principalUser', value)
                    }
                  />
                </div>
                <div className="mb-3">
                  <AsyncTypeaheadField
                    isLoading={isLoadingPolicies}
                    name="policies"
                    label="Políticas de acesso"
                    placeholder="Selecione"
                    options={accessPoliciesOptions}
                    onSearch={(search: string) =>
                      accessPolicyRefetch({
                        params: { search }
                      } as any)
                    }
                    disabled={
                      !userCanPerformAction || controlPrincipalUser === 'true'
                    }
                    multiple
                  />
                </div>
                <SelectField
                  name="isActive"
                  label="Usuário ativo"
                  disabled={!userCanPerformAction}
                  yesNoOptions
                  value={String(customerUsers?.isActive)}
                />
              </Col>
            </div>
          )}
        </Form>
      </div>
      <Modal
        primaryButtonAction={handleOnDelete}
        primaryButtonLabel="Excluir"
        title="Atenção!"
        isOpen={showDeleteConfirmation}
        showCloseButton={false}
        toggle={() => setShowDeleteConfirmation((x) => !x)}
        secondaryButtonAction={() => setShowDeleteConfirmation(false)}
        secondaryButtonLabel="Cancelar"
      >
        <p>
          Deseja realmente excluir o usuário? <br />
          <small>Essa alteração não poderá ser revertida.</small>
        </p>
      </Modal>
    </>
  );
};

export default CustomerUsersCreatePage;
