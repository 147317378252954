import React, { useCallback } from 'react';
import { useApiGet } from 'hooks/useApi';
import Pagination from 'models/Pagination';
import UrlExternal from 'models/UrlExternal';

const useControllerUrlExternalListDragPage = () => {
  const [filteredData, setFilteredData] = React.useState({
    pageSize: 10,
    page: 1,
    search: ''
  });

  const { data: urlExternal, isLoading } = useApiGet<Pagination<UrlExternal>>(
    '/media-manager/url-external',
    {
      params: filteredData
    }
  );

  const handleSearch = useCallback(
    (search: string) => {
      setFilteredData({ ...filteredData, search });
    },
    [filteredData]
  );
  const handlePageChange = useCallback(
    (page: number) => {
      setFilteredData({ ...filteredData, page });
    },
    [filteredData]
  );

  return {
    filteredData,
    urlExternal,
    isLoading,
    handleSearch,
    handlePageChange,
    setFilteredData
  };
};

export default useControllerUrlExternalListDragPage;
