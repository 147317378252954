import { useCallback, useMemo, useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useApiGet, useApiPost, useApiPut } from 'hooks/useApi';
import TypeResource from 'enum/TypeResource';
import TypeAction from 'enum/TypeAction';
import useFieldSetErrors from 'hooks/useFieldSetErrors';
import { useToast } from 'hooks/useToast';
import CustomerCompanies from 'models/CustomerCompanies';
import useUser from 'hooks/useUser';
import CustomerUser from 'models/CustomerUser';
import Pagination from 'models/Pagination';
import NavCompaniesFormPage from '../enum/navCompaniesFormPage';
import apiPaths from 'constants/apiPaths';
import { Addresses } from 'models/CustomerCompanies';

const yupSchema = yup
  .object({
    name: yup.string().required('O nome fantasia é obrigatório.'),
    email: yup.string().email().required(),
    razaoSocial: yup.string().required('A razão social é obrigatória.'),
    addresses: yup
      .array()
      .of(
        yup.object().shape({
          street: yup.string(),
          number: yup.string(),
          complement: yup.string(),
          district: yup.string(),
          city: yup.string(),
          state: yup.string(),
          zipCode: yup.string(),
          mainAddress: yup.string().oneOf(['true', 'false']).default('false'),
          billingAddress: yup
            .string()
            .oneOf(['true', 'false'])
            .default('false')
            .when('mainAddress', {
              is: 'true',
              then: (schema) => schema.default('true')
            })
        })
      )
      .test(
        'addresses',
        'Endereço principal e de cobrança devem ser informados',
        (value) => {
          return (
            value?.length === 2 ||
            (value?.length === 1 && value[0].mainAddress === 'true')
          );
        }
      ),
    users: yup.array(),
    phone: yup.string(),
    cellPhone: yup.string(),
    cnpj: yup.string().required('O CNPJ é obrigatório.'),
    stateRegistration: yup.string(),
    municipalRegistration: yup.string()
  })
  .required();

const useControllerCustomerCompaniesFormPage = () => {
  const params = useParams();
  const user = useUser();
  const navigate = useNavigate();
  const { addToast } = useToast();
  const [activeTab, setActiveTab] = useState<string>(
    NavCompaniesFormPage.GENRAL_DATA
  );
  const [isNext, setIsNext] = useState(true);
  const { data, isLoading: isFetching } = useApiGet<CustomerCompanies>(
    apiPaths.customerCompanies,
    { id: params.id, enabled: !!params.id }
  );
  const { mutate: mutateCreate, isPending: isCreating } = useApiPost<
    CustomerCompanies
  >(apiPaths.customerCompanies);
  const { mutate: mutateUpdate, isPending: isUpdating } = useApiPut<
    CustomerCompanies
  >(apiPaths.customerCompanies, params.id);

  const {
    data: customerUsersData,
    refetch: customerUsersRefetch,
    isLoading: isLoadingCustomerUsers
  } = useApiGet<Pagination<CustomerUser>>(apiPaths.customerUsers);

  const mutate = params.id ? mutateUpdate : mutateCreate;

  const userCanPerformAction = useMemo(() => {
    if (params.id) {
      return user?.resources?.[TypeResource.COMPANIES]?.includes(
        TypeAction.UPDATE
      );
    } else {
      return user?.resources?.[TypeResource.COMPANIES]?.includes(
        TypeAction.CREATE
      );
    }
  }, [user, params.id]);

  const isSaving = isCreating || isUpdating;

  const customerCompanies = params.id ? data : undefined;

  const formMethods = useForm({
    resolver: yupResolver(yupSchema)
  });

  const mainAdressControl = useWatch({
    control: formMethods.control,
    name: 'addresses.0.mainAddress',
    defaultValue: 'false'
  });

  const watchedFields = useWatch({
    control: formMethods.control
  });

  const canProceedToAddress = useMemo(() => {
    const { name, razaoSocial, cnpj, users } = watchedFields;
    return !!name && !!razaoSocial && !!cnpj && users?.length;
  }, [watchedFields]);

  const canSubmit = useMemo(() => {
    const { email, phone, addresses = [] } = watchedFields;

    const addressValid =
      addresses[0]?.street && addresses[0]?.city && addresses[0]?.state;

    return !!email && !!phone && addressValid;
  }, [watchedFields]);

  const handleNext = () => {
    if (activeTab === NavCompaniesFormPage.GENRAL_DATA && canProceedToAddress) {
      setActiveTab(NavCompaniesFormPage.ADDRESS);
      setIsNext(false);
    }
  };

  const handlePrevious = () => {
    if (activeTab === NavCompaniesFormPage.ADDRESS) {
      setActiveTab(NavCompaniesFormPage.GENRAL_DATA);
      setIsNext(true);
    }
  };

  const updateActiveTab = (tab: string) => {
    setActiveTab(tab);
    setIsNext(tab === NavCompaniesFormPage.GENRAL_DATA);
  };

  const onError = useFieldSetErrors(formMethods.setError);

  const handleOnSubmit = useCallback(
    (data: CustomerCompanies) => {
      if (!userCanPerformAction) return;
      if (!isNext && canSubmit) {
        const dataUsers = data?.users?.map((user: any) => user.value);
        const dataAddresses: Addresses[] = (data?.addresses || []).reduce(
          (acc: Addresses[], address: any, index: number) => {
            if (mainAdressControl === 'true') {
              if (index === 0) {
                acc.push({
                  street: address?.street,
                  number: address?.number,
                  complement: address?.complement,
                  district: address?.district,
                  city: address?.city,
                  state: address?.state,
                  zipCode: address?.zipCode,
                  mainAddress: true,
                  billingAddress: true
                });
              }
            } else {
              acc.push({
                street: address?.street,
                number: address?.number,
                complement: address?.complement,
                district: address?.district,
                city: address?.city,
                state: address?.state,
                zipCode: address?.zipCode,
                mainAddress: index === 0,
                billingAddress: index === 1
              });
            }
            return acc;
          },
          []
        );
        mutate(
          { ...data, users: dataUsers, addresses: dataAddresses },
          {
            onError,
            onSuccess: () => {
              addToast('success', 'Empresa salva com sucesso');
              navigate(apiPaths.customerCompanies);
            }
          }
        );
      }
    },
    [
      addToast,
      mutate,
      navigate,
      onError,
      userCanPerformAction,
      mainAdressControl,
      isNext,
      canSubmit
    ]
  );

  const customerUsersOptions = useMemo(() => {
    const data = customerUsersData?.items || [];
    return data.map((user) => ({
      label: user.firstName + ' ' + user.lastName,
      value: user.id
    }));
  }, [customerUsersData]);

  useEffect(() => {
    if (customerCompanies) {
      formMethods.reset({
        name: customerCompanies.name,
        email: customerCompanies.email,
        razaoSocial: customerCompanies.razaoSocial,
        phone: customerCompanies.phone,
        cellPhone: customerCompanies.cellPhone,
        cnpj: customerCompanies.cnpj,
        stateRegistration: customerCompanies.stateRegistration,
        municipalRegistration: customerCompanies.municipalRegistration,
        users: customerCompanies.users?.map((user) => ({
          label: user.firstName + ' ' + user.lastName,
          value: user.id
        })),
        addresses: customerCompanies.addresses?.map((address) => ({
          ...address,
          mainAddress: address.mainAddress || 'false',
          billingAddress: address.billingAddress || 'false'
        }))
      } as any);
    }
  }, [customerCompanies, formMethods]);

  return {
    formMethods,
    handleOnSubmit,
    customerUsersOptions,
    isFetching,
    isSaving,
    customerCompanies,
    userCanPerformAction,
    isLoadingCustomerUsers,
    customerUsersRefetch,
    params,
    mainAdressControl,
    canProceedToAddress,
    activeTab,
    setActiveTab,
    isNext,
    setIsNext,
    canSubmit,
    handleNext,
    handlePrevious,
    updateActiveTab
  };
};

export default useControllerCustomerCompaniesFormPage;
