import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useApiDelete, useApiGet, useApiPost, useApiPut } from 'hooks/useApi';
import TypeResource from 'enum/TypeResource';
import TypeAction from 'enum/TypeAction';
import useFieldSetErrors from 'hooks/useFieldSetErrors';
import { useToast } from 'hooks/useToast';
import { MediaFileAndCampaignPlaylist, Playlist } from 'models/Playlist';
import useUser from 'hooks/useUser';
import { Option } from 'components/AsyncTypeaheadField';
import ScreenOrientation from 'enum/ScreenOrientation';
import DeviceGroup from 'models/DeviceGroup';
import Pagination from 'models/Pagination';
import MediaFile from 'models/MediaFile';
import { Campaign } from 'models/MediaFileCampaign';
import InternalsNews from 'models/InternalsNews';
import UrlExternal from 'models/UrlExternal';
import ToastType from 'enum/ToastType';
import { EditedMedia, MediaEditorRef } from 'components/MediaEditor';
import apiPaths from 'constants/apiPaths';

type FormValue = {
  name: string;
  active: boolean;
  deviceGroup: Option | null;
  screenOrientation: ScreenOrientation;
  startDate: string;
  endDate: string;
};

type MutateMediaFileAndCampaignPlaylist = Omit<
  MediaFileAndCampaignPlaylist,
  'campaign' | 'internalNews' | 'externalUrl'
> & {
  mediaFile?: any;
  campaign?: string;
  internalNews?: string;
  externalUrl?: string;
};

const yupSchema = yup.object({
  name: yup.string().required('O nome é obrigatório.'),
  deviceGroup: yup.object().required('O grupo é obrigatório.'),
  screenOrientation: yup
    .mixed<ScreenOrientation>()
    .oneOf(Object.values(ScreenOrientation))
    .required(),
  active: yup.boolean()
});

const useControllerPlaylistEditPage = () => {
  const params = useParams();
  const user = useUser();
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const navigate = useNavigate();
  const { addToast } = useToast();
  const refMediaEditor = useRef<MediaEditorRef>(null);

  const { data, isLoading: isFetching, refetch } = useApiGet<Playlist>(
    `/media-manager/playlists`,
    {
      id: params.id,
      enabled: !!params.id
    }
  );
  const { mutate: mutateCreate, isPending: isCreating } = useApiPost<Playlist>(
    apiPaths.playlists
  );
  const { mutate: mutateUpdate, isPending: isUpdating } = useApiPut<Playlist>(
    apiPaths.playlists,
    params.id
  );

  const { mutate: mutateDelete, isPending: isDeleting } = useApiDelete(
    apiPaths.playlists,
    params.id
  );

  const {
    mutate: mutatePlaylistItems,
    isPending: isUpdatingPlaylistItems
  } = useApiPut<Playlist>(
    `/media-manager/playlists/${params.id}/items/orders`,
    '',
    {
      onSuccess: refetch
    }
  );

  const {
    mutate: mutateCreatePlaylistItems,
    isPending: isCreatingPlaylistItems
  } = useApiPost<MutateMediaFileAndCampaignPlaylist>(
    `/media-manager/playlists/${params.id}/items`
  );

  const {
    data: deviceGroupData,
    refetch: deviceGroupRefetch,
    isLoading: isLoadingDeviceGroup
  } = useApiGet<Pagination<DeviceGroup>>(apiPaths.deviceGroup);

  const mutate = params.id ? mutateUpdate : mutateCreate;

  const userCanPerformAction = useMemo(() => {
    if (params.id) {
      return user?.resources?.[TypeResource.PLAYLISTS]?.includes(
        TypeAction.UPDATE
      );
    } else {
      return user?.resources?.[TypeResource.PLAYLISTS]?.includes(
        TypeAction.CREATE
      );
    }
  }, [user, params.id]);

  const userCanDelete = useMemo(() => {
    return user?.resources?.[TypeResource.PLAYLISTS]?.includes(
      TypeAction.DELETE
    );
  }, [user]);

  const isSaving = isCreating || isUpdating;

  const playlist = params.id ? data : undefined;

  const formMethods = useForm<FormValue>({
    resolver: yupResolver(yupSchema as yup.ObjectSchema<FormValue>),
    defaultValues: {
      name: '',
      active: true,
      deviceGroup: null,
      screenOrientation: ScreenOrientation.LANDSCAPE
    }
  });

  const onError = useFieldSetErrors(formMethods.setError);

  const handleOnSubmit = useCallback(
    (data: FormValue) => {
      if (!userCanPerformAction) return;
      const payload = {
        deviceGroup: data.deviceGroup?.value!,
        name: data.name,
        active: data.active,
        screenOrientation: data.screenOrientation,
        startDate: data.startDate,
        endDate: data.endDate
      } as any;
      mutate(payload, {
        onError,
        onSuccess: (payload) => {
          addToast(ToastType.SUCCESS, 'Playlist salva com sucesso');
          navigate('/media-manager/corporate-tv?tab=playlists');
        }
      });
    },
    [userCanPerformAction, mutate, onError, addToast, navigate]
  );

  const handleOnDelete = useCallback(() => {
    if (!params.id || !userCanDelete) return;
    mutateDelete(null as any, {
      onError: (response: any) => {
        addToast(
          ToastType.ERROR,
          response.errors?.detail?.toString() || 'Erro ao excluir a playlist'
        );
      },
      onSuccess: () => {
        addToast(ToastType.SUCCESS, 'Playlist excluída com sucesso');
        navigate('/media-manager/corporate-tv?tab=playlists');
      }
    });
  }, [addToast, mutateDelete, navigate, params.id, userCanDelete]);

  const deviceGroupOptions = useMemo(() => {
    const data = deviceGroupData?.items || [];
    return data.map(
      (device) =>
        ({
          label: device.name,
          value: device.id
        } as any)
    );
  }, [deviceGroupData]);

  const typeOptionsOrientation = [
    {
      value: ScreenOrientation.PORTRAIT,
      label: 'Retrato'
    },
    {
      value: ScreenOrientation.LANDSCAPE,
      label: 'Paisagem'
    }
  ];

  const handleOnEditMedia = useCallback(
    (mediaFile: MediaFile) => {
      refMediaEditor?.current?.showEditor(mediaFile, {
        orientation: playlist?.screenOrientation!
      });
    },
    [playlist?.screenOrientation]
  );

  const handleReOrderMedias = useCallback(
    (items: MediaFileAndCampaignPlaylist[]) => {
      mutatePlaylistItems(
        items.map((item) => ({
          itemId: item.id,
          order: item.order
        })) as any
      );
    },
    [mutatePlaylistItems]
  );

  const handleOnFinish = useCallback(
    (mediaFile: MediaFile, { url, cloudinaryJson }: EditedMedia) => {
      const newOrder = playlist?.items?.length || 0;
      mutateCreatePlaylistItems(
        {
          editedFile: url,
          order: newOrder || 0,
          duration: 5,
          cloudinaryJson,
          active: true,
          mediaFile: mediaFile.id
        },
        {
          onSuccess: () => {
            refetch();
          }
        }
      );
    },
    [mutateCreatePlaylistItems, playlist, refetch]
  );

  const handleOnCampaignFinish = useCallback(
    (campaign: Campaign) => {
      mutateCreatePlaylistItems(
        {
          order: 0,
          duration: 5,
          cloudinaryJson: {},
          active: true,
          campaign: campaign.id!
        },
        {
          onSuccess: () => {
            refetch();
          }
        }
      );
    },
    [mutateCreatePlaylistItems, refetch]
  );

  const handleOnInternalsNewsFinish = useCallback(
    (news: InternalsNews) => {
      mutateCreatePlaylistItems(
        {
          order: 0,
          duration: 5,
          cloudinaryJson: {},
          active: true,
          internalNews: news.id!
        },
        {
          onSuccess: () => {
            refetch();
          }
        }
      );
    },
    [mutateCreatePlaylistItems, refetch]
  );

  const handleOnUrlExternalFinish = useCallback(
    (url: UrlExternal) => {
      mutateCreatePlaylistItems(
        {
          order: 0,
          duration: 5,
          cloudinaryJson: {},
          active: true,
          externalUrl: url.id!
        },
        {
          onSuccess: () => {
            refetch();
          }
        }
      );
    },
    [mutateCreatePlaylistItems, refetch]
  );

  const [mediaToPreview, setMediaToPreview] = useState<
    MediaFileAndCampaignPlaylist
  >();

  useEffect(() => {
    if (playlist) {
      formMethods.reset({
        name: playlist.name,
        active: playlist.active,
        screenOrientation: playlist.screenOrientation,
        deviceGroup: playlist.deviceGroup
          ? {
              label: playlist.deviceGroup?.name,
              value: playlist.deviceGroup?.id
            }
          : null,
        startDate: playlist.startDate,
        endDate: playlist.endDate
      } as any);
    }
  }, [playlist, formMethods]);

  const [selectedPlaylist, setSelectedPlaylist] = useState<Playlist | null>(
    null
  );
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);

  const openPreview = useCallback((playlist: Playlist) => {
    setSelectedPlaylist(playlist);
    setIsPreviewOpen(true);
  }, []);

  const closePreview = useCallback(() => {
    setIsPreviewOpen(false);
    setSelectedPlaylist(null);
  }, []);

  const typeOptionsActive = [
    { value: true, label: 'Ativo' },
    {
      value: false,
      label: 'Inativo'
    }
  ];

  return {
    formMethods,
    userCanPerformAction,
    userCanDelete,
    isSaving,
    playlist,
    deviceGroupOptions,
    typeOptionsOrientation,
    mediaToPreview,
    isPreviewOpen,
    showDeleteConfirmation,
    handleOnSubmit,
    handleOnDelete,
    handleOnEditMedia,
    handleReOrderMedias,
    handleOnFinish,
    handleOnCampaignFinish,
    handleOnInternalsNewsFinish,
    handleOnUrlExternalFinish,
    openPreview,
    closePreview,
    isFetching,
    deviceGroupRefetch,
    isLoadingDeviceGroup,
    isDeleting,
    isUpdatingPlaylistItems,
    isCreatingPlaylistItems,
    isUpdating,
    selectedPlaylist,
    setMediaToPreview,
    params,
    setShowDeleteConfirmation,
    refMediaEditor,
    typeOptionsActive
  };
};

export default useControllerPlaylistEditPage;
