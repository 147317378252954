import React, { useState } from 'react';
// @ts-ignore
import { useDrag } from 'react-dnd';
import InternalsNews from 'models/InternalsNews';
import Button from 'components/Button';
import moment from 'moment';
import InternalsNewsPreview from './InternalsNewsPreview';

type InternalsNewsItemProps = {
  internalsNews: InternalsNews;
};

const InternalsNewsItem = ({ internalsNews }: InternalsNewsItemProps) => {
  const [{ isDragging }, drag] = useDrag({
    type: 'InternalsNews',
    item: { id: internalsNews.id },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  });

  const [
    selectedInternalsNews,
    setSelectedInternalsNews
  ] = useState<InternalsNews | null>(null);

  const [isPreviewOpen, setIsPreviewOpen] = useState(false);

  const openPreview = (internalsNews: InternalsNews) => {
    setSelectedInternalsNews(internalsNews);
    setIsPreviewOpen(true);
  };

  const closePreview = () => {
    setIsPreviewOpen(false);
    setSelectedInternalsNews(null);
  };

  return (
    <div ref={drag}>
      <div>
        {!isDragging ? (
          <div className="d-flex justify-content-between align-items-center border-top p-4">
            <div className="mt-2 w-100 me-4">
              <small>Título </small>
              <br />
              <b>{internalsNews.title}</b>
            </div>
            <div className="mt-2 w-100 me-4">
              <small>Preview do texto </small>
              <br />
              <b className="text-truncate-wrap">{internalsNews.description}</b>
            </div>
            <div className="mt-2 w-100 me-4">
              <small>Data </small>
              <br />
              <b>{moment(internalsNews.startDate).format('LLL')}</b>
            </div>
            <div className="mt-2 w-100 ms-4">
              <small>Notícia </small>
              <br />
              <Button
                type="button"
                color="transparent"
                icon="collection-play"
                className="text-primary"
                onClick={() => openPreview(internalsNews)}
              />
            </div>
          </div>
        ) : (
          <div className="campaign-medias-list-item">
            <div className="bg-dark-subtle"></div>
          </div>
        )}
      </div>
      <InternalsNewsPreview
        isOpen={isPreviewOpen}
        onClose={closePreview}
        news={selectedInternalsNews!}
      />
    </div>
  );
};

export default InternalsNewsItem;
