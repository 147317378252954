import { useCallback, useEffect, useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useApiPut } from 'hooks/useApi';
import useFieldSetErrors from 'hooks/useFieldSetErrors';
import { useToast } from 'hooks/useToast';
import { User } from 'models/User';
import SessionContext from 'contexts/session';
import NavUserProfilePage from '../enum/navUserProfilePage';
import apiPaths from 'constants/apiPaths';

const yupSchema = yup
  .object({
    email: yup.string().email().required(),
    firstName: yup.string().required('O nome é obrigatório.'),
    lastName: yup.string().required('O sobrenome é obrigatório.'),
    password: yup.string().default(''),
    confirmPassword: yup.string().default(''),
    currentPassword: yup.string().default('')
  })
  .required();

const useControllerUsersFormPage = () => {
  const { user, setUser } = useContext(SessionContext);
  const { addToast } = useToast();

  const { mutate, isPending: isUpdating } = useApiPut<User>(
    apiPaths.userProfile
  );

  const formMethods = useForm({
    resolver: yupResolver(yupSchema)
  });

  const onError = useFieldSetErrors(formMethods.setError);

  const handleOnSubmit = useCallback(
    (data: User) => {
      mutate(data, {
        onError,
        onSuccess: (dataUser: User) => {
          setUser(dataUser);
          addToast('success', 'Alteração salva com sucesso');
        }
      });
    },
    [addToast, mutate, onError, setUser]
  );

  useEffect(() => {
    if (user) {
      formMethods.reset({
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email
      } as any);
    }
  }, [user, formMethods]);

  const [activeTab, setActiveTab] = useState<string>(
    NavUserProfilePage.INFORMATIONS
  );

  return {
    formMethods,
    handleOnSubmit,
    activeTab,
    setActiveTab,
    isUpdating,
    user,
    setUser
  };
};

export default useControllerUsersFormPage;
