import { Link } from 'react-router-dom';
import { Col, Row, BreadcrumbItem, Breadcrumb } from 'reactstrap';
import Button from 'components/Button';
import Form from 'components/Form';
import TextField from 'components/TextField';
import { Loader } from 'react-bootstrap-typeahead';
import ResourcesField from 'pages/AccessPolicies/ResourcesField';
import Modal from 'components/Modal';
import useControlllerAccessPoliciesFormPage from './_presenters/useControllerPoliciesFormPage/useControllerPoliciesFormPage';

const AccessPoliciesFormPage = () => {
  const {
    showDeleteConfirmation,
    setShowDeleteConfirmation,
    params,
    isFetching,
    isDeleting,
    userCanPerformAction,
    userCanDelete,
    isSaving,
    accessPolicy,
    formMethods,
    handleOnSubmit,
    handleOnDelete,
    canSubmit,
    goOut
  } = useControlllerAccessPoliciesFormPage();

  return (
    <>
      <div className="mx-3">
        <Form onSubmit={handleOnSubmit} {...formMethods}>
          <Row className="d-flex align-items-center">
            <Col className="mb-3">
              <Breadcrumb className="mt-4">
                <BreadcrumbItem active>
                  <Link
                    to={'/access-policies'}
                    className="text-decoration-none"
                  >
                    Políticas de acesso
                  </Link>
                </BreadcrumbItem>
                <BreadcrumbItem className="text-gray-600 fw-medium">
                  {params.id
                    ? accessPolicy?.name
                    : 'Cadastrar política de acesso'}
                </BreadcrumbItem>
              </Breadcrumb>
              <h4 className="fw-bolder">
                {params.id ? accessPolicy?.name : 'Política de acesso'}
              </h4>
              <span className="small-typography text-gray-600">
                {params.id
                  ? 'Realize a gestão da política de acesso.'
                  : 'Cadastre uma nova política de acesso.'}
              </span>
            </Col>
            <Col className="d-flex align-items-center justify-content-end">
              {userCanDelete && params.id && (
                <Button
                  type="button"
                  loading={isDeleting}
                  className="button-outline delete me-2"
                  onClick={() => setShowDeleteConfirmation(true)}
                >
                  Excluir
                </Button>
              )}
              {!params.id && (
                <Button className="button-outline me-2" onClick={goOut}>
                  Sair
                </Button>
              )}
              {userCanPerformAction && (
                <Button
                  type="submit"
                  className="btn btn-primary text-white button-register"
                  loading={isSaving}
                  disabled={!canSubmit}
                >
                  Salvar
                </Button>
              )}
            </Col>
          </Row>
          {isFetching ? (
            <Loader />
          ) : (
            <>
              <TextField
                name="name"
                label="Nome da política"
                placeholder="Digite o nome da política"
                disabled={!userCanPerformAction}
                defaultValue={accessPolicy?.name}
              />
              <ResourcesField disabled={!userCanPerformAction} />
            </>
          )}
        </Form>
      </div>
      <Modal
        primaryButtonAction={handleOnDelete}
        primaryButtonLabel="Excluir"
        title="Atenção!"
        isOpen={showDeleteConfirmation}
        showCloseButton={false}
        toggle={() => setShowDeleteConfirmation((x) => !x)}
        secondaryButtonAction={() => setShowDeleteConfirmation(false)}
        secondaryButtonLabel="Cancelar"
      >
        <p>
          Desaja realmente excluir a política de acesso? <br />
          <small>Essa alteração não poderá ser revertida.</small>
        </p>
      </Modal>
    </>
  );
};

export default AccessPoliciesFormPage;
