import React, { useState } from 'react';
import { TabContent, TabPane } from 'reactstrap';
import MediaGrid from 'components/MediaGrid';
import classNames from 'classnames';
import CampaignListDragPage from '../CampaignListDragPage';
import InternalsNewsListDragPage from '../InternalsNewsListDragPage';
import UrlExternalListDragPage from '../UrlExternalListDragPage';

enum Pages {
  MEDIA_GRID = '1',
  CAMPAIGNS = '2',
  INTERNALS_NEWS = '3',
  URL_EXTERNAL = '4'
}

type PlaylistItemsPageProps = { playlistScreenOrientation: string };

const PlaylistItemsPage = ({
  playlistScreenOrientation
}: PlaylistItemsPageProps) => {
  const [activeTab, setActiveTab] = useState<string>(Pages.MEDIA_GRID);

  return (
    <div>
      <div className="shadow bg-white form mt-4">
        <div className="d-flex align-items-center mt-4">
          <div className="navlink mx-3">
            <div
              className={classNames('me-4 navlink', {
                active: activeTab === Pages.MEDIA_GRID
              })}
              onClick={() => setActiveTab(Pages.MEDIA_GRID)}
            >
              Mídias
            </div>
          </div>
          <div className="navlink">
            <div
              className={classNames('navlink me-4', {
                active: activeTab === Pages.CAMPAIGNS
              })}
              onClick={() => setActiveTab(Pages.CAMPAIGNS)}
            >
              Campanhas
            </div>
          </div>
          <div className="navlink">
            <div
              className={classNames('navlink me-4', {
                active: activeTab === Pages.INTERNALS_NEWS
              })}
              onClick={() => setActiveTab(Pages.INTERNALS_NEWS)}
            >
              Notícias Internas
            </div>
          </div>
          <div className="navlink">
            <div
              className={classNames('navlink', {
                active: activeTab === Pages.URL_EXTERNAL
              })}
              onClick={() => setActiveTab(Pages.URL_EXTERNAL)}
            >
              URL Externa
            </div>
          </div>
        </div>

        <TabContent activeTab={activeTab} className="mx-3">
          <TabPane tabId="1">
            <div className="mt-2">
              <MediaGrid title="Gerenciamento de Mídias" />
            </div>
          </TabPane>
          <TabPane tabId="2">
            <div className="mt-2">
              <CampaignListDragPage
                screenOrientation={playlistScreenOrientation}
              />
            </div>
          </TabPane>
          <TabPane tabId="3">
            <div className="mt-2">
              <InternalsNewsListDragPage
                screenOrientation={playlistScreenOrientation}
              />
            </div>
          </TabPane>
          <TabPane tabId="4">
            <div className="mt-2">
              <UrlExternalListDragPage />
            </div>
          </TabPane>
        </TabContent>
      </div>
    </div>
  );
};

export default PlaylistItemsPage;
