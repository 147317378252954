import { Col, Row, Spinner, Table, Collapse, CardBody, Card } from 'reactstrap';
import React, { useCallback, useState } from 'react';
import SearchInput from 'components/SearchInput';
import { useApiGet, useApiPost } from 'hooks/useApi';
import Pagination from 'models/Pagination';
import PaginationNav from 'components/Pagination';
import PageSizeSelector from 'components/PageSizeSelector';
import SelectField from 'components/SelectField';
import TextField from 'components/TextField';
import Form from 'components/Form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import Button from 'components/Button';
import TypeSupport from 'enum/TypeSupport';
import Faq from 'models/Faq';
import Icon from 'components/Icon';
import SupportContact from 'models/SupportContact';
import useFieldSetErrors from 'hooks/useFieldSetErrors';
import { useToast } from 'hooks/useToast';

const yupSchema = yup
  .object({
    subject: yup.mixed<TypeSupport>().oneOf(Object.values(TypeSupport)),
    message: yup.string()
  })
  .required();

const SupportFaqListPage = () => {
  const { addToast } = useToast();
  const [filteredData, setFilteredData] = React.useState({
    pageSize: 10,
    page: 1,
    search: ''
  });

  const { data: supportListPage, isLoading } = useApiGet<Pagination<Faq>>(
    '/support/faq',
    {
      params: filteredData
    }
  );

  const { mutate: supportContactMutation, isPending: isSaving } = useApiPost<
    SupportContact
  >('/support/contact');

  const handleSearch = useCallback(
    (search: string) => {
      setFilteredData({ ...filteredData, search });
    },
    [filteredData]
  );

  const handlePageChange = useCallback(
    (page: number) => {
      setFilteredData({ ...filteredData, page });
    },
    [filteredData]
  );

  const [openIndexes, setOpenIndexes] = useState<number[]>([]);

  const formMethods = useForm({
    resolver: yupResolver(yupSchema)
  });

  const onError = useFieldSetErrors(formMethods.setError);

  const handleOnSubmit = useCallback(
    (data: SupportContact) => {
      supportContactMutation(data, {
        onError,
        onSuccess: () => {
          addToast('success', 'Mensagem enviada com sucesso');
          formMethods.reset();
        }
      });
    },
    [supportContactMutation, onError, addToast, formMethods]
  );

  const toggle = useCallback(
    (index: number) => {
      const currentIndex = openIndexes.indexOf(index);
      const newOpenIndexes = [...openIndexes];
      if (currentIndex === -1) {
        newOpenIndexes.push(index);
      } else {
        newOpenIndexes.splice(currentIndex, 1);
      }
      setOpenIndexes(newOpenIndexes);
    },
    [openIndexes]
  );

  const typeSupportOptions = [
    { value: TypeSupport.BILLING, label: 'Financeiro' },
    { value: TypeSupport.GENERAL, label: 'Geral' },
    { value: TypeSupport.TECHNICAL, label: 'Técnico' }
  ];

  return (
    <div className="bg-white p-2 h-100">
      <Row className="d-flex align-items-center bg-white mb-2">
        <Col className="bg-white">
          <p className="mt-2">
            <span className="h5 fw-bolder">Ajuda e FAQ</span>
          </p>
        </Col>
      </Row>
      <Row className="d-flex align-items-center bg-white">
        <Col className="bg-white  mt-1" md="4">
          <SearchInput
            onSearchTextChange={handleSearch}
            placeholder="Em que podemos ajudar?"
          />
        </Col>
        <Col className="text-end  mt-1">
          <PageSizeSelector
            pageSize={filteredData?.pageSize}
            onPageSizeChanged={(pageSize) =>
              setFilteredData({ ...filteredData, pageSize })
            }
          />
        </Col>
      </Row>
      <hr />
      <Table responsive hover className="border">
        <tbody>
          {isLoading && (
            <tr>
              <td colSpan={2} className="text-center p-5">
                <Spinner />
              </td>
            </tr>
          )}
          {supportListPage?.items.map((faq, index) => (
            <tr role="button" onClick={() => toggle(index)} key={faq.order}>
              <td>
                <p>{faq.question} </p>
                <Collapse isOpen={openIndexes.includes(index)}>
                  <p>{faq.answer}</p>
                </Collapse>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      {Boolean(supportListPage?.total) && (
        <p className="text-end">
          {supportListPage?.total} pergunta(s) frequente(s) encontrada(s)
        </p>
      )}
      {supportListPage && (
        <PaginationNav
          onChange={handlePageChange}
          totalPages={supportListPage.totalPages}
          currentPage={supportListPage.page}
        />
      )}
      <Row className="d-flex align-items-center bg-white">
        <Col className="d-flex align-items-center justify-content-between bg-white">
          <Form onSubmit={handleOnSubmit} {...formMethods}>
            <Card>
              <CardBody>
                <p>Não encontrou o que procurava? Entre em contato conosco.</p>
                <hr />
                <SelectField
                  label="Selecione o tipo de problema:"
                  name="subject"
                  options={typeSupportOptions}
                />
                <TextField
                  label="Descreva o problema:"
                  name="message"
                  placeholder="Descreva o problema"
                  type="textarea"
                />
                <Button color="primary" loading={isSaving}>
                  Enviar
                </Button>
              </CardBody>
            </Card>
          </Form>
          <Card>
            <CardBody>
              <div className="ms-3">
                <p>
                  Você também pode entrar em contato através dos seguintes
                  canais:
                </p>
                <div className="d-flex align-items-center mb-2">
                  <Icon icon="whatsapp" />
                  <span className="ms-1"> (11) 99999-9999</span>
                </div>
                <div className="d-flex align-items-center mb-2">
                  <Icon icon="telephone" />
                  <span className="ms-1"> (11) 99999-9999</span>
                </div>
                <div className="d-flex align-items-center mb-2">
                  <Icon icon="envelope-at" />
                  <span className="ms-1"> email@email.com</span>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default SupportFaqListPage;
