import React, { useCallback, useMemo } from 'react';
import { useApiGet } from 'hooks/useApi';
import Pagination from 'models/Pagination';
import { useNavigate } from 'react-router-dom';
import useUser from 'hooks/useUser';
import TypeResource from 'enum/TypeResource';
import TypeAction from 'enum/TypeAction';
import apiPaths from 'constants/apiPaths';

const useControllerExternalListPage = () => {
  const navigate = useNavigate();
  const [filteredData, setFilteredData] = React.useState({
    pageSize: 10,
    page: 1,
    search: ''
  });
  const { data: urlData, isLoading } = useApiGet<
    Pagination<{
      id: string | number;
      url: string;
      duration: number;
      isIndefinite: boolean;
    }>
  >(apiPaths.urlExternal, {
    params: filteredData
  });

  const handleSearch = useCallback(
    (search: string) => {
      setFilteredData({ ...filteredData, search });
    },
    [filteredData]
  );
  const handlePageChange = useCallback(
    (page: number) => {
      setFilteredData({ ...filteredData, page });
    },
    [filteredData]
  );

  const handleClearFilter = useCallback(() => {
    setFilteredData({ pageSize: 10, page: 1, search: '' });
  }, []);

  const goToEdit = useCallback(
    (row: { id: string | number }) => {
      navigate(`/media-manager/url-external/${row.id}`);
    },
    [navigate]
  );
  const user = useUser();
  const userCanAdd = useMemo(() => {
    return user?.resources?.[TypeResource.URL_EXTERNAL]?.includes(
      TypeAction.CREATE
    );
  }, [user]);

  return {
    filteredData,
    setFilteredData,
    urlData,
    isLoading,
    handleSearch,
    handlePageChange,
    goToEdit,
    userCanAdd,
    handleClearFilter
  };
};

export default useControllerExternalListPage;
