import Icon from 'components/Icon';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useToast } from 'hooks/useToast';

const AlertPopups = () => {
  const mapTimeouts = useRef<any>({});
  const { toasts, removeToast } = useToast();
  const keys = useMemo(() => Object.keys(toasts || {}), [toasts]);
  useEffect(() => {
    keys.forEach((key) => {
      if (!mapTimeouts.current[key]) {
        mapTimeouts.current[key] = setTimeout(() => {
          removeToast(key);
        }, 5000);
      }
    });
  }, [toasts, keys, removeToast]);

  const handleRemoveToast = useCallback(
    (key: string) => {
      clearTimeout(mapTimeouts.current[key]);
      removeToast(key);
    },
    [removeToast]
  );

  const renderToast = useCallback(
    (key: string) => {
      const toast = toasts[key];
      if (!toast) return null;
      return (
        <div
          key={key}
          className={`bg-${toast.backgroundColor} text-${toast.color} small-typography px-2 py-3 rounded-2 border-start border-4 border-${toast.borderColor}`}
          role="button"
          onClick={() => handleRemoveToast(key)}
        >
          <div className="d-flex align-items-center justify-content-between ms-1">
            <Icon
              icon={`${toast.icon}`}
              className="me-2"
              width={16}
              height={16}
            />
            {toast.message}

            <Icon className="ms-2" icon="x" width={16} height={16} />
          </div>
        </div>
      );
    },
    [handleRemoveToast, toasts]
  );
  if (keys.length === 0) return null;
  return (
    <div className="alert-toast-container">
      {keys.map((key) => renderToast(key))}
    </div>
  );
};

export default AlertPopups;
