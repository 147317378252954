import { useNavigate, useParams } from 'react-router-dom';
import { useCallback, useEffect, useMemo, useState } from 'react';
import AccessPolicy from 'models/AccessPolicy';
import { useForm } from 'react-hook-form';
import { useApiDelete, useApiGet, useApiPost, useApiPut } from 'hooks/useApi';
import useUser from 'hooks/useUser';
import TypeResource from 'enum/TypeResource';
import TypeAction from 'enum/TypeAction';
import useFieldSetErrors from 'hooks/useFieldSetErrors';
import { useToast } from 'hooks/useToast';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import ToastType from 'enum/ToastType';
import apiPaths from 'constants/apiPaths';

const yupSchema = yup
  .object({
    name: yup.string().required(),
    resources: yup.array().min(1)
  })
  .required();

const useControlllerAccessPoliciesFormPage = () => {
  const params = useParams();
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const navigate = useNavigate();
  const { addToast } = useToast();
  const user = useUser();
  const { data, isLoading: isFetching } = useApiGet<AccessPolicy>(
    apiPaths.accessPolicies,
    { id: params.id, enabled: !!params.id }
  );
  const { mutate: mutateCreate, isPending: isCreating } = useApiPost<
    AccessPolicy
  >(apiPaths.accessPolicies);
  const { mutate: mutateUpdate, isPending: isUpdating } = useApiPut<
    AccessPolicy
  >(apiPaths.accessPolicies, params.id);

  const { mutate: mutateDelete, isPending: isDeleting } = useApiDelete(
    apiPaths.accessPolicies,
    params.id
  );

  const mutate = params.id ? mutateUpdate : mutateCreate;

  const userCanPerformAction = useMemo(() => {
    if (params.id) {
      return user?.resources?.[TypeResource.ACCESS_POLICIES]?.includes(
        TypeAction.UPDATE
      );
    } else {
      return user?.resources?.[TypeResource.ACCESS_POLICIES]?.includes(
        TypeAction.CREATE
      );
    }
  }, [user, params.id]);

  const userCanDelete = useMemo(() => {
    return user?.resources?.[TypeResource.ACCESS_POLICIES]?.includes(
      TypeAction.DELETE
    );
  }, [user]);

  const isSaving = isCreating || isUpdating;

  const accessPolicy = params.id ? data : undefined;

  const formMethods = useForm({
    resolver: yupResolver(yupSchema)
  });

  const canSubmit = useMemo(() => {
    return formMethods.formState.isValid;
  }, [formMethods.formState.isValid]);

  const onError = useFieldSetErrors(formMethods.setError);

  const handleOnSubmit = useCallback(
    (data: AccessPolicy) => {
      if (!userCanPerformAction) return;
      const resources = data.resources.filter(
        (resource) =>
          resource.canRead ||
          resource.canUpdate ||
          resource.canDelete ||
          resource.canCreate
      );
      mutate(
        { ...data, resources },
        {
          onError,
          onSuccess: () => {
            addToast(ToastType.SUCCESS, 'Política de acesso salva com sucesso');
            navigate('/access-policies');
          }
        }
      );
    },
    [addToast, mutate, navigate, onError, userCanPerformAction]
  );

  const handleOnDelete = useCallback(() => {
    if (!params.id || !userCanDelete) return;
    mutateDelete(null as any, {
      onError: (response: any) => {
        addToast(
          ToastType.ERROR,
          response.errors?.detail?.toString() ||
            'Erro ao excluir política de acesso'
        );
      },
      onSuccess: () => {
        addToast(ToastType.SUCCESS, 'Política de acesso excluída com sucesso');
        navigate('/access-policies');
      }
    });
  }, [addToast, mutateDelete, navigate, params.id, userCanDelete]);

  useEffect(() => {
    if (accessPolicy) {
      formMethods.reset(accessPolicy as any);
    }
  }, [accessPolicy, formMethods]);

  const goOut = () => {
    navigate('/access-policies');
  };

  return {
    showDeleteConfirmation,
    setShowDeleteConfirmation,
    navigate,
    addToast,
    user,
    params,
    isFetching,
    isCreating,
    isUpdating,
    isDeleting,
    mutate,
    userCanPerformAction,
    userCanDelete,
    isSaving,
    accessPolicy,
    formMethods,
    onError,
    handleOnSubmit,
    handleOnDelete,
    canSubmit,
    goOut
  };
};

export default useControlllerAccessPoliciesFormPage;
