import {
  Col,
  TabContent,
  TabPane,
  Breadcrumb,
  BreadcrumbItem
} from 'reactstrap';
import Button from 'components/Button';
import Form from 'components/Form';
import TextField from 'components/TextField';
import ImageUpload from 'components/ImageUpload';
import PasswordField from 'components/PasswordField';
import ImagePreview from 'components/ImagePreview';
import classNames from 'classnames';
import useControllerUserProfilePage from './_presenters/useControllerUserProfileForm/useControllerUserProfileForm';
import NavUserProfilePage from './_presenters/enum/navUserProfilePage';
import apiPaths from 'constants/apiPaths';

const UserProfileForm = () => {
  const {
    handleOnSubmit,
    activeTab,
    setActiveTab,
    formMethods,
    user,
    setUser,
    isUpdating
  } = useControllerUserProfilePage();

  return (
    <div className="mx-3">
      <Form onSubmit={handleOnSubmit} {...formMethods}>
        <div>
          <Breadcrumb className="text-gray-600 fw-medium mt-4">
            <BreadcrumbItem>Detalhes da Conta</BreadcrumbItem>
          </Breadcrumb>
          <h4 className="fw-bold mt-3">Detalhes da Conta</h4>
          <span className="small-typography text-gray-600">
            Todas as suas informações concentram-se aqui.
          </span>
          <div className="d-flex align-items-center mt-4">
            <div className="navlink">
              <div
                className={classNames('me-5 navlink', {
                  active: activeTab === NavUserProfilePage.INFORMATIONS
                })}
                onClick={() => setActiveTab(NavUserProfilePage.INFORMATIONS)}
              >
                Minhas Informações
              </div>
            </div>
            <div className="navlink">
              <div
                className={classNames('navlink', {
                  active: activeTab === NavUserProfilePage.CREDENTIALS
                })}
                onClick={() => setActiveTab(NavUserProfilePage.CREDENTIALS)}
              >
                Credenciais
              </div>
            </div>
          </div>
          <hr className="color-line" />
          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              <div className="mt-3">
                <div className="shadow bg-white form mb-3">
                  <div className="d-flex align-items-center p-3">
                    <ImagePreview imageUrl={user?.picture} />
                    <ImageUpload
                      url={`${apiPaths.userProfile}/picture`}
                      onSuccess={({ picture }: any) => {
                        setUser({ ...user, picture });
                      }}
                      keyName="picture"
                    />
                  </div>
                </div>
                <div className="shadow bg-white form">
                  <Col className="mx-4 mt-3">
                    <TextField
                      name="firstName"
                      label="Nome"
                      placeholder="Digite o nome"
                      defaultValue={user?.firstName}
                    />
                    <TextField
                      name="lastName"
                      label="Sobrenome"
                      placeholder="Digite o sobrenome"
                      defaultValue={user?.lastName}
                    />
                    <TextField
                      name="email"
                      label="E-mail"
                      placeholder="Digite o e-mail"
                      defaultValue={user?.email}
                    />
                  </Col>
                </div>
                <div className="text-end mt-3">
                  <Button
                    color="primary"
                    className="me-2 text-white"
                    loading={isUpdating}
                    type="submit"
                  >
                    Salvar as alterações
                  </Button>
                </div>
              </div>
            </TabPane>
            <TabPane tabId="2">
              <div className="mt-2">
                <PasswordField
                  label="Senha Antiga"
                  type="password"
                  name="currentPassword"
                  placeholder="Digite a sua senha antiga"
                />
                <PasswordField
                  label="Senha Nova"
                  type="password"
                  name="password"
                  placeholder="Digite a sua senha nova"
                />
                <PasswordField
                  label="Confirme a senha nova"
                  type="password"
                  name="confirmPassword"
                  placeholder="Confirme a sua senha nova"
                />
              </div>
              <div className="text-end">
                <Button
                  color="primary"
                  type="submit"
                  loading={isUpdating}
                  className="btn btn-primary text-white"
                >
                  Salvar alterações
                </Button>
              </div>
            </TabPane>
          </TabContent>
        </div>
      </Form>
    </div>
  );
};

export default UserProfileForm;
