import { Col, Row, Spinner } from 'reactstrap';
import SearchInput from 'components/SearchInput';
import PaginationNav from 'components/Pagination';
import { Link } from 'react-router-dom';
import useControllerExternalListPage from './_presenters/useControllerExternalListPage.ts/useControllerExternalListPage';
import apiPaths from 'constants/apiPaths';
import Table from 'components/Table';
import { Column } from 'components/Table/_presenters/props/tableComponentProps';

const UrlExternalListPage = () => {
  const {
    urlData,
    isLoading,
    handleSearch,
    handlePageChange,
    goToEdit,
    userCanAdd,
    handleClearFilter
  } = useControllerExternalListPage();

  const tableData =
    urlData?.items.map((item) => ({
      ...item,
      id: item.id?.toString(),
      durationInfo: { duration: item.duration, isIndefinite: item.isIndefinite }
    })) || [];

  const columns = [
    {
      accessor: 'url',
      label: 'URL',
      render: (url: string) => <span>{url}</span>
    },
    {
      accessor: 'durationInfo',
      label: 'Duração',
      render: ({
        duration,
        isIndefinite
      }: {
        duration: number;
        isIndefinite: boolean;
      }) => <span>{isIndefinite ? 'Tempo indeterminado' : `${duration}s`}</span>
    }
  ] as Column<{
    id: string | number;
    url: string;
    durationInfo: { duration: number; isIndefinite: boolean };
  }>[];

  return (
    <div>
      <Row className="d-flex flex-column flex-md-row">
        <Col className="col-12 col-md">
          <SearchInput
            onClearFilter={handleClearFilter}
            onSearchTextChange={handleSearch}
            placeholder="Buscar"
          />
        </Col>
        {userCanAdd && (
          <Col className="col-12 col-md align-text mt-3 mt-md-0">
            <Link
              to={`${apiPaths.urlExternal}/create`}
              className="btn btn-primary text-white button-register"
            >
              Adicionar URL
            </Link>
          </Col>
        )}
      </Row>
      {isLoading && (
        <tr>
          <td colSpan={2} className="text-center p-5">
            <Spinner />
          </td>
        </tr>
      )}
      {!isLoading && (
        <Table<{
          id: string | number;
          url: string;
          durationInfo: { duration: number; isIndefinite: boolean };
        }>
          data={tableData}
          columns={columns}
          isLoading={isLoading}
          onRowClick={goToEdit}
        />
      )}
      <div className="d-flex justify-content-between align-items-center flex-column flex-md-row mt-3">
        {urlData && (
          <PaginationNav
            onChange={handlePageChange}
            totalPages={urlData.totalPages}
            currentPage={urlData.page}
          />
        )}
      </div>
    </div>
  );
};

export default UrlExternalListPage;
