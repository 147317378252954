import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { MediaFileCampaign } from 'models/MediaFileCampaign';
// @ts-ignore
import { useDrop } from 'react-dnd';
import CampaignPositionItem from './CampaignPositionItem';
import MediaFile from 'models/MediaFile';
import { Spinner, Table } from 'reactstrap';
import MediaCampaignPosition from 'enum/MediaCampaignPosition';
import Icon from 'components/Icon';

type CampaignPositionListProps = {
  mediasCampaign?: MediaFileCampaign[];
  onAddMedia: (media: MediaFile, position: MediaCampaignPosition) => void;
  idCampaign: string;
  position: MediaCampaignPosition;
  onReOrderMedias: (mediaEdited: MediaFileCampaign[]) => void;
  isLoading?: boolean;
  loadingPositions?: MediaCampaignPosition[];
  onClick?: (mediaCampaignPreview: MediaFileCampaign) => void;
};

const CampaignPositionList = ({
  position,
  mediasCampaign,
  onAddMedia,
  onReOrderMedias,
  isLoading,
  onClick,
  idCampaign
}: CampaignPositionListProps) => {
  const dragAndDropKeyAccept = `MediaCampaignOrder-${position}`;

  const contentRef = useRef<HTMLDivElement>(null);
  const mediasCampaignOrdered = useMemo(
    () =>
      (mediasCampaign || []).sort((a, b) => (a.order || 0) - (b.order || 0)),
    [mediasCampaign]
  );

  const [
    mediaCampaignOrderedPreview,
    setMediaCampaignOrderedPreview
  ] = useState<MediaFileCampaign[]>(mediasCampaignOrdered);

  useEffect(() => {
    setMediaCampaignOrderedPreview(mediasCampaignOrdered);
  }, [mediasCampaignOrdered]);

  const reorderMediaCampaigns = useCallback(
    (dragId: string, hoverId: string) => {
      const draggedIndex = mediaCampaignOrderedPreview.findIndex(
        (media) => media.id === dragId
      );
      const draggedMediaCampaign = mediaCampaignOrderedPreview[draggedIndex];
      const hoverIndex = mediaCampaignOrderedPreview.findIndex(
        (media) => media.id === hoverId
      );
      const copiedMediasCampaign = [...mediaCampaignOrderedPreview];
      copiedMediasCampaign.splice(draggedIndex, 1);
      copiedMediasCampaign.splice(hoverIndex, 0, draggedMediaCampaign);
      return copiedMediasCampaign.map((mediaCampaign, index) => ({
        ...mediaCampaign,
        order: index
      }));
    },
    [mediaCampaignOrderedPreview]
  );

  const handleMediaReOrderFinish = useCallback(() => {
    onReOrderMedias(mediaCampaignOrderedPreview);
  }, [mediaCampaignOrderedPreview, onReOrderMedias]);

  const handleMediaReOrderPreview = useCallback(
    (dragId: string, hoverId: string) => {
      setMediaCampaignOrderedPreview(reorderMediaCampaigns(dragId, hoverId));
    },
    [reorderMediaCampaigns]
  );

  const [{ isOver, canDrop }, dropMedia] = useDrop({
    accept: 'MediaItem',
    drop(item: MediaFile) {
      onAddMedia(item, position);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: !monitor.getItem()?.isFolder
    })
  });

  const [{ isOverTop }, dropTopContent] = useDrop({
    accept: dragAndDropKeyAccept,
    collect: (monitor) => ({
      isOverTop: monitor.isOver()
    })
  });

  const [{ isOverBottom }, dropBottomContent] = useDrop({
    accept: dragAndDropKeyAccept,
    collect: (monitor) => ({
      isOverBottom: monitor.isOver()
    })
  });

  const SCROLL_INTERVAL = 20;

  useEffect(() => {
    if (isOverTop) {
      const interval = setInterval(() => {
        const content = contentRef.current;
        if (content && content.scrollTop > 0) {
          content.scrollTop -= SCROLL_INTERVAL;
        }
      }, 100);
      return () => clearInterval(interval);
    }
  }, [isOverTop]);

  useEffect(() => {
    if (isOverBottom) {
      const interval = setInterval(() => {
        const content = contentRef.current;
        if (content) {
          content.scrollTop += SCROLL_INTERVAL;
        }
      }, 100);
      return () => clearInterval(interval);
    }
  }, [isOverBottom]);

  const moveUp = useCallback(
    (mediaId: string) => {
      const currentIndex = mediaCampaignOrderedPreview.findIndex(
        (media) => media.id === mediaId
      );
      if (currentIndex > 0) {
        const newIndex = currentIndex - 1;
        handleMediaReOrderPreview(
          mediaId,
          mediaCampaignOrderedPreview[newIndex].id!
        );
      }
    },
    [handleMediaReOrderPreview, mediaCampaignOrderedPreview]
  );

  const moveDown = useCallback(
    (mediaId: string) => {
      const currentIndex = mediaCampaignOrderedPreview.findIndex(
        (media) => media.id === mediaId
      );
      if (currentIndex < mediaCampaignOrderedPreview.length - 1) {
        const newIndex = currentIndex + 1;
        handleMediaReOrderPreview(
          mediaId,
          mediaCampaignOrderedPreview[newIndex].id!
        );
      }
    },
    [handleMediaReOrderPreview, mediaCampaignOrderedPreview]
  );

  return (
    <div ref={dropMedia} className="w-100 mb-4">
      <div
        ref={dropTopContent}
        className="position-absolute p-4 end-0 start-0 z-1"
      />
      <div className="campaign-medias-list" ref={contentRef}>
        {isLoading && (
          <div className="position-absolute campaign-position-list d-flex justify-content-center align-items-center bg-white opacity-3">
            <Spinner />
          </div>
        )}
        {isOver && !canDrop && (
          <div className="position-absolute campaign-position-list d-flex justify-content-center align-items-center bg-white opacity-3 bg-danger">
            <p>
              <Icon icon="ban" className="mr-2" />
              Somente arquivos de mídia são permitidos aqui!
            </p>
          </div>
        )}
        {isOver && canDrop && (
          <div className="position-absolute campaign-position-list d-flex justify-content-center align-items-center opacity-3 bg-danger-subtle">
            <p className="fw-bold text-black">
              <Icon icon="target" className="mr-2" />
              Somente aqui seu arquivo para a posição {position}!
            </p>
          </div>
        )}
        <Table>
          {mediaCampaignOrderedPreview.map((media, index) => (
            <CampaignPositionItem
              dragAndDropKeyAccept={dragAndDropKeyAccept}
              key={media.id}
              mediaCampaign={media}
              onMediaReOrderFinish={handleMediaReOrderFinish}
              onMediaReOrderPreview={handleMediaReOrderPreview}
              onClick={onClick}
              campaignId={idCampaign}
              index={index}
              isFirst={index === 0}
              isLast={index === mediaCampaignOrderedPreview.length - 1}
              moveUp={moveUp}
              moveDown={moveDown}
            />
          ))}
        </Table>
      </div>
      <div
        ref={dropBottomContent}
        className="position-absolute p-4 z-1 end-0 start-0"
      />
    </div>
  );
};
export default CampaignPositionList;
