import React, { useCallback, useMemo, useState } from 'react';
import { useApiGet, useApiDelete } from 'hooks/useApi';
import Pagination from 'models/Pagination';
import { useNavigate } from 'react-router-dom';
import TypeResource from 'enum/TypeResource';
import TypeAction from 'enum/TypeAction';
import useUser from 'hooks/useUser';
import apiPaths from 'constants/apiPaths';
import { useToast } from 'hooks/useToast';
import ToastType from 'enum/ToastType';
import { ItemResource } from 'models/AccessPolicy';

const useControllerAccessPoliciesListPage = () => {
  const navigate = useNavigate();
  const { addToast } = useToast();
  const user = useUser();
  const [filteredData, setFilteredData] = React.useState({
    pageSize: 10,
    page: 1,
    search: ''
  });
  const { data: accessPoliciesPage, isLoading, refetch } = useApiGet<
    Pagination<{
      id: string;
      name: string;
      resources: ItemResource[];
    }>
  >(apiPaths.accessPolicies, {
    params: filteredData
  });

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [accessPoliciesIdToDelete, setAccessPoliciesIdToDelete] = useState<
    string | null
  >(null);

  const { mutate: mutateDelete } = useApiDelete(
    `${apiPaths.accessPolicies}/${accessPoliciesIdToDelete}`
  );
  const userCanAdd = useMemo(() => {
    return user?.resources?.[TypeResource.ACCESS_POLICIES]?.includes(
      TypeAction.CREATE
    );
  }, [user]);
  const handleSearch = useCallback(
    (search: string) => {
      setFilteredData({ ...filteredData, search });
    },
    [filteredData]
  );
  const handlePageChange = useCallback(
    (page: number) => {
      setFilteredData({ ...filteredData, page });
    },
    [filteredData]
  );
  const goToEntry = useCallback(
    (row: { id: string }) => {
      navigate(`/access-policies/${row.id}`);
    },
    [navigate]
  );
  const userCanDelete = useMemo(() => {
    return user?.resources?.[TypeResource.COMPANIES]?.includes(
      TypeAction.DELETE
    );
  }, [user]);

  const handleClearFilter = useCallback(() => {
    setFilteredData({ pageSize: 10, page: 1, search: '' });
  }, []);

  const [openDropdownId, setOpenDropdownId] = useState<string | null>(null);

  const toggleMenu = (id: string) => {
    setOpenDropdownId((prevId) => (prevId === id ? null : id));
  };

  const handleDeleteClick = useCallback((id: string) => {
    setAccessPoliciesIdToDelete(id);
    setShowDeleteConfirmation(true);
  }, []);

  const handleOnDelete = useCallback(() => {
    if (!accessPoliciesIdToDelete || !userCanDelete) return;
    mutateDelete(null as any, {
      onError: (response: any) => {
        addToast(
          ToastType.ERROR,
          response.errors?.detail?.toString() || 'Erro ao excluir política'
        );
      },
      onSuccess: () => {
        setShowDeleteConfirmation(false);
        setAccessPoliciesIdToDelete(null);
        addToast(ToastType.SUCCESS, 'Política excluída com sucesso');
        refetch();
      }
    });
  }, [
    addToast,
    mutateDelete,
    userCanDelete,
    accessPoliciesIdToDelete,
    refetch
  ]);

  return {
    accessPoliciesPage,
    isLoading,
    handleSearch,
    handlePageChange,
    goToEntry,
    userCanAdd,
    openDropdownId,
    userCanDelete,
    toggleMenu,
    setAccessPoliciesIdToDelete,
    showDeleteConfirmation,
    handleClearFilter,
    handleDeleteClick,
    handleOnDelete,
    setShowDeleteConfirmation
  };
};

export default useControllerAccessPoliciesListPage;
