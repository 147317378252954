import React, { useCallback, useMemo, useState } from 'react';
import { useApiGet } from 'hooks/useApi';
import Pagination from 'models/Pagination';
import { useNavigate } from 'react-router-dom';
import { Campaign } from 'models/MediaFileCampaign';
import useUser from 'hooks/useUser';
import TypeResource from 'enum/TypeResource';
import TypeAction from 'enum/TypeAction';
import CampaignLayout from 'enum/CampaignLayout';
import ScreenOrientation from 'enum/ScreenOrientation';
import apiPaths from 'constants/apiPaths';

const useControllerCampaignListPage = () => {
  const navigate = useNavigate();
  const [filteredData, setFilteredData] = React.useState({
    pageSize: 10,
    page: 1,
    search: ''
  });
  const { data: campaignsData, isLoading } = useApiGet<
    Pagination<{
      id: string | number;
      name: string;
      medias: any;
      layoutType: CampaignLayout;
      screenOrientation: ScreenOrientation;
      campaign: Campaign;
      active: boolean;
    }>
  >(apiPaths.campaigns, {
    params: filteredData
  });

  const handleSearch = useCallback(
    (search: string) => {
      setFilteredData({ ...filteredData, search });
    },
    [filteredData]
  );
  const handleClearFilter = useCallback(() => {
    setFilteredData({ pageSize: 10, page: 1, search: '' });
  }, []);

  const handlePageChange = useCallback(
    (page: number) => {
      setFilteredData({ ...filteredData, page });
    },
    [filteredData]
  );
  const goToEdit = useCallback(
    (row: { id: string | number }) => {
      navigate(`/media-manager/campaigns/${row.id}`);
    },
    [navigate]
  );
  const user = useUser();
  const userCanAdd = useMemo(() => {
    return user?.resources?.[TypeResource.CAMPAIGNS]?.includes(
      TypeAction.CREATE
    );
  }, [user]);

  const [selectedCampaign, setSelectedCampaign] = useState<Campaign | null>(
    null
  );
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);

  const openPreview = (campaign: Campaign) => {
    setSelectedCampaign(campaign);
    setIsPreviewOpen(true);
  };

  const closePreview = () => {
    setIsPreviewOpen(false);
    setSelectedCampaign(null);
  };

  const handleCampaignContinuosOnEnded = () => {
    setIsPreviewOpen(true);
  };

  return {
    filteredData,
    setFilteredData,
    campaignsData,
    isLoading,
    handleSearch,
    handlePageChange,
    goToEdit,
    userCanAdd,
    selectedCampaign,
    isPreviewOpen,
    openPreview,
    closePreview,
    handleCampaignContinuosOnEnded,
    handleClearFilter
  };
};

export default useControllerCampaignListPage;
