import { Col, Row, Spinner } from 'reactstrap';
import SearchInput from 'components/SearchInput';
import Table from 'components/Table';
import PaginationNav from 'components/Pagination';
import { Link } from 'react-router-dom';
import { screenOrientationDisplay } from 'enum/ScreenOrientation';
import Button from 'components/Button';
import PlayerPlaylistPreview from 'components/Playlist/PlayerPlaylistPreview';
import useControllerPlaylistListPage from './_presenters/useControllerPlaylistListPage/useControllerPlaylistListPage';
import { Column } from 'components/Table/_presenters/props/tableComponentProps';
import { Playlist } from 'models/Playlist';
import ScreenOrientation from 'enum/ScreenOrientation';
import DeviceGroup from 'models/DeviceGroup';
import apiPaths from 'constants/apiPaths';

const PlaylistListPage = () => {
  const {
    playlistsData,
    selectedPlaylist,
    isPreviewOpen,
    isLoading,
    handleSearch,
    handlePageChange,
    goToEdit,
    userCanAdd,
    openPreview,
    closePreview,
    handleClearFilter
  } = useControllerPlaylistListPage();

  const columns = [
    {
      accessor: 'name',
      label: 'Playlist',
      render: (name: string) => <span>{name}</span>
    },
    {
      accessor: 'deviceGroup',
      label: 'Grupo',
      render: (deviceGroup: DeviceGroup) => <span>{deviceGroup?.name}</span>
    },
    {
      accessor: 'items',
      label: 'Qtd Itens',
      render: (items: any) => <span>{items?.length}</span>
    },
    {
      accessor: 'items',
      label: 'Duração',
      render: (items: any) => (
        <span>
          {items.reduce(
            (total: number, media: any) => total + (media?.duration || 0),
            0
          )}{' '}
          s
        </span>
      )
    },
    {
      accessor: 'screenOrientation',
      label: 'Orientação',
      render: (orientation: ScreenOrientation) => (
        <span>{screenOrientationDisplay(orientation)}</span>
      )
    },
    {
      accessor: 'active',
      label: 'Status',
      render: (active: boolean) =>
        active ? (
          <span className="text-green-800 fw-semibold bg-green-100 rounded p-1 small-typography-2">
            Ativa
          </span>
        ) : (
          <span className="text-gray-900 fw-semibold bg-light-secondary rounded p-1 small-typography-2">
            Inativa
          </span>
        )
    },
    {
      accessor: 'playlist',
      label: 'Playlist',
      render: (playlist: Playlist) => (
        <Button
          className="text-primary"
          type="button"
          color="transparent"
          icon="collection-play"
          onClick={(event) => {
            event.stopPropagation();
            openPreview(playlist);
          }}
        />
      )
    }
  ] as Column<{
    id: string | number;
    name?: string;
    deviceGroup: DeviceGroup;
    screenOrientation: ScreenOrientation;
    playlist: Playlist;
    active: boolean;
  }>[];

  return (
    <div>
      <Row className="d-flex flex-column flex-md-row">
        <Col className="col-12 col-md">
          <SearchInput
            onClearFilter={handleClearFilter}
            onSearchTextChange={handleSearch}
            placeholder="Buscar"
          />
        </Col>
        {userCanAdd && (
          <Col className="col-12 col-md align-text mt-3 mt-md-0">
            <Link
              to={`${apiPaths.playlists}/create`}
              className="btn btn-primary text-white button-register"
            >
              Criar playlist
            </Link>
          </Col>
        )}
      </Row>
      {isLoading && (
        <tr>
          <td colSpan={2} className="text-center p-5">
            <Spinner />
          </td>
        </tr>
      )}
      {!isLoading && (
        <Table<{
          id: string | number;
          name?: string;
          deviceGroup: DeviceGroup;
          screenOrientation: ScreenOrientation;
          playlist: Playlist;
          active: boolean;
        }>
          data={
            playlistsData?.items?.map((item) => ({
              ...item,
              id: item.id?.toString(),
              playlist: { ...item, id: item.id?.toString() }
            })) || []
          }
          columns={columns}
          isLoading={isLoading}
          onRowClick={goToEdit}
        />
      )}
      <div className="d-flex justify-content-between align-items-center flex-column flex-md-row mt-3">
        {playlistsData && (
          <PaginationNav
            onChange={handlePageChange}
            totalPages={playlistsData.totalPages}
            currentPage={playlistsData.page}
          />
        )}
      </div>
      {selectedPlaylist && (
        <PlayerPlaylistPreview
          playlist={selectedPlaylist}
          isOpen={isPreviewOpen}
          onClose={closePreview}
        />
      )}
    </div>
  );
};

export default PlaylistListPage;
