import { Collapse, Nav, NavItem, NavLink } from 'reactstrap';
import Icon from 'components/Icon';
import { NavLink as NavLinkRouter, useNavigate } from 'react-router-dom';
import MenuProps from './_presenters/props/menuProps';
import useControllerMenu from './_presenters/useControllerMenu/useControllerMenu';
import classNames from 'classnames';

const Menu = ({ isOpen, toggleSidebar, routes }: MenuProps) => {
  const {
    dropdownOpenTV,
    toggleMenuTV,
    dropdownOpenUser,
    toggleMenuUser,
    dropdownOpenDevices,
    toggleMenuDevices
  } = useControllerMenu({ isOpen });

  const navigate = useNavigate();

  const goToTab = (tabName: string, baseRoute: string) => {
    navigate(`${baseRoute}?tab=${tabName}`);
  };

  return (
    <Nav className="d-flex justify-content-center" navbar>
      <NavItem>
        <NavLink
          className={classNames(
            isOpen ? 'nav-link-expanded' : 'nav-link-collapsed',
            'ms-4'
          )}
          to="/"
          end={true}
          tag={NavLinkRouter}
        >
          <Icon
            className={classNames(isOpen ? 'me-3' : 'me-5')}
            icon="layers"
            width={24}
            height={24}
          />

          <span className="fw-medium">Dashboard</span>
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink
          onClick={toggleMenuTV}
          role="button"
          className={classNames(
            isOpen ? 'nav-link-expanded' : 'nav-link-collapsed',
            'ms-4'
          )}
          tag={NavLinkRouter}
          to={routes.CORPORATE_TV.LIST}
        >
          <button
            className="bg-transparent border-0 p-0"
            onClick={toggleSidebar}
          >
            <Icon
              className={classNames(isOpen ? 'me-3' : 'me-5')}
              icon="tv"
              width={24}
              height={24}
            />
          </button>
          <span className="fw-medium">TV Corporativa</span>
        </NavLink>
        <Collapse
          isOpen={dropdownOpenTV}
          className="bg-gray-100 w-100 py-2"
          data-bs-popper="static"
        >
          <NavItem tag="span">
            <NavLink
              role="button"
              className="ms-3 me-3 ps-1"
              onClick={() => goToTab('campaigns', routes.CORPORATE_TV.LIST)}
            >
              <span className="ms-5 sub-menu-font fw-medium">Campanhas</span>
            </NavLink>
          </NavItem>
          <NavItem tag="span">
            <NavLink
              className="ms-3 me-3 ps-1"
              role="button"
              onClick={() => goToTab('playlists', routes.CORPORATE_TV.LIST)}
            >
              <span className="ms-5 sub-menu-font fw-medium">Playlists</span>
            </NavLink>
          </NavItem>
          <NavItem tag="span">
            <NavLink
              className="ms-3 me-3 ps-1"
              role="button"
              onClick={() => goToTab('medias', routes.CORPORATE_TV.LIST)}
            >
              <span className="ms-5 sub-menu-font fw-medium">Mídias</span>
            </NavLink>
          </NavItem>
          <NavItem tag="span">
            <NavLink
              className="ms-3 me-3 ps-1"
              role="button"
              onClick={() => goToTab('external-url', routes.CORPORATE_TV.LIST)}
            >
              <span className="ms-5 sub-menu-font fw-medium">URL Externa</span>
            </NavLink>
          </NavItem>
        </Collapse>
      </NavItem>
      <NavItem>
        <NavLink
          className={classNames(
            isOpen ? 'nav-link-expanded' : 'nav-link-collapsed',
            'ms-4'
          )}
          onClick={toggleMenuDevices}
          role="button"
          tag={NavLinkRouter}
          to={routes.DEVICES.LIST}
        >
          <button
            className="bg-transparent border-0 p-0"
            onClick={toggleSidebar}
          >
            <Icon
              className={classNames(isOpen ? 'me-3' : 'me-5')}
              icon="laptop"
              width={24}
              height={24}
            />
          </button>
          <span className=" fw-medium">Dispositivos</span>
        </NavLink>
        <Collapse
          isOpen={dropdownOpenDevices}
          className="bg-gray-100 w-100 py-2 nav-link-expanded"
          data-bs-popper="static"
        >
          <NavItem tag="span">
            <NavLink
              className="ms-3 me-3 ps-1"
              role="button"
              onClick={() => goToTab('device-list', routes.DEVICES.LIST)}
              end={true}
            >
              <span className="ms-5 fw-medium sub-menu-font">
                Lista de Dispositivos
              </span>
            </NavLink>
          </NavItem>
          <NavItem tag="span">
            <NavLink
              className="ms-3 me-3 ps-1"
              role="button"
              onClick={() => goToTab('device-group', routes.DEVICES.LIST)}
            >
              <span className="ms-5 fw-medium sub-menu-font">
                Grupos de Dispositivos
              </span>
            </NavLink>
          </NavItem>
        </Collapse>
      </NavItem>
      <NavItem>
        <NavLink
          className={classNames(
            isOpen ? 'nav-link-expanded' : 'nav-link-collapsed',
            'ms-4'
          )}
          tag={NavLinkRouter}
          to={routes.CUSTOMER_COMPANIES.LIST}
        >
          <Icon
            className={classNames(isOpen ? 'me-3' : 'me-5')}
            icon="grid"
            width={24}
            height={24}
          />
          <span className=" fw-medium">Empresas</span>
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink
          className={classNames(
            isOpen ? 'nav-link-expanded' : 'nav-link-collapsed',
            'ms-4'
          )}
          onClick={toggleMenuUser}
          role="button"
        >
          <button
            className="bg-transparent border-0 p-0"
            onClick={toggleSidebar}
          >
            <Icon
              className={classNames(isOpen ? 'me-3' : 'me-5')}
              icon="diagram-2"
              width={24}
              height={24}
            />
          </button>
          <span className=" fw-medium">Usuários</span>
        </NavLink>
        <Collapse
          isOpen={dropdownOpenUser}
          className="bg-gray-100 w-100 py-2 nav-link-expanded"
          data-bs-popper="static"
        >
          <NavItem tag="span">
            <NavLink
              className="ms-3 me-3 ps-1"
              role="button"
              tag={NavLinkRouter}
              to={routes.CUSTOMER_USERS.LIST}
            >
              <span className="ms-5 sub-menu-font fw-medium">Usuários</span>
            </NavLink>
          </NavItem>
          <NavItem tag="span">
            <NavLink
              className="ms-3 me-3 ps-1"
              role="button"
              tag={NavLinkRouter}
              to={routes.ACCESS_POLICIES.LIST}
            >
              <span className="ms-5 sub-menu-font fw-medium">
                Políticas de acesso
              </span>
            </NavLink>
          </NavItem>
        </Collapse>
      </NavItem>
      <NavItem>
        <NavLink
          className={classNames(
            isOpen ? 'nav-link-expanded' : 'nav-link-collapsed',
            'ms-4'
          )}
          tag={NavLinkRouter}
          to={routes.NEWS.LIST}
        >
          <Icon
            className={classNames(isOpen ? 'me-3' : 'me-5')}
            icon="chat-square-quote"
            width={24}
            height={24}
          />
          <span className=" fw-medium">Notícias Internas</span>
        </NavLink>
      </NavItem>
    </Nav>
  );
};

export default Menu;
