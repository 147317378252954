import TextField from 'components/TextField';
import Form from 'components/Form';
import { Col, Row, Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { Link } from 'react-router-dom';
import Button from 'components/Button';
import RadioChoicesFiled from 'components/RadioChoicesField';
import SelectField from 'components/SelectField';
import CampaignLayout from 'enum/CampaignLayout';
import {
  LabelFullScreen,
  LabelHalfSplit,
  LabelHorizontallySplit,
  LabelVerticallySplit
} from '../CampaignLabels';
import ScreenOrientation from 'enum/ScreenOrientation';
import useControllerCampaignCreatePage from './_presenters/useControllerCampaignCreatePage/useControllerCampaignCreatePage';
import apiPaths from 'constants/apiPaths';

const CampaignCreatePage = () => {
  const {
    formMethods,
    onSubmitHandler,
    typeOptionsOrientation,
    goOut,
    canSubmit
  } = useControllerCampaignCreatePage();

  const typeOptionsScreen = [
    { value: CampaignLayout.FULL_SCREEN, label: <LabelFullScreen /> },
    {
      value: CampaignLayout.HORIZONTALLY_SPLIT,
      label: <LabelHorizontallySplit />
    },
    { value: CampaignLayout.VERTICALLY_SPLIT, label: <LabelVerticallySplit /> },
    { value: CampaignLayout.HALF_SCREEN, label: <LabelHalfSplit /> }
  ];
  const typeOptionsActive = [
    { value: true, label: 'Ativo' },
    {
      value: false,
      label: 'Inativo'
    }
  ];

  return (
    <div className="mx-3">
      <Form onSubmit={onSubmitHandler} {...formMethods}>
        <Row className="d-flex align-items-center">
          <Col className="mb-3">
            <Breadcrumb className="mt-4">
              <BreadcrumbItem active>
                <Link
                  to={apiPaths.tvCorporate}
                  className="text-decoration-none"
                >
                  Tv Corporativa
                </Link>
              </BreadcrumbItem>
              <BreadcrumbItem className="text-gray-600 fw-medium">
                Campanhas
              </BreadcrumbItem>
            </Breadcrumb>
            <h4>Campanha</h4>
            <span className="small-typography text-gray-600">
              Realize as configurações da sua campanha.
            </span>
          </Col>
          <Col className="d-flex align-items-center justify-content-end">
            <Button className="button-outline me-2" onClick={goOut}>
              Sair
            </Button>
            <Button
              color="primary"
              type="submit"
              className="btn btn-primary text-white button-register"
              disabled={!canSubmit}
            >
              Salvar
            </Button>
          </Col>
        </Row>
        <div className="shadow bg-white form">
          <Col className="mx-4 mt-3">
            <TextField label="Nome" name="name" />
            <RadioChoicesFiled
              label="Tipo de tela"
              name="layoutType"
              options={typeOptionsScreen}
              classNameRadio="d-flex flex-wrap justify-content-between"
              defaultValue={CampaignLayout.FULL_SCREEN}
            />
            <SelectField
              label="Orientação visual"
              name="screenOrientation"
              options={typeOptionsOrientation}
              defaultValue={ScreenOrientation.PORTRAIT}
            />
            <SelectField
              name="active"
              label="Status"
              options={typeOptionsActive}
              defaultValue={String(true)}
            />
          </Col>
        </div>
      </Form>
    </div>
  );
};

export default CampaignCreatePage;
