import FullScreenImg from 'assets/images/content.png';
import HorizontalScreenImg from 'assets/images/content (1).png';
import VerticallyScreenImg from 'assets/images/content (2).png';
import HalfScreenImg from 'assets/images/content (3).png';

export const LabelFullScreen = () => (
  <div>
    <p>Opção 01</p>
    <img src={FullScreenImg} alt="Full Screen" />
  </div>
);

export const LabelHorizontallySplit = () => (
  <div>
    <p>Opção 02</p>
    <img src={HorizontalScreenImg} alt="Horizontal Screen" />
  </div>
);

export const LabelVerticallySplit = () => (
  <div>
    <p>Opção 03</p>
    <img src={VerticallyScreenImg} alt="Vertically Screen" />
  </div>
);

export const LabelHalfSplit = () => (
  <div>
    <p>Opção 04</p>
    <img src={HalfScreenImg} alt="Half Screen" />
  </div>
);
