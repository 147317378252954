import { Col, Row, Spinner } from 'reactstrap';
import SearchInput from 'components/SearchInput';
import PaginationNav from 'components/Pagination';
import { Link } from 'react-router-dom';
import { campaignLayoutDisplay } from 'enum/CampaignLayout';
import { screenOrientationDisplay } from 'enum/ScreenOrientation';
import Button from 'components/Button';
import PlayerCampaignPreview from 'components/Campaign/PlayerCampaignPreview';
import useControllerCampaignListPage from './_presenters/useControllerCampaingListPage/useControllerCampaignListPage';
import Table from 'components/Table';
import { Column } from 'components/Table/_presenters/props/tableComponentProps';
import { Campaign } from 'models/MediaFileCampaign';
import CampaignLayout from 'enum/CampaignLayout';
import ScreenOrientation from 'enum/ScreenOrientation';
import apiPaths from 'constants/apiPaths';

const CampaignListPage = () => {
  const {
    campaignsData,
    isLoading,
    handleSearch,
    handlePageChange,
    goToEdit,
    userCanAdd,
    selectedCampaign,
    isPreviewOpen,
    openPreview,
    closePreview,
    handleCampaignContinuosOnEnded,
    handleClearFilter
  } = useControllerCampaignListPage();

  const columns = [
    {
      accessor: 'name',
      label: 'Nome',
      render: (name: string) => <span>{name}</span>
    },
    {
      accessor: 'layoutType',
      label: 'Layout',
      render: (layout: CampaignLayout) => (
        <span>{campaignLayoutDisplay(layout)}</span>
      )
    },
    {
      accessor: 'medias',
      label: 'Duração',
      render: (medias: any) => (
        <span>
          {medias.reduce(
            (total: number, media: any) => total + (media?.duration || 0),
            0
          )}{' '}
          s
        </span>
      )
    },
    {
      accessor: 'screenOrientation',
      label: 'Orientação',
      render: (orientation: ScreenOrientation) => (
        <span>{screenOrientationDisplay(orientation)}</span>
      )
    },
    {
      accessor: 'active',
      label: 'Status',
      render: (active: boolean) =>
        active ? (
          <span className="text-green-800 fw-semibold bg-green-100 rounded p-1 small-typography-2">
            Ativa
          </span>
        ) : (
          <span className="text-gray-900 fw-semibold bg-light-secondary rounded p-1 small-typography-2">
            Inativa
          </span>
        )
    },
    {
      accessor: 'campaign',
      label: 'Campanha',
      render: (campaign: Campaign) => (
        <Button
          className="text-primary"
          type="button"
          color="transparent"
          icon="collection-play"
          onClick={(event) => {
            event.stopPropagation();
            openPreview(campaign);
          }}
        />
      )
    }
  ] as Column<{
    id: string | number;
    name: string;
    medias: any;
    layoutType: CampaignLayout;
    screenOrientation: ScreenOrientation;
    campaign: Campaign;
    active: boolean;
  }>[];

  return (
    <div>
      <Row className="d-flex flex-column flex-md-row">
        <Col className="col-12 col-md">
          <SearchInput
            onClearFilter={handleClearFilter}
            onSearchTextChange={handleSearch}
            placeholder="Buscar"
          />
        </Col>
        {userCanAdd && (
          <Col className="col-12 col-md align-text mt-3 mt-md-0">
            <Link
              to={`${apiPaths.campaigns}/create`}
              className="btn btn-primary text-white button-register"
            >
              Criar Campanha
            </Link>
          </Col>
        )}
      </Row>
      {isLoading && (
        <tr>
          <td colSpan={2} className="text-center p-5">
            <Spinner />
          </td>
        </tr>
      )}
      {!isLoading && (
        <Table<{
          id: string | number;
          name: string;
          medias: any;
          layoutType: CampaignLayout;
          screenOrientation: ScreenOrientation;
          campaign: Campaign;
          active: boolean;
        }>
          data={
            campaignsData?.items.map((item) => ({
              ...item,
              id: item.id?.toString(),
              campaign: { ...item, id: item.id?.toString() }
            })) || []
          }
          columns={columns}
          isLoading={isLoading}
          onRowClick={goToEdit}
        />
      )}
      <div className="d-flex justify-content-between align-items-center flex-column flex-md-row mt-3">
        {campaignsData && (
          <PaginationNav
            onChange={handlePageChange}
            totalPages={campaignsData.totalPages}
            currentPage={campaignsData.page}
          />
        )}
      </div>

      {selectedCampaign && (
        <PlayerCampaignPreview
          isOpen={isPreviewOpen}
          onClose={closePreview}
          campaign={selectedCampaign}
          onEnded={handleCampaignContinuosOnEnded}
        />
      )}
    </div>
  );
};

export default CampaignListPage;
