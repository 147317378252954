import React, { useCallback, useMemo, useState } from 'react';
import { useApiGet, useApiDelete } from 'hooks/useApi';
import Pagination from 'models/Pagination';
import { useNavigate } from 'react-router-dom';
import useUser from 'hooks/useUser';
import TypeResource from 'enum/TypeResource';
import TypeAction from 'enum/TypeAction';
import apiPaths from 'constants/apiPaths';
import { useToast } from 'hooks/useToast';
import ToastType from 'enum/ToastType';

const useControllerCustomerCompanies = () => {
  const navigate = useNavigate();
  const { addToast } = useToast();
  const [filteredData, setFilteredData] = React.useState({
    pageSize: 10,
    page: 1,
    search: ''
  });
  const { data: companiesData, isLoading, refetch } = useApiGet<
    Pagination<{
      id: string;
      name: string;
      razaoSocial: string;
      cnpj: string;
    }>
  >(apiPaths.customerCompanies, {
    params: filteredData
  });

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [companyIdToDelete, setCompanyIdToDelete] = useState<string | null>(
    null
  );

  const { mutate: mutateDelete, isPending: isDeleting } = useApiDelete(
    `${apiPaths.customerCompanies}/${companyIdToDelete}`
  );

  const handleSearch = useCallback(
    (search: string) => {
      setFilteredData({ ...filteredData, search });
    },
    [filteredData]
  );
  const handlePageChange = useCallback(
    (page: number) => {
      setFilteredData({ ...filteredData, page });
    },
    [filteredData]
  );
  const goToEdit = useCallback(
    (row: { id: string }) => {
      navigate(`/customer/companies/${row.id}`);
    },
    [navigate]
  );
  const user = useUser();
  const userCanAdd = useMemo(() => {
    return user?.resources?.[TypeResource.COMPANIES]?.includes(
      TypeAction.CREATE
    );
  }, [user]);

  const [isOpen, setIsOpen] = useState(false);
  const [openDropdownId, setOpenDropdownId] = useState<string | null>(null);

  const toggleMenu = (id: string) => {
    setOpenDropdownId((prevId) => (prevId === id ? null : id));
  };

  const userCanDelete = useMemo(() => {
    return user?.resources?.[TypeResource.COMPANIES]?.includes(
      TypeAction.DELETE
    );
  }, [user]);

  const handleDeleteClick = useCallback((id: string) => {
    setCompanyIdToDelete(id);
    setShowDeleteConfirmation(true);
  }, []);

  const handleOnDelete = useCallback(() => {
    if (!companyIdToDelete || !userCanDelete) return;
    mutateDelete(null as any, {
      onError: (response: any) => {
        addToast(
          ToastType.ERROR,
          response.errors?.detail?.toString() || 'Erro ao excluir empresa'
        );
      },
      onSuccess: () => {
        setShowDeleteConfirmation(false);
        setCompanyIdToDelete(null);
        addToast(ToastType.SUCCESS, 'Empresa excluída com sucesso');
        refetch();
      }
    });
  }, [addToast, mutateDelete, userCanDelete, companyIdToDelete, refetch]);

  return {
    filteredData,
    companiesData,
    isLoading,
    handleSearch,
    handlePageChange,
    goToEdit,
    userCanAdd,
    setFilteredData,
    isOpen,
    setIsOpen,
    openDropdownId,
    setOpenDropdownId,
    toggleMenu,
    showDeleteConfirmation,
    setShowDeleteConfirmation,
    companyIdToDelete,
    setCompanyIdToDelete,
    handleDeleteClick,
    handleOnDelete,
    isDeleting,
    userCanDelete
  };
};

export default useControllerCustomerCompanies;
