import { Input } from 'reactstrap';
import Icon from '../Icon';
import useControllerImageUpload from './_presenters/useControllerImageUpload/useControllerImageUpload';
import { ImageUploadProps } from './_presenters/props/imageUploadProps';

const ImageUpload: React.FC<ImageUploadProps> = ({
  onSuccess,
  url,
  keyName
}) => {
  const { handleImageChange, ref, isPending } = useControllerImageUpload({
    onSuccess,
    url,
    keyName
  });

  return (
    <div className="ms-4">
      <Input
        id="file-upload"
        innerRef={ref}
        name="file"
        type="file"
        onChange={handleImageChange}
        accept="image/*"
        className="d-none"
        aria-label="File Upload"
        disabled={isPending}
      />
      <label
        htmlFor="file-upload"
        className="btn btn-outline-primary btn-outline"
      >
        <Icon icon="upload" className="me-2" />
        Anexar
      </label>
    </div>
  );
};

export default ImageUpload;
