import { Col, Row, Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { Link } from 'react-router-dom';
import Button from 'components/Button';
import Form from 'components/Form';
import TextField from 'components/TextField';
import { Loader } from 'react-bootstrap-typeahead';
import SelectField from 'components/SelectField';
import Modal from 'components/Modal';
import AsyncTypeaheadField from 'components/AsyncTypeaheadField';
import useControllerDeviceGroupFormPage from './_presenters/useControllerDeviceGroupFormPage/useControllerDeviceGroupFormPage';
import apiPaths from 'constants/apiPaths';

const DeviceGroupFormPage = () => {
  const {
    formMethods,
    companiesDataOptions,
    companiesLoading,
    userCanPerformAction,
    showDeleteConfirmation,
    handleOnDelete,
    handleOnSubmit,
    isSaving,
    isFetching,
    deviceGroups,
    params,
    isDeleting,
    setShowDeleteConfirmation,
    userCanDelete,
    typeOptionsActive,
    handleSearch
  } = useControllerDeviceGroupFormPage();
  return (
    <>
      <div className="mx-3">
        <Form onSubmit={handleOnSubmit} {...formMethods}>
          <Row className="d-flex align-items-center">
            <Col className="mb-3">
              <Breadcrumb className="mt-4">
                <BreadcrumbItem active>
                  <Link
                    to={`${apiPaths.devices}?tab=device-group`}
                    className="text-decoration-none"
                  >
                    Grupo de Dispositivos
                  </Link>
                </BreadcrumbItem>
                <BreadcrumbItem className="text-gray-600 fw-medium">
                  Cadastrar Grupo de Dispositivo
                </BreadcrumbItem>
              </Breadcrumb>
              <h4>Grupo de Dispositivo</h4>
              <span className="small-typography text-gray-600">
                Cadastre seu grupo de dispositivo
              </span>
            </Col>

            <Col className="d-flex align-items-center justify-content-end">
              {params.id && userCanDelete && (
                <Button
                  type="button"
                  color="danger"
                  loading={isDeleting}
                  className="button-outline delete me-2"
                  onClick={() => setShowDeleteConfirmation(true)}
                >
                  Excluir
                </Button>
              )}
              {userCanPerformAction && (
                <Button
                  color="primary"
                  type="submit"
                  loading={isSaving}
                  className="btn btn-primary text-white button-register"
                >
                  Salvar
                </Button>
              )}
            </Col>
          </Row>
          <hr />
          {isFetching ? (
            <Loader />
          ) : (
            <div className="shadow bg-white form">
              <Col className="mx-4 mt-3">
                <TextField
                  name="name"
                  label="Nome:"
                  placeholder="Nome"
                  disabled={!userCanPerformAction}
                  defaultValue={deviceGroups?.name}
                />
                <AsyncTypeaheadField
                  isLoading={companiesLoading}
                  name="company"
                  label="Selecione a empresa:"
                  options={companiesDataOptions}
                  onSearch={handleSearch}
                  disabled={!userCanPerformAction}
                  multiple={false}
                />
                <div className="mb-3">
                  <SelectField
                    name="active"
                    label="Status"
                    options={typeOptionsActive}
                    disabled={!userCanPerformAction}
                    defaultValue={String(deviceGroups?.active)}
                  />
                </div>
              </Col>
            </div>
          )}
        </Form>
      </div>
      <Modal
        primaryButtonAction={handleOnDelete}
        primaryButtonLabel="Excluir"
        title="Atenção!"
        isOpen={showDeleteConfirmation}
        showCloseButton={false}
        toggle={() => setShowDeleteConfirmation((x) => !x)}
        secondaryButtonAction={() => setShowDeleteConfirmation(false)}
        secondaryButtonLabel="Cancelar"
      >
        <p>
          Desaja realmente excluir o grupo de dispositivos? <br />
          <small>Essa alteração não poderá ser revertida.</small>
        </p>
      </Modal>
    </>
  );
};

export default DeviceGroupFormPage;
