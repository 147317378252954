import {
  Col,
  Row,
  Breadcrumb,
  BreadcrumbItem,
  TabContent,
  TabPane
} from 'reactstrap';
import { Link } from 'react-router-dom';
import Button from 'components/Button';
import Form from 'components/Form';
import TextField from 'components/TextField';
import { Loader } from 'react-bootstrap-typeahead';
import SelectField from 'components/SelectField';
import classNames from 'classnames';
import useControllerCustomerCompaniesFormPage from './_presenters/useControllerCustomerCompaniesFormPage/useControllerCustomerCompaniesFormPage';
import NavCompaniesFormPage from './_presenters/enum/navCompaniesFormPage';
import MultipleSelectField from 'components/AsyncTypeaheadField';
import NumberMaskInputField from 'components/NumberMaskInputField';

const CustomerCompaniesFormPage = () => {
  const {
    formMethods,
    handleOnSubmit,
    isFetching,
    isSaving,
    customerCompanies,
    userCanPerformAction,
    mainAdressControl,
    canProceedToAddress,
    activeTab,
    isNext,
    canSubmit,
    handleNext,
    handlePrevious,
    updateActiveTab,
    customerUsersOptions,
    isLoadingCustomerUsers,
    customerUsersRefetch
  } = useControllerCustomerCompaniesFormPage();

  return (
    <>
      <div className="mx-3">
        <Form onSubmit={handleOnSubmit} {...formMethods}>
          <Row className="d-flex align-items-center">
            <Col className="mb-3">
              <Breadcrumb className="mt-4">
                <BreadcrumbItem active>
                  <Link
                    to="/customer/companies"
                    className="text-decoration-none"
                  >
                    Empresas
                  </Link>
                </BreadcrumbItem>
                <BreadcrumbItem className="text-gray-600 fw-medium">
                  Cadastrar empresa
                </BreadcrumbItem>
              </Breadcrumb>
              <span className="small-typography text-gray-600">
                Preencha todas as informações para cadastrar
              </span>
            </Col>
            <Col className="text-end">
              {activeTab === NavCompaniesFormPage.ADDRESS && (
                <Button
                  className="button-outline me-2"
                  onClick={handlePrevious}
                  disabled={isSaving}
                >
                  Passo Anterior
                </Button>
              )}
              {userCanPerformAction && (
                <Button
                  className="button-register"
                  onClick={handleNext}
                  disabled={
                    (isNext && !canProceedToAddress) ||
                    (!isNext && (!canSubmit || isSaving))
                  }
                  type={isNext ? 'button' : 'submit'}
                >
                  {isNext ? 'Próximo' : 'Cadastrar'}
                </Button>
              )}
            </Col>
          </Row>
          <div className="d-flex align-items-center justify-content-between shadow mt-2 bg-white">
            <div className="navlink-companies mt-4 ms-3 w-100">
              <div
                className={classNames('me-5 navlink-companies', {
                  active: activeTab === NavCompaniesFormPage.GENRAL_DATA
                })}
                onClick={() =>
                  updateActiveTab(NavCompaniesFormPage.GENRAL_DATA)
                }
              >
                <div className="d-flex align-items-center">
                  <div
                    className={classNames('number-container', {
                      active: activeTab === NavCompaniesFormPage.GENRAL_DATA
                    })}
                  >
                    <span>1</span>
                  </div>
                  <div className="ms-2">Dados Gerais</div>
                </div>
              </div>
            </div>

            <div className="navlink-companies mt-4 w-100">
              <div
                className={classNames('navlink-companies', {
                  active: activeTab === NavCompaniesFormPage.ADDRESS,
                  disabled:
                    activeTab !== NavCompaniesFormPage.ADDRESS &&
                    !canProceedToAddress
                })}
                onClick={() => {
                  if (canProceedToAddress)
                    updateActiveTab(NavCompaniesFormPage.ADDRESS);
                }}
              >
                <div className="d-flex align-items-center">
                  <div
                    className={classNames('number-container', {
                      active: activeTab === NavCompaniesFormPage.ADDRESS,
                      disabled: !canProceedToAddress
                    })}
                  >
                    <span>2</span>
                  </div>
                  <div className="ms-2">Contato e Endereço</div>
                </div>
              </div>
            </div>
          </div>
          {isFetching ? (
            <Loader />
          ) : (
            <div>
              <TabContent activeTab={activeTab}>
                <TabPane tabId={NavCompaniesFormPage.GENRAL_DATA}>
                  <div className="mt-3">
                    <div className="shadow bg-white form">
                      <Col className="mx-4 mt-3">
                        <h5 className="fw-bold text-gray-600">Dados Gerais</h5>
                        <span className="small-typography text-gray-600">
                          Informações gerais sobre a empresa
                        </span>
                        <div className="mt-3">
                          <TextField
                            name="name"
                            label="Nome Fanstasia"
                            placeholder="Digite o nome fantasia"
                            disabled={!userCanPerformAction}
                            defaultValue={customerCompanies?.name}
                          />
                        </div>
                        <TextField
                          name="razaoSocial"
                          label="Razão Social"
                          placeholder="Digite a razão social"
                          disabled={!userCanPerformAction}
                          defaultValue={customerCompanies?.razaoSocial}
                        />
                        <NumberMaskInputField
                          name="cnpj"
                          label="CNPJ"
                          placeholder="Digite o CNPJ"
                          disabled={!userCanPerformAction}
                          defaultValue={customerCompanies?.cnpj}
                          mask="99.999.999/9999-99"
                        />
                        <TextField
                          name="stateRegistration"
                          label="Inscrição Estadual"
                          placeholder="Digite a inscrição estadual"
                          disabled={!userCanPerformAction}
                          defaultValue={customerCompanies?.stateRegistration}
                        />
                        <TextField
                          name="municipalRegistration"
                          label="Inscrição Municipal"
                          placeholder="Digite a inscrição municipal"
                          disabled={!userCanPerformAction}
                          defaultValue={
                            customerCompanies?.municipalRegistration
                          }
                        />
                        <MultipleSelectField
                          isLoading={isLoadingCustomerUsers}
                          name="users"
                          label="Usuários:"
                          options={customerUsersOptions}
                          onSearch={(search: string) =>
                            customerUsersRefetch({
                              params: { search }
                            } as any)
                          }
                          disabled={!userCanPerformAction}
                        />
                      </Col>
                    </div>
                  </div>
                </TabPane>
                <TabPane tabId={NavCompaniesFormPage.ADDRESS}>
                  <div className="mt-3">
                    <div className="shadow bg-white form">
                      <Col className="mx-4 mt-3">
                        <h5 className="fw-bold text-gray-600">Contato</h5>
                        <span className="small-typography text-gray-600">
                          Informações sobre o endereço da empresa
                        </span>
                        <div className="mt-3">
                          <TextField
                            name="email"
                            label="E-mail"
                            placeholder="Digite o e-mail"
                            disabled={!userCanPerformAction}
                            defaultValue={customerCompanies?.email}
                          />
                        </div>
                        <div className="d-flex flex-column flex-md-row align-items-center justify-content-between">
                          <div className="w-100 mb-3 mb-md-0">
                            <TextField
                              name="phone"
                              label="Telefone"
                              placeholder="Digite o número de telefone"
                              disabled={!userCanPerformAction}
                              defaultValue={customerCompanies?.phone}
                            />
                          </div>
                          <div className="w-100 ms-0 ms-md-3">
                            <TextField
                              name="cellPhone"
                              label="Celular"
                              placeholder="Digite o número de celular"
                              disabled={!userCanPerformAction}
                              defaultValue={customerCompanies?.cellPhone}
                            />
                          </div>
                        </div>
                        <h5 className="fw-bold text-gray-600">Endereço</h5>
                        <TextField
                          name="addresses.0.zipCode"
                          label="CEP"
                          placeholder="Digite o CEP"
                          disabled={!userCanPerformAction}
                          defaultValue={
                            customerCompanies?.addresses?.[0]?.zipCode
                          }
                        />
                        <div className="d-flex flex-column flex-md-row align-items-center justify-content-between">
                          <div className="w-100 mb-3 mb-md-0">
                            <TextField
                              name="addresses.0.street"
                              label="Logradouro"
                              placeholder="Digite o logradouro"
                              disabled={!userCanPerformAction}
                              defaultValue={
                                customerCompanies?.addresses?.[0]?.street
                              }
                            />
                          </div>
                          <div className="w-100 ms-0 ms-md-3">
                            <TextField
                              name="addresses.0.number"
                              label="Número"
                              placeholder="Digite o número"
                              disabled={!userCanPerformAction}
                              defaultValue={
                                customerCompanies?.addresses?.[0]?.number
                              }
                            />
                          </div>
                        </div>
                        <div className="d-flex flex-column flex-md-row align-items-center justify-content-between">
                          <div className="w-100 mb-3 mb-md-0">
                            <TextField
                              name="addresses.0.district"
                              label="Bairro"
                              placeholder="Digite o bairro"
                              disabled={!userCanPerformAction}
                              defaultValue={
                                customerCompanies?.addresses?.[0]?.district
                              }
                            />
                          </div>
                          <div className="w-100 ms-0 ms-md-3">
                            <TextField
                              name="addresses.0.city"
                              label="Cidade"
                              placeholder="Digite a cidade"
                              disabled={!userCanPerformAction}
                              defaultValue={
                                customerCompanies?.addresses?.[0]?.city
                              }
                            />
                          </div>
                        </div>
                        <div className="d-flex flex-column flex-md-row align-items-center justify-content-between">
                          <div className="w-100 mb-3 mb-md-0">
                            <TextField
                              name="addresses.0.state"
                              label="UF"
                              placeholder="Digite o estado"
                              disabled={!userCanPerformAction}
                              defaultValue={
                                customerCompanies?.addresses?.[0]?.state
                              }
                            />
                          </div>
                          <div className="w-100 ms-0 ms-md-3">
                            <TextField
                              name="addresses.0.complement"
                              label="Complemento"
                              placeholder="Digite o complemento"
                              disabled={!userCanPerformAction}
                              defaultValue={
                                customerCompanies?.addresses?.[0]?.complement
                              }
                            />
                          </div>
                        </div>
                        <h5 className="fw-bold text-gray-600">
                          Endereço de Cobrança
                        </h5>
                        <span className="small-typography text-gray-600">
                          Este endereço é o mesmo da cobrança?
                        </span>
                        <div className="mb-3">
                          <SelectField
                            name="addresses.0.mainAddress"
                            label="Selecione"
                            disabled={!userCanPerformAction}
                            yesNoOptions
                            className="text-primary"
                          />
                        </div>
                        <div
                          className={classNames('mb-3', {
                            'd-none':
                              mainAdressControl === 'true' ||
                              (mainAdressControl !== 'false' &&
                                !customerCompanies?.addresses?.[1])
                          })}
                        >
                          <TextField
                            name="addresses.1.zipCode"
                            label="CEP"
                            placeholder="Digite o CEP"
                            disabled={!userCanPerformAction}
                            defaultValue={
                              customerCompanies?.addresses?.[1]?.zipCode
                            }
                          />
                          <div className="d-flex flex-column flex-md-row align-items-center justify-content-between">
                            <div className="w-100 mb-3 mb-md-0">
                              <TextField
                                name="addresses.1.street"
                                label="Logradouro"
                                placeholder="Digite o logradouro"
                                disabled={!userCanPerformAction}
                                defaultValue={
                                  customerCompanies?.addresses?.[1]?.street
                                }
                              />
                            </div>
                            <div className="w-100 ms-0 ms-md-3">
                              <TextField
                                name="addresses.1.number"
                                label="Número"
                                placeholder="Digite o número"
                                disabled={!userCanPerformAction}
                                defaultValue={
                                  customerCompanies?.addresses?.[1]?.number
                                }
                              />
                            </div>
                          </div>
                          <div className="d-flex flex-column flex-md-row align-items-center justify-content-between">
                            <div className="w-100 mb-3 mb-md-0">
                              <TextField
                                name="addresses.1.district"
                                label="Bairro"
                                placeholder="Digite o bairro"
                                disabled={!userCanPerformAction}
                                defaultValue={
                                  customerCompanies?.addresses?.[1]?.district
                                }
                              />
                            </div>
                            <div className="w-100 ms-0 ms-md-3">
                              <TextField
                                name="addresses.1.city"
                                label="Cidade"
                                placeholder="Digite a cidade"
                                disabled={!userCanPerformAction}
                                defaultValue={
                                  customerCompanies?.addresses?.[1]?.city
                                }
                              />
                            </div>
                          </div>
                          <div className="d-flex flex-column flex-md-row align-items-center justify-content-between">
                            <div className="w-100 mb-3 mb-md-0">
                              <TextField
                                name="addresses.1.state"
                                label="UF"
                                placeholder="Digite o estado"
                                disabled={!userCanPerformAction}
                                defaultValue={
                                  customerCompanies?.addresses?.[1]?.state
                                }
                              />
                            </div>
                            <div className="w-100 ms-0 ms-md-3">
                              <TextField
                                name="addresses.1.complement"
                                label="Complemento"
                                placeholder="Digite o complemento"
                                disabled={!userCanPerformAction}
                                defaultValue={
                                  customerCompanies?.addresses?.[1]?.complement
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </Col>
                    </div>
                  </div>
                </TabPane>
              </TabContent>
            </div>
          )}
        </Form>
      </div>
    </>
  );
};

export default CustomerCompaniesFormPage;
