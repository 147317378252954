import { Col, Row, Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { Link } from 'react-router-dom';
import Button from 'components/Button';
import Form from 'components/Form';
import TextField from 'components/TextField';
import { Loader } from 'react-bootstrap-typeahead';
import Modal from 'components/Modal';
import NumberField from 'components/NumberField';
import useControllerUrlExternalFormPage from './_presenters/useControllerUrlExternalFormPage/useControllerUrlExternalFormPage';
import apiPaths from 'constants/apiPaths';
import SelectField from 'components/SelectField';

const UrlExternalFormPage = () => {
  const {
    params,
    showDeleteConfirmation,
    setShowDeleteConfirmation,
    isFetching,
    isDeleting,
    userCanPerformAction,
    formMethods,
    handleOnSubmit,
    urlExternal,
    handleOnDelete,
    isIndefiniteValue,
    userCanDelete,
    canSubmit
  } = useControllerUrlExternalFormPage();
  return (
    <>
      <div className="mx-3">
        <Form onSubmit={handleOnSubmit} {...formMethods}>
          <Row className="d-flex align-items-center">
            <Col className="mb-3">
              <Breadcrumb className="mt-4">
                <BreadcrumbItem active>
                  <Link
                    to={apiPaths.tvCorporate}
                    className="text-decoration-none"
                  >
                    Tv Corporativa
                  </Link>
                </BreadcrumbItem>
                <BreadcrumbItem className="text-gray-600 fw-medium">
                  Url Externa
                </BreadcrumbItem>
              </Breadcrumb>
              <h4>Url Externa</h4>
              <span className="small-typography text-gray-600">
                Cadastre aqui a sua Url.
              </span>
            </Col>
            <Col className="d-flex align-items-center justify-content-end">
              {params.id && userCanDelete && (
                <Button
                  type="button"
                  loading={isDeleting}
                  className="button-outline delete me-2"
                  onClick={() => setShowDeleteConfirmation(true)}
                >
                  Excluir
                </Button>
              )}
              {userCanPerformAction && (
                <Button
                  color="primary"
                  type="submit"
                  className="btn btn-primary text-white button-register"
                  disabled={!canSubmit}
                >
                  Salvar
                </Button>
              )}
            </Col>
          </Row>
          <hr />
          {isFetching ? (
            <Loader />
          ) : (
            <div className="shadow bg-white form">
              <Col className="mx-4 mt-3">
                <TextField
                  name="url"
                  label="URL"
                  placeholder="Digite a URL"
                  disabled={!userCanPerformAction}
                  defaultValue={urlExternal?.url}
                />
                <SelectField
                  name="isIndefinite"
                  label="O tempo de exibição é indeterminado?"
                  yesNoOptions
                  disabled={!userCanPerformAction}
                  defaultValue={String(urlExternal?.isIndefinite)}
                />
                <NumberField
                  name="duration"
                  label="Duração"
                  placeholder="Duração"
                  disabled={String(isIndefiniteValue) === 'true'}
                  defaultValue={urlExternal?.duration}
                />
              </Col>
            </div>
          )}
        </Form>
      </div>
      <Modal
        primaryButtonAction={handleOnDelete}
        primaryButtonLabel="Excluir"
        title="Atenção!"
        isOpen={showDeleteConfirmation}
        showCloseButton={false}
        toggle={() => setShowDeleteConfirmation((x) => !x)}
        secondaryButtonAction={() => setShowDeleteConfirmation(false)}
        secondaryButtonLabel="Cancelar"
      >
        <p>
          Deseja realmente excluir a URL? <br />
          <small>Essa alteração não poderá ser revertida.</small>
        </p>
      </Modal>
    </>
  );
};
export default UrlExternalFormPage;
