import {
  Col,
  Row,
  Spinner,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Breadcrumb,
  BreadcrumbItem
} from 'reactstrap';
import SearchInput from 'components/SearchInput';
import PaginationNav from 'components/Pagination';
import { Link } from 'react-router-dom';
import Icon from 'components/Icon';
import moment from 'moment';
import useControllerInternalsNewsListPage from './_presenters/useControllerInternalsNewsListPage/useControllerInternalsNewsListPage';
import Table from 'components/Table';
import { Column } from 'components/Table/_presenters/props/tableComponentProps';
import apiPaths from 'constants/apiPaths';
import Modal from 'components/Modal';

const NewsListPage = () => {
  const {
    internalsNews,
    isLoading,
    handleSearch,
    handlePageChange,
    goToEdit,
    userCanAdd,
    openDropdownId,
    userCanDelete,
    toggleMenu,
    setinternalsNewsIdToDelete,
    showDeleteConfirmation,
    handleClearFilter,
    handleOnDelete,
    setShowDeleteConfirmation
  } = useControllerInternalsNewsListPage();

  const columns = [
    {
      accessor: 'title',
      label: 'Título',
      render: (title: string) => <span>{title}</span>
    },
    {
      accessor: 'description',
      label: 'Preview do texto',
      render: (description) => (
        <span className="text-truncate-wrap">{description}</span>
      )
    },
    {
      accessor: 'isActive',
      label: 'Status',
      render: (active: boolean) =>
        active ? (
          <span className="text-green-800 fw-semibold bg-green-100 rounded p-1 small-typography-2">
            Ativa
          </span>
        ) : (
          <span className="text-gray-900 fw-semibold bg-light-secondary rounded p-1 small-typography-2">
            Inativa
          </span>
        )
    },
    {
      accessor: 'date',
      label: 'Data',
      render: (date) => <span>{moment(date as string).format('LLL')}</span>
    },
    {
      accessor: 'actions',
      label: 'Ações',
      render: (_, row) => (
        <UncontrolledDropdown
          className="dropdown-table"
          isOpen={openDropdownId === row.id}
          toggle={() => toggleMenu(row.id)}
        >
          <DropdownToggle
            color="none"
            className="d-flex align-items-center border-0"
            onClick={(e) => e.stopPropagation()}
          >
            {openDropdownId === row.id ? (
              <Icon icon="chevron-up" className="text-primary ms-2" />
            ) : (
              <Icon icon="chevron-down" className="text-primary ms-2" />
            )}
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem header>AÇÕES</DropdownItem>
            <DropdownItem onClick={() => goToEdit(row)}>
              Visualizar
            </DropdownItem>
            {userCanDelete && (
              <DropdownItem
                onClick={() => {
                  setinternalsNewsIdToDelete(row.id);
                  setShowDeleteConfirmation(true);
                }}
              >
                Excluir
              </DropdownItem>
            )}
          </DropdownMenu>
        </UncontrolledDropdown>
      )
    }
  ] as Column<{
    id: string;
    title: string;
    description: string;
    active: boolean;
    startDate: string;
  }>[];

  return (
    <div className="padding-page">
      <Row className="d-flex flex-column flex-md-row">
        <Col className="mb-3">
          <Breadcrumb>
            <BreadcrumbItem active>Notícias internas</BreadcrumbItem>
          </Breadcrumb>
          <h4 className="fw-bolder">Notícias internas</h4>
          <span className="small-typography text-gray-600">
            Realize a gestão das notícias da sua tv coorporativa
          </span>
        </Col>
        {userCanAdd && (
          <Col className="col-12 col-md align-text mt-3 mt-md-0">
            <Link
              to={`${apiPaths.internalNews}/create`}
              className="btn btn-primary text-white button-register"
            >
              Adicionar notícia
            </Link>
          </Col>
        )}
      </Row>
      <Row className="d-flex align-items-center mt-2 flex-column flex-md-row">
        <Col className="col-12 col-md">
          <SearchInput
            onClearFilter={handleClearFilter}
            onSearchTextChange={handleSearch}
            placeholder="Buscar"
          />
        </Col>
      </Row>
      {isLoading && (
        <tr>
          <td colSpan={2} className="text-center p-5">
            <Spinner />
          </td>
        </tr>
      )}
      {!isLoading && (
        <Table<{
          id: string;
          title: string;
          description: string;
          active: boolean;
          startDate: string;
        }>
          data={internalsNews?.items || []}
          columns={columns}
          isLoading={isLoading}
        />
      )}
      <div className="d-flex justify-content-between align-items-center flex-column flex-md-row mt-3">
        {internalsNews && (
          <PaginationNav
            onChange={handlePageChange}
            totalPages={internalsNews.totalPages}
            currentPage={internalsNews.page}
          />
        )}
      </div>
      <Modal
        isOpen={showDeleteConfirmation}
        title="Confirmar Exclusão"
        toggle={() => setShowDeleteConfirmation(false)}
        primaryButtonLabel="Excluir"
        primaryButtonAction={handleOnDelete}
        secondaryButtonLabel="Cancelar"
        secondaryButtonAction={() => setShowDeleteConfirmation(false)}
      >
        <p>
          Tem certeza de que deseja excluir esta notícia? Essa ação não pode ser
          desfeita.
        </p>
      </Modal>
    </div>
  );
};

export default NewsListPage;
