import { useEffect, useState } from 'react';

type UseControllerMenuProps = {
  isOpen: boolean;
};

const useControllerMenu = ({ isOpen }: UseControllerMenuProps) => {
  const [dropdownOpenTV, setdropdownOpenTV] = useState(false);
  const toggleMenuTV = () => setdropdownOpenTV((prevState) => !prevState);

  const [dropdownOpenUser, setdropdownOpenUser] = useState(false);
  const toggleMenuUser = () => setdropdownOpenUser((prevState) => !prevState);

  const [dropdownOpenDevices, setDropdownOpenDevices] = useState(false);
  const toggleMenuDevices = () => setDropdownOpenDevices((prev) => !prev);

  useEffect(() => {
    if (!isOpen) {
      setdropdownOpenTV(false);
      setdropdownOpenUser(false);
      setDropdownOpenDevices(false);
    }
  }, [isOpen]);

  return {
    dropdownOpenTV,
    toggleMenuTV,
    dropdownOpenUser,
    toggleMenuUser,
    dropdownOpenDevices,
    toggleMenuDevices
  };
};

export default useControllerMenu;
