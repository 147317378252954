import {
  Col,
  Row,
  Spinner,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';
import SearchInput from 'components/SearchInput';
import PaginationNav from 'components/Pagination';
import { Link } from 'react-router-dom';
import Table from 'components/Table';
import useControllerDeviceListPage from './_presenters/useControllerDeviceListPage/useControllerDeviceListPage';
import apiPaths from 'constants/apiPaths';
import { Column } from 'components/Table/_presenters/props/tableComponentProps';
import Icon from 'components/Icon';
import DeviceGroup from 'models/DeviceGroup';
import Modal from 'components/Modal';

const DeviceListPage = () => {
  const {
    device,
    isLoading,
    handleSearch,
    handlePageChange,
    goToEdit,
    userCanAdd,
    handleClearFilter,
    openDropdownId,
    userCanDelete,
    toggleMenu,
    setDeviceIdToDelete,
    setShowDeleteConfirmation,
    showDeleteConfirmation,
    handleOnDelete
  } = useControllerDeviceListPage();

  const columns = [
    {
      accessor: 'name',
      label: 'Nome',
      render: (name: string) => <span>{name}</span>
    },
    {
      accessor: 'group',
      label: 'Grupo',
      render: (group: DeviceGroup) => <span>{group?.name || 'Sem grupo'}</span>
    },
    {
      accessor: 'active',
      label: 'Status',
      render: (active: boolean) =>
        active ? (
          <span className="text-green-800 fw-semibold bg-green-100 rounded p-1 small-typography-2">
            Ativa
          </span>
        ) : (
          <span className="text-gray-900 fw-semibold bg-light-secondary rounded p-1 small-typography-2">
            Inativa
          </span>
        )
    },
    {
      accessor: 'local',
      label: 'Local',
      render: (local: string) => <span>{local}</span>
    },
    {
      accessor: 'actions',
      label: 'Ações',
      render: (_, row) => (
        <UncontrolledDropdown
          className="dropdown-table"
          isOpen={openDropdownId === row.id}
          toggle={() => toggleMenu(row.id)}
        >
          <DropdownToggle
            color="none"
            className="d-flex align-items-center border-0"
            onClick={(e) => e.stopPropagation()}
          >
            {openDropdownId === row.id ? (
              <Icon icon="chevron-up" className="text-primary ms-2" />
            ) : (
              <Icon icon="chevron-down" className="text-primary ms-2" />
            )}
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem header>AÇÕES</DropdownItem>
            <DropdownItem onClick={() => goToEdit(row)}>
              Informações
            </DropdownItem>
            {userCanDelete && (
              <DropdownItem
                onClick={() => {
                  setDeviceIdToDelete(row.id);
                  setShowDeleteConfirmation(true);
                }}
              >
                Excluir
              </DropdownItem>
            )}
          </DropdownMenu>
        </UncontrolledDropdown>
      )
    }
  ] as Column<{
    id: string;
    name: string;
    active: boolean;
    local: string;
    group: DeviceGroup;
  }>[];

  return (
    <div>
      <Row className="d-flex flex-column flex-md-row">
        <Col className="col-12 col-md">
          <SearchInput
            onClearFilter={handleClearFilter}
            onSearchTextChange={handleSearch}
            placeholder="Buscar"
          />
        </Col>
        {userCanAdd && (
          <Col className="col-12 col-md align-text mt-3 mt-md-0">
            <Link
              to={`${apiPaths.device}/create`}
              className="btn btn-primary text-white button-register"
            >
              Adicionar Dispositivo
            </Link>
          </Col>
        )}
      </Row>

      {isLoading && (
        <tr>
          <td colSpan={2} className="text-center p-5">
            <Spinner />
          </td>
        </tr>
      )}
      {!isLoading && (
        <Table<{
          id: string;
          name: string;
          active: boolean;
          local: string;
          group: DeviceGroup;
        }>
          data={device?.items || []}
          columns={columns}
          isLoading={isLoading}
        />
      )}
      <div className="d-flex justify-content-between align-items-center flex-column flex-md-row mt-3">
        {device && (
          <PaginationNav
            onChange={handlePageChange}
            totalPages={device.totalPages}
            currentPage={device.page}
          />
        )}
      </div>
      <Modal
        isOpen={showDeleteConfirmation}
        title="Confirmar Exclusão"
        toggle={() => setShowDeleteConfirmation(false)}
        primaryButtonLabel="Excluir"
        primaryButtonAction={handleOnDelete}
        secondaryButtonLabel="Cancelar"
        secondaryButtonAction={() => setShowDeleteConfirmation(false)}
      >
        <p>
          Tem certeza de que deseja excluir este dispositivo? Essa ação não pode
          ser desfeita.
        </p>
      </Modal>
    </div>
  );
};

export default DeviceListPage;
