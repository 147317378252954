import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Form from 'components/Form';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Col, Row } from 'reactstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Icon from 'components/Icon';
import Button from 'components/Button';
import useFieldSetErrors from 'hooks/useFieldSetErrors';
import { useToast } from 'hooks/useToast';
import { useApiDelete, useApiGet, useApiPost, useApiPut } from 'hooks/useApi';
import Modal from 'components/Modal';
import { Loader } from 'react-bootstrap-typeahead';
import License from 'models/License';
import DateField from 'components/DateField';
import SwitchField from 'components/SwitchField';
import NumberField from 'components/NumberField';
import Customer from 'models/Customer';
import Pagination from 'models/Pagination';
import AsyncTypeaheadField, { Option } from 'components/AsyncTypeaheadField';
import SelectField from 'components/SelectField';
import { LicenseTypeLabel } from 'enum/LicenseType';
import ToastType from 'enum/ToastType';

const schemaToCreate = yup
  .object({
    customer: yup.object().required('O cliente é obrigatório.'),
    licenseType: yup.string().required('O tipo da licença é obrigatório.'),
    expirationDate: yup.string(),
    active: yup.boolean().required('O status é obrigatório.'),
    quantity: yup.number().required('A quantidade é obrigatória.')
  })
  .required();

const schemaToEdit = yup
  .object({
    expirationDate: yup.string(),
    active: yup.boolean().required('O status é obrigatório.')
  })
  .required();

type LicenseApiPayload = {
  customerId: string;
  licenseType: string;
  expirationDate?: string;
  active: boolean;
  quantity: number;
};

type LicenseFormData = {
  customer: Option;
  licenseType: string;
  expirationDate?: string;
  active: boolean;
  quantity: number;
};

const AdminLicensesFormPage = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const [customersSearch, setCustomersSearch] = useState('');

  const { data: license, isLoading: isFetching } = useApiGet<License>(
    `/admin/licenses`,
    { id: params.id, enabled: !!params.id }
  );
  const { mutateAsync: mutateCreate, isPending: isCreating } = useApiPost<
    LicenseApiPayload
  >('/admin/licenses');
  const { mutateAsync: mutateUpdate, isPending: isUpdating } = useApiPut<
    LicenseApiPayload
  >('/admin/licenses', params.id);

  const { mutate: mutateDelete, isPending: isDeleting } = useApiDelete(
    '/admin/licenses',
    params.id
  );

  const { data: customers } = useApiGet<Pagination<Customer>>(
    '/admin/customers',
    customersSearch
      ? {
          params: {
            search: customersSearch
          }
        }
      : undefined
  );

  const mutate = params.id ? mutateUpdate : mutateCreate;

  const isSaving = isCreating || isUpdating;

  const formMethods = useForm<LicenseFormData>({
    defaultValues: {
      active: true,
      licenseType: 'PLAYER',
      quantity: 1
    },
    resolver: yupResolver(params.id ? schemaToEdit : schemaToCreate) as any
  });

  const onError = useFieldSetErrors(formMethods.setError);
  const { addToast } = useToast();

  const customersOptions = useMemo(
    () =>
      customers?.items.map((customer) => ({
        label: customer.name,
        value: customer.id
      })) || [
        {
          label: 'Nenhum cliente encontrado',
          value: ''
        }
      ],
    [customers]
  );

  const onSubmitHandler = useCallback(
    async (data: LicenseFormData) => {
      try {
        await mutate({
          ...data,
          customerId: data.customer.value,
          expirationDate: data.expirationDate || undefined
        });
        navigate('/admin/licenses');
      } catch (error) {
        onError(error);
        addToast(ToastType.ERROR, 'Erro ao criar a licença');
      }
    },
    [mutate, navigate, onError, addToast]
  );

  const handleOnDelete = useCallback(() => {
    if (!params.id) return;
    mutateDelete(null as any, {
      onError: (response: any) => {
        addToast(
          ToastType.ERROR,
          response.errors?.detail?.toString() || 'Erro ao excluir a licença'
        );
      },
      onSuccess: () => {
        addToast(ToastType.SUCCESS, 'Licença excluída com sucesso');
        navigate('/admin/notifications');
      }
    });
  }, [addToast, mutateDelete, navigate, params.id]);

  useEffect(() => {
    if (license) {
      formMethods.reset({
        customer: {
          label: license.customer?.name,
          value: license.customer?.id
        },
        licenseType: license.licenseType,
        expirationDate: license.expirationDate || '',
        active: license.active
      } as any);
    }
  }, [formMethods, license]);

  return (
    <div className="bg-white p-2 h-100">
      <Form onSubmit={onSubmitHandler} {...formMethods}>
        <Row className="d-flex align-items-center bg-white mb-2">
          <Col className="bg-white">
            <p className="mt-2 d-flex align-items-center">
              <Link
                to={'/admin/licenses'}
                className="btn btn-link text-dark text-decoration-none me-2"
              >
                <Icon icon="chevron-left" />
              </Link>
              <span className="h5 fw-bolder mb-0">Licença</span>
            </p>
          </Col>
          <Col className="text-end">
            {params.id && (
              <Button
                type="button"
                color="danger"
                icon="trash"
                className="me-2"
                loading={isDeleting}
                onClick={() => setShowDeleteConfirmation(true)}
              >
                Excluir
              </Button>
            )}
            <Button color="primary" icon="floppy" loading={isSaving}>
              Salvar
            </Button>
          </Col>
        </Row>
        {isFetching ? (
          <Loader />
        ) : (
          <div>
            <hr className="mb-4" />
            <AsyncTypeaheadField
              name="customer"
              label="Cliente:"
              options={customersOptions}
              onSearch={setCustomersSearch}
              placeholder="Digite o nome do cliente"
              multiple={false}
              disabled={!!params.id}
            />
            <SelectField
              name="licenseType"
              options={Object.entries(LicenseTypeLabel).map(([key, value]) => ({
                label: value,
                value: key
              }))}
              placeholder="Selecione o tipo de licença"
              label="Tipo de Licença:"
              disabled={!!params.id}
              defaultValue={license?.licenseType || 'PLAYER'}
            />
            <DateField
              label="Data de Expiração:"
              name="expirationDate"
              placeholder="Selecione a data de expiração"
              className="w-100"
            />
            {!params.id && (
              <NumberField
                label="Quantidade de Licenças:"
                name="quantity"
                defaultValue={1}
                placeholder="Digite a quantidade"
                className="w-100"
              />
            )}
            <SwitchField name="active" label="Ativa" />
          </div>
        )}
      </Form>
      <Modal
        primaryButtonAction={handleOnDelete}
        disabledPrimaryButton={license?.inUse}
        primaryButtonLabel="Excluir"
        title="Atenção!"
        isOpen={showDeleteConfirmation}
        showCloseButton={false}
        toggle={() => setShowDeleteConfirmation((x) => !x)}
        secondaryButtonAction={() => setShowDeleteConfirmation(false)}
        secondaryButtonLabel="Cancelar"
      >
        <p>
          {license?.inUse ? (
            <>
              A licença está em uso, não é possível excluí-la. Se deseja
              desativar o player, desative a licença.
            </>
          ) : (
            <>
              Deseja realmente excluir a licença? <br />
              <small>Essa alteração não poderá ser revertida.</small>
            </>
          )}
        </p>
      </Modal>
    </div>
  );
};

export default AdminLicensesFormPage;
