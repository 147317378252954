import React from 'react';
import { Col, Row } from 'reactstrap';
import TextField from 'components/TextField';
import { useApiGet } from 'hooks/useApi';
import Device from 'models/Device';
import { useForm, FormProvider } from 'react-hook-form';

const ResourcesPage = () => {
  const { data: device } = useApiGet<Device>(`/device`);

  const methods = useForm();

  return (
    <FormProvider {...methods}>
      <div className="mt-4">
        <Row xs="2" className="d-flex align-items-center shadow bg-white form">
          <Col className="mt-2">
            <TextField
              name="so"
              label="Sistema Operacional"
              placeholder="Sistema Operacional"
              disabled={!device?.player?.so}
              defaultValue={device?.player?.so}
              data-testid="so"
            />
          </Col>
          <Col className="mt-2">
            <TextField
              name="playerVersion"
              label="Versão Player"
              placeholder="Versão Player"
              disabled={!device?.player?.playerVersion}
              defaultValue={device?.player?.playerVersion}
              data-testid="playerVersion"
            />
          </Col>
          <Col>
            <TextField
              name="menRam"
              label="Total de Memória RAM"
              placeholder="Total dew Memória RAM"
              disabled={!device?.player?.menRam}
              defaultValue={device?.player?.menRam}
              data-testid="menRam"
            />
          </Col>
          <Col>
            <TextField
              name="storageUsed"
              label="Utilizado HD"
              placeholder="Utilizado HD"
              disabled={!device?.player?.storageUsed}
              defaultValue={device?.player?.storageUsed}
              data-testid="storageUsed"
            />
          </Col>
          <Col>
            <TextField
              name="brand"
              label="Marca do dispositivo"
              placeholder="Marca do dispositivo"
              disabled={!device?.player?.brand}
              defaultValue={device?.player?.brand}
              data-testid="brand"
            />
          </Col>
          <Col>
            <TextField
              name="processor"
              label="Processador"
              placeholder="Processador"
              disabled={!device?.player?.processor}
              defaultValue={device?.player?.processor}
              data-testid="processor"
            />
          </Col>
          <Col>
            <TextField
              name="ip"
              label="IP"
              placeholder="IP"
              disabled={!device?.player?.ip}
              defaultValue={device?.player?.ip}
              data-testid="ip"
            />
          </Col>
          <Col>
            <TextField
              name="guid"
              label="GUID"
              placeholder="GUID"
              disabled={!device?.player?.token}
              defaultValue={device?.player?.token}
              data-testid="guid"
            />
          </Col>
        </Row>
      </div>
    </FormProvider>
  );
};

export default ResourcesPage;
