import React, { PropsWithChildren } from 'react';
import {
  Col,
  Container,
  UncontrolledDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Navbar,
  NavbarBrand,
  NavbarToggler
} from 'reactstrap';
import Icon from 'components/Icon';
import { NavLink as NavLinkRouter } from 'react-router-dom';
import AlertPopups from 'components/AlertPopups';
import MediaUploadProvider from 'contexts/mediaUpload';
import NotificationButton from 'components/NotificationButton';
import Sidebar from './Sidebar';
import classNames from 'classnames';
import useControllerDashboardLayout from './_presenters/useControllerDashboardLayout/useControllerDashboardLayout';
import iconDown from '../../assets/images/More.png';

type LayoutProps = PropsWithChildren<{
  routes: { [key: string]: any };
}>;
const DashboardLayout = ({ children, routes }: LayoutProps) => {
  const {
    user,
    onLogoutHandler,
    isOpen,
    toggle,
    dropdownOpen,
    toggleMenu
  } = useControllerDashboardLayout();

  return (
    <MediaUploadProvider>
      <Navbar
        color="white"
        fixed="top"
        container="fluid"
        light
        className={classNames(
          'sticky-top d-flex',
          'justify-content-between',
          'border',
          'border-top-0',
          'border-bottom-1',
          'border-gray-200',
          isOpen ? 'closed' : 'navbar'
        )}
      >
        {isOpen ? (
          <NavbarToggler className="border-0" onClick={toggle}>
            {' '}
            <Icon icon="list" className="text-primary" />
          </NavbarToggler>
        ) : (
          <NavbarToggler className="border-0" onClick={toggle}>
            {' '}
            <Icon icon="chevron-left" className="text-primary" />
          </NavbarToggler>
        )}
        <NavbarBrand className="d-flex align-items-center">
          <NotificationButton />
          <UncontrolledDropdown
            isOpen={dropdownOpen}
            toggle={toggleMenu}
            direction="down"
            data-bs-popper="static"
          >
            <DropdownToggle
              color="none"
              className="d-flex align-items-center border-0"
            >
              {user?.picture ? (
                <>
                  <img
                    width="44px"
                    height="44px"
                    src={user.picture}
                    alt="User"
                    className="rounded-circle mx-auto"
                  />
                  {user && (
                    <span className="d-flex flex-column justify-content-center fs-6 text-gray-800 bold ms-3">
                      {user.firstName} {user.lastName}
                      <small className="text-gray-700 semibold">
                        {user.role === 'CUSTOMER' ? 'Usuário' : 'Administrador'}
                      </small>
                    </span>
                  )}

                  <img
                    alt="iconDowm"
                    src={iconDown}
                    className="text-primary ms-4"
                  />
                </>
              ) : (
                <>
                  <Icon
                    icon="person-circle"
                    className="text-secondary"
                    width={44}
                    height={44}
                  />
                  {user && (
                    <span className="d-flex flex-column justify-content-center fs-6 text-gray-800 bold ms-3">
                      {user.firstName} {user.lastName}
                      <small className="text-gray-700 semibold">
                        {user.role === 'CUSTOMER' ? 'Usuário' : 'Admin'}
                      </small>
                    </span>
                  )}
                  <img
                    alt="iconDowm"
                    src={iconDown}
                    className="text-primary ms-4"
                  />
                </>
              )}
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem to={routes.USER_PROFILE.EDIT} tag={NavLinkRouter}>
                Sua Conta
              </DropdownItem>
              <DropdownItem onClick={onLogoutHandler}>
                Sair do Sistema
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </NavbarBrand>
      </Navbar>
      <Container fluid>
        <div className="row flex-nowrap">
          <Sidebar
            isOpen={!isOpen}
            toggleSidebar={toggle}
            routes={routes}
            data-bs-popper="static"
          />
          <Col className="w-100 p-0 m-0 bg-gray-100">
            <main
              role="main"
              className="h-100 overflow-x-hidden overflow-y-scroll "
            >
              <Container fluid className="bg-gray-100">
                {children}
              </Container>
            </main>
          </Col>
        </div>
      </Container>
      <AlertPopups />
    </MediaUploadProvider>
  );
};

export default DashboardLayout;
