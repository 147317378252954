import {
  Col,
  Row,
  Spinner,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Breadcrumb,
  BreadcrumbItem
} from 'reactstrap';
import SearchInput from 'components/SearchInput';
import PaginationNav from 'components/Pagination';
import { Link } from 'react-router-dom';
import useControllerAccessPoliciesListPage from './_presenters/useControllerAccessPoliciesListPage/useControllerAccessPoliciesListPage';
import Table from 'components/Table';
import { Column } from 'components/Table/_presenters/props/tableComponentProps';
import { ItemResource } from 'models/AccessPolicy';
import Modal from 'components/Modal';
import Icon from 'components/Icon';
import apiPaths from 'constants/apiPaths';

const AccessPoliciesListPage = () => {
  const {
    accessPoliciesPage,
    isLoading,
    handleSearch,
    handlePageChange,
    goToEntry,
    userCanAdd,
    openDropdownId,
    userCanDelete,
    toggleMenu,
    setAccessPoliciesIdToDelete,
    showDeleteConfirmation,
    handleClearFilter,
    handleOnDelete,
    setShowDeleteConfirmation
  } = useControllerAccessPoliciesListPage();

  const columns = [
    {
      accessor: 'name',
      label: 'Política de acesso',
      render: (title: string) => <span>{title}</span>
    },
    {
      accessor: 'resources',
      label: 'Recursos',
      render: (resources: ItemResource[]) => (
        <span>
          {resources.length
            ? resources
                .map((item) => item.label)
                .sort()
                .join(', ')
            : 'Nenhum Recurso associado'}
        </span>
      )
    },
    {
      accessor: 'actions',
      label: 'Ações',
      render: (_, row) => (
        <UncontrolledDropdown
          className="dropdown-table"
          isOpen={openDropdownId === row.id}
          toggle={() => toggleMenu(row.id)}
        >
          <DropdownToggle
            color="none"
            className="d-flex align-items-center border-0"
            onClick={(e) => e.stopPropagation()}
          >
            {openDropdownId === row.id ? (
              <Icon icon="chevron-up" className="text-primary ms-2" />
            ) : (
              <Icon icon="chevron-down" className="text-primary ms-2" />
            )}
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem header>AÇÕES</DropdownItem>
            <DropdownItem onClick={() => goToEntry(row)}>
              Visualizar
            </DropdownItem>
            {userCanDelete && (
              <DropdownItem
                onClick={() => {
                  setAccessPoliciesIdToDelete(row.id);
                  setShowDeleteConfirmation(true);
                }}
              >
                Excluir
              </DropdownItem>
            )}
          </DropdownMenu>
        </UncontrolledDropdown>
      )
    }
  ] as Column<{
    id: string;
    name: string;
    resources: ItemResource[];
  }>[];

  return (
    <div className="padding-page">
      <Row className="d-flex flex-column flex-md-row">
        <Col className="mb-3">
          <Breadcrumb>
            <BreadcrumbItem active>Políticas de Acesso</BreadcrumbItem>
          </Breadcrumb>
          <h4 className="fw-bolder">Políticas de Acesso</h4>
          <span className="small-typography text-gray-600">
            Realize a gestão das políticas de acesso dos usuários.
          </span>
        </Col>
        {userCanAdd && accessPoliciesPage?.items.length !== 0 && (
          <Col className="col-12 col-md align-text mt-3 mt-md-0">
            <Link
              to={apiPaths.accessPoliciesCreate}
              className="btn btn-primary text-white button-register"
            >
              Adicionar política
            </Link>
          </Col>
        )}
      </Row>
      <Row className="d-flex align-items-center mt-2 flex-column flex-md-row">
        <Col className="col-12 col-md">
          <SearchInput
            onClearFilter={handleClearFilter}
            onSearchTextChange={handleSearch}
            placeholder="Buscar"
          />
        </Col>
      </Row>
      {isLoading && (
        <tr>
          <td colSpan={2} className="text-center p-5">
            <Spinner />
          </td>
        </tr>
      )}
      {!isLoading &&
        accessPoliciesPage?.items &&
        accessPoliciesPage?.items.length !== 0 && (
          <Table<{
            id: string;
            name: string;
            resources: ItemResource[];
          }>
            data={accessPoliciesPage?.items || []}
            columns={columns}
            isLoading={isLoading}
          />
        )}
      {!isLoading && accessPoliciesPage?.items.length === 0 && (
        <div
          className="d-flex flex-column align-items-center justify-content-center position-absolute top-50 start-50 translate-middle 
     top-md-40 start-md-50 top-sm-25 start-sm-50"
        >
          <Icon icon="person-gear" width={151} height={94.61} />
          <h4 className="text-primary fw-bold mb-2">
            Cadastre as políticas de acesso
          </h4>
          <span className="small-typography text-gray-600  mb-1">
            Cadastre e veja as informações sobre as políticas de acesso.
          </span>
          <Link
            to={'/access-policies/create'}
            className="btn btn-primary text-white button-register"
          >
            Cadastrar
          </Link>
        </div>
      )}
      <div className="d-flex justify-content-between align-items-center flex-column flex-md-row mt-3">
        {accessPoliciesPage && accessPoliciesPage?.items.length !== 0 && (
          <PaginationNav
            onChange={handlePageChange}
            totalPages={accessPoliciesPage.totalPages}
            currentPage={accessPoliciesPage.page}
          />
        )}
      </div>
      <Modal
        isOpen={showDeleteConfirmation}
        title="Confirmar Exclusão"
        toggle={() => setShowDeleteConfirmation(false)}
        primaryButtonLabel="Excluir"
        primaryButtonAction={handleOnDelete}
        secondaryButtonLabel="Cancelar"
        secondaryButtonAction={() => setShowDeleteConfirmation(false)}
      >
        <p>
          Tem certeza de que deseja excluir esta política de acesso? Essa ação
          não pode ser desfeita.
        </p>
      </Modal>
    </div>
  );
};

export default AccessPoliciesListPage;
