import { Col, Row, Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { Link } from 'react-router-dom';
import Button from 'components/Button';
import Form from 'components/Form';
import TextField from 'components/TextField';
import { Loader } from 'react-bootstrap-typeahead';
import AsyncTypeaheadField from 'components/AsyncTypeaheadField';
import DateField from 'components/DateField';
import useControllerPlaylistCreatePage from './_presenters/useControllerPlaylistCreatePage/useControllerPlaylistCreatePage';
import apiPaths from 'constants/apiPaths';
import SelectField from 'components/SelectField';
import ScreenOrientation from 'enum/ScreenOrientation';

const PlaylistCreatePage = () => {
  const {
    formMethods,
    isFetching,
    isSaving,
    deviceGroupOptions,
    typeOptionsOrientation,
    handleOnSubmit,
    userCanPerformAction,
    deviceGroupRefetch,
    isLoadingDeviceGroup,
    playlist,
    canSubmit,
    typeOptionsActive
  } = useControllerPlaylistCreatePage();

  return (
    <>
      <div className="mx-3">
        <Form onSubmit={handleOnSubmit} {...formMethods}>
          <Row className="d-flex align-items-center">
            <Col className="mb-3">
              <Breadcrumb className="mt-4">
                <BreadcrumbItem active>
                  <Link
                    to={apiPaths.tvCorporate}
                    className="text-decoration-none"
                  >
                    Tv Corporativa
                  </Link>
                </BreadcrumbItem>
                <BreadcrumbItem className="text-gray-600 fw-medium">
                  Playlist
                </BreadcrumbItem>
              </Breadcrumb>
              <h4>Playlist</h4>
              <span className="small-typography text-gray-600">
                Insira as informações que compõe sua playlist
              </span>
            </Col>
            <Col className="text-end">
              {userCanPerformAction && (
                <Button
                  color="primary"
                  type="submit"
                  className="btn btn-primary text-white button-register"
                  disabled={!canSubmit}
                  loading={isSaving}
                >
                  Cadastrar
                </Button>
              )}
            </Col>
          </Row>
          <hr />
          {isFetching ? (
            <Loader />
          ) : (
            <div className="shadow bg-white form">
              <Col className="mx-4 mt-3">
                <TextField
                  name="name"
                  label="Nome"
                  placeholder="Digite o nome"
                  disabled={!userCanPerformAction}
                  defaultValue={playlist?.name}
                />

                <AsyncTypeaheadField
                  isLoading={isLoadingDeviceGroup}
                  name="deviceGroup"
                  label="Grupo"
                  options={deviceGroupOptions}
                  onSearch={(search: string) =>
                    deviceGroupRefetch({
                      params: { search }
                    } as any)
                  }
                  disabled={!userCanPerformAction}
                  multiple={false}
                />

                <DateField
                  name="startDate"
                  label="Data de inicio de execução (Opcional)"
                  type="datetime-local"
                />

                <DateField
                  name="endDate"
                  label="Data de fim de execução (Opcional)"
                  type="datetime-local"
                />

                <SelectField
                  label="Orientação visual"
                  name="screenOrientation"
                  options={typeOptionsOrientation}
                  defaultValue={
                    playlist?.screenOrientation || ScreenOrientation.PORTRAIT
                  }
                />
                <SelectField
                  name="active"
                  label="Status"
                  options={typeOptionsActive}
                  defaultValue={String(true) || String(playlist?.active)}
                />
              </Col>
            </div>
          )}
        </Form>
      </div>
    </>
  );
};
export default PlaylistCreatePage;
