import React, { useCallback, useMemo, useState } from 'react';
import { useApiGet, useApiDelete } from 'hooks/useApi';
import Pagination from 'models/Pagination';
import { useNavigate } from 'react-router-dom';
import useUser from 'hooks/useUser';
import TypeResource from 'enum/TypeResource';
import TypeAction from 'enum/TypeAction';
import { DeviceCompany } from 'models/DeviceGroup';
import apiPaths from 'constants/apiPaths';
import { useToast } from 'hooks/useToast';
import ToastType from 'enum/ToastType';

const useControllerDeviceGroupListPage = () => {
  const navigate = useNavigate();
  const { addToast } = useToast();
  const [filteredData, setFilteredData] = React.useState({
    pageSize: 10,
    page: 1,
    search: ''
  });
  const { data: deviceGroupData, isLoading, refetch } = useApiGet<
    Pagination<{
      id: string;
      name: string;
      company: DeviceCompany;
      active: boolean;
    }>
  >(apiPaths.deviceGroup, {
    params: filteredData
  });

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [deviceIdToDelete, setDeviceIdToDelete] = useState<string | null>(null);

  const { mutate: mutateDelete } = useApiDelete(
    `${apiPaths.deviceGroup}/${deviceIdToDelete}`
  );

  const handleSearch = useCallback(
    (search: string) => {
      setFilteredData({ ...filteredData, search });
    },
    [filteredData]
  );
  const handlePageChange = useCallback(
    (page: number) => {
      setFilteredData({ ...filteredData, page });
    },
    [filteredData]
  );
  const goToEdit = useCallback(
    (row: { id: string }) => {
      navigate(`/device/group/${row.id}`);
    },
    [navigate]
  );
  const user = useUser();
  const userCanAdd = useMemo(() => {
    return user?.resources?.[TypeResource.DEVICES_GROUPS]?.includes(
      TypeAction.CREATE
    );
  }, [user]);

  const userCanDelete = useMemo(() => {
    return user?.resources?.[TypeResource.COMPANIES]?.includes(
      TypeAction.DELETE
    );
  }, [user]);

  const handleClearFilter = useCallback(() => {
    setFilteredData({ pageSize: 10, page: 1, search: '' });
  }, []);

  const [openDropdownId, setOpenDropdownId] = useState<string | null>(null);

  const toggleMenu = (id: string) => {
    setOpenDropdownId((prevId) => (prevId === id ? null : id));
  };

  const handleDeleteClick = useCallback((id: string) => {
    setDeviceIdToDelete(id);
    setShowDeleteConfirmation(true);
  }, []);

  const handleOnDelete = useCallback(() => {
    if (!deviceIdToDelete || !userCanDelete) return;
    mutateDelete(null as any, {
      onError: (response: any) => {
        addToast(
          ToastType.ERROR,
          response.errors?.detail?.toString() ||
            'Erro ao excluir grupo de dispositivo'
        );
      },
      onSuccess: () => {
        setShowDeleteConfirmation(false);
        setDeviceIdToDelete(null);
        addToast(
          ToastType.SUCCESS,
          'Grupo de dispositivo excluído com sucesso'
        );
        refetch();
      }
    });
  }, [addToast, mutateDelete, userCanDelete, deviceIdToDelete, refetch]);

  return {
    deviceGroupData,
    isLoading,
    handleSearch,
    handlePageChange,
    goToEdit,
    userCanAdd,
    handleClearFilter,
    showDeleteConfirmation,
    handleDeleteClick,
    handleOnDelete,
    userCanDelete,
    openDropdownId,
    setOpenDropdownId,
    toggleMenu,
    setDeviceIdToDelete,
    setShowDeleteConfirmation
  };
};

export default useControllerDeviceGroupListPage;
