import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useApiDelete, useApiGet, useApiPost, useApiPut } from 'hooks/useApi';
import TypeResource from 'enum/TypeResource';
import TypeAction from 'enum/TypeAction';
import useFieldSetErrors from 'hooks/useFieldSetErrors';
import { useToast } from 'hooks/useToast';
import UrlExternal from 'models/UrlExternal';
import useUser from 'hooks/useUser';
import apiPaths from 'constants/apiPaths';
import ToastType from 'enum/ToastType';

const yupSchema = yup.object({
  url: yup.string().required('A url é obrigatória.'),
  duration: yup.number(),
  isIndefinite: yup.mixed().oneOf(['false', 'true'])
});

const useControllerUrlExternalFormPage = () => {
  const params = useParams();
  const user = useUser();
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const navigate = useNavigate();
  const { addToast } = useToast();

  const { data, isLoading: isFetching } = useApiGet<UrlExternal>(
    `/media-manager/url-external`,
    {
      id: params.id,
      enabled: !!params.id
    }
  );
  const { mutate: mutateCreate, isPending: isCreating } = useApiPost<
    UrlExternal
  >(apiPaths.urlExternal);
  const { mutate: mutateUpdate, isPending: isUpdating } = useApiPut<
    UrlExternal
  >(apiPaths.urlExternal, params.id);

  const { mutate: mutateDelete, isPending: isDeleting } = useApiDelete(
    apiPaths.urlExternal,
    params.id
  );

  const mutate = params.id ? mutateUpdate : mutateCreate;

  const userCanPerformAction = useMemo(() => {
    if (params.id) {
      return user?.resources?.[TypeResource.URL_EXTERNAL]?.includes(
        TypeAction.UPDATE
      );
    } else {
      return user?.resources?.[TypeResource.URL_EXTERNAL]?.includes(
        TypeAction.CREATE
      );
    }
  }, [user, params.id]);

  const userCanDelete = useMemo(() => {
    return user?.resources?.[TypeResource.URL_EXTERNAL]?.includes(
      TypeAction.DELETE
    );
  }, [user]);

  const isSaving = isCreating || isUpdating;

  const urlExternal = params.id ? data : undefined;

  const formMethods = useForm({
    resolver: yupResolver(yupSchema)
  });

  const watchedFields = useWatch({
    control: formMethods.control
  });

  const isIndefiniteValue = useWatch({
    control: formMethods.control,
    name: 'isIndefinite',
    defaultValue: 'false'
  });

  const canSubmit = useMemo(() => {
    const { url, duration } = watchedFields;
    if (!url) return false;
    if (String(isIndefiniteValue) === 'true') return true;
    return (duration ?? 0) > 0;
  }, [watchedFields, isIndefiniteValue]);

  const onError = useFieldSetErrors(formMethods.setError);

  const handleOnSubmit = useCallback(
    (data: UrlExternal) => {
      if (!userCanPerformAction) return;
      const payload = {
        ...data,
        isIndefinite: String(data.isIndefinite) === 'true',
        duration: String(data.isIndefinite) === 'true' ? 0 : data.duration
      };
      mutate(payload, {
        onError,
        onSuccess: () => {
          addToast(ToastType.SUCCESS, 'URL salva com sucesso');
          navigate('/media-manager/corporate-tv?tab=external-url');
        }
      });
    },
    [userCanPerformAction, mutate, onError, addToast, navigate]
  );

  const handleOnDelete = useCallback(() => {
    if (!params.id || !userCanDelete) return;
    mutateDelete(null as any, {
      onError: (response: any) => {
        addToast(
          ToastType.ERROR,
          response.errors?.detail?.toString() || 'Erro ao excluir a URL'
        );
      },
      onSuccess: () => {
        addToast(ToastType.SUCCESS, 'URL excluída com sucesso');
        navigate('/media-manager/corporate-tv?tab=external-url');
      }
    });
  }, [addToast, mutateDelete, navigate, params.id, userCanDelete]);

  useEffect(() => {
    if (urlExternal) {
      formMethods.reset({
        url: urlExternal.url,
        duration: urlExternal.duration,
        isIndefinite: urlExternal.isIndefinite ? 'true' : 'false'
      } as any);
    }
  }, [urlExternal, formMethods]);

  return {
    params,
    showDeleteConfirmation,
    setShowDeleteConfirmation,
    isFetching,
    isDeleting,
    userCanPerformAction,
    formMethods,
    handleOnSubmit,
    isSaving,
    urlExternal,
    handleOnDelete,
    isIndefiniteValue,
    userCanDelete,
    canSubmit
  };
};

export default useControllerUrlExternalFormPage;
