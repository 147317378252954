import { Col, Row, Breadcrumb, BreadcrumbItem } from 'reactstrap';
import Button from 'components/Button';
import Form from 'components/Form';
import TextField from 'components/TextField';
import { Loader } from 'react-bootstrap-typeahead';
import AsyncTypeaheadField from 'components/AsyncTypeaheadField';
import useControllerDeviceFormPage from './_presenters/useControllerDeviceFormPage/useControllerDeviceFormPage';
import { Link } from 'react-router-dom';
import apiPaths from 'constants/apiPaths';
import SelectField from 'components/SelectField';
import Modal from 'components/Modal';
import ScreenOrientation from 'enum/ScreenOrientation';

const DeviceFormPage = () => {
  const {
    device,
    isFetching,
    deviceGroupRefetch,
    isLoadingDeviceGroup,
    formMethods,
    isSaving,
    handleOnSubmit,
    userCanPerformAction,
    deviceGroupOptions,
    typeOptionsOrientation,
    canSubmit,
    typeOptionsActive,
    params,
    isDeleting,
    showDeleteConfirmation,
    setShowDeleteConfirmation,
    handleOnDelete,
    userCanDelete
  } = useControllerDeviceFormPage();
  return (
    <>
      <div className="mx-3">
        <Form onSubmit={handleOnSubmit} {...formMethods}>
          <Row className="d-flex align-items-center">
            {!params.id && (
              <Col className="mb-3">
                <Breadcrumb className="mt-4">
                  <BreadcrumbItem active>
                    <Link
                      to={apiPaths.devices}
                      className="text-decoration-none"
                    >
                      Dispositivos
                    </Link>
                  </BreadcrumbItem>
                  <BreadcrumbItem className="text-gray-600 fw-medium">
                    Cadastrar Dispositivo
                  </BreadcrumbItem>
                </Breadcrumb>
                <h4>Dispositivo</h4>
                <span className="small-typography text-gray-600">
                  Cadastre seu dispositivo
                </span>
              </Col>
            )}
            <Col className="d-flex align-items-center justify-content-end">
              {userCanPerformAction && !params.id && (
                <Button
                  color="primary"
                  type="submit"
                  className="btn btn-primary text-white button-register"
                  disabled={!canSubmit}
                  isLoading={isSaving}
                >
                  Cadastrar
                </Button>
              )}
            </Col>
          </Row>
          {isFetching ? (
            <Loader />
          ) : (
            <div className="shadow bg-white form">
              <Col className="mx-4 mt-3">
                <TextField
                  name="name"
                  label="Nome do dispositivo"
                  placeholder="Digite o nome do dispositivo"
                  disabled={!userCanPerformAction}
                  defaultValue={device?.name}
                />

                <AsyncTypeaheadField
                  isLoading={isLoadingDeviceGroup}
                  name="group"
                  label="Grupo"
                  options={deviceGroupOptions}
                  onSearch={(search: string) =>
                    deviceGroupRefetch({
                      params: { search }
                    } as any)
                  }
                  disabled={!userCanPerformAction}
                  multiple={false}
                />

                <TextField
                  name="local"
                  label="Local"
                  placeholder="Local"
                  disabled={!userCanPerformAction}
                  defaultValue={device?.local}
                />

                <SelectField
                  label="Orientação"
                  name="screenOrientation"
                  options={typeOptionsOrientation}
                  disabled={!userCanPerformAction}
                  defaultValue={
                    device?.screenOrientation || ScreenOrientation.PORTRAIT
                  }
                />
                <SelectField
                  name="active"
                  label="Status"
                  options={typeOptionsActive}
                  disabled={!userCanPerformAction}
                  defaultValue={String(device?.active) || String(true)}
                />
              </Col>
            </div>
          )}
          <div className="d-flex justify-content-between align-items-center mt-4">
            {userCanDelete && params.id && (
              <Button
                type="button"
                color="red-400"
                loading={isDeleting}
                className="me-2 text-white"
                onClick={() => setShowDeleteConfirmation(true)}
              >
                Excluir dispositivo
              </Button>
            )}
            {userCanPerformAction && params.id && (
              <Button
                color="primary"
                type="submit"
                className="text-white btn btn-primary"
                disabled={!canSubmit}
                isLoading={isSaving}
              >
                Salvar as alterações
              </Button>
            )}
          </div>
        </Form>
        <Modal
          primaryButtonAction={handleOnDelete}
          primaryButtonLabel="Excluir"
          title="Atenção!"
          isOpen={showDeleteConfirmation}
          showCloseButton={false}
          toggle={() => setShowDeleteConfirmation((x) => !x)}
          secondaryButtonAction={() => setShowDeleteConfirmation(false)}
          secondaryButtonLabel="Cancelar"
        >
          <p>
            Deseja realmente excluir o dispositivo? <br />
            <small>Essa alteração não poderá ser revertida.</small>
          </p>
        </Modal>
      </div>
    </>
  );
};
export default DeviceFormPage;
