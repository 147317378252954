import React, { PropsWithChildren } from 'react';
import {
  Modal as ReactstrapModal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from 'reactstrap';
import Button from 'components/Button';

type ModalProps = PropsWithChildren<{
  isOpen: boolean;
  toggle: () => void;
  title: React.ReactNode;
  primaryButtonLabel: string;
  primaryButtonAction?: () => void;
  secondaryButtonLabel?: string;
  secondaryButtonAction?: () => void;
  showCloseButton?: boolean;
  classNameHeader?: string;
  classNameFooter?: string;
  classNameBody?: string;
  isLoading?: boolean;
  primaryButtonType?: 'reset' | 'button' | 'submit';
  disabledPrimaryButton?: boolean;
  disabledSecondaryButton?: boolean;
}>;

const Modal = ({
  isOpen,
  toggle,
  title,
  children,
  primaryButtonLabel,
  primaryButtonAction,
  secondaryButtonLabel,
  secondaryButtonAction,
  showCloseButton = true,
  classNameHeader,
  classNameFooter,
  classNameBody,
  isLoading,
  primaryButtonType,
  disabledPrimaryButton,
  disabledSecondaryButton
}: ModalProps) => {
  return (
    <ReactstrapModal isOpen={isOpen} toggle={toggle}>
      <ModalHeader
        className={classNameHeader}
        toggle={showCloseButton ? toggle : undefined}
      >
        {title}
      </ModalHeader>
      <ModalBody className={classNameBody}>{children}</ModalBody>
      <ModalFooter className={classNameFooter}>
        <Button
          color="primary"
          className="text-white"
          onClick={primaryButtonAction}
          loading={isLoading}
          type={primaryButtonType}
          disabled={disabledPrimaryButton}
        >
          {primaryButtonLabel}
        </Button>{' '}
        {secondaryButtonLabel && secondaryButtonAction && (
          <Button
            color="secondary"
            className="text-white"
            onClick={secondaryButtonAction}
            loading={isLoading}
            type="button"
            disabled={disabledSecondaryButton}
          >
            {secondaryButtonLabel}
          </Button>
        )}
      </ModalFooter>
    </ReactstrapModal>
  );
};

export default Modal;
