import { FormProvider, UseFormReturn } from 'react-hook-form';
import { PropsWithChildren, useCallback, useState } from 'react';
import Icon from 'components/Icon';
import { Alert } from 'reactstrap';

type Props = PropsWithChildren<
  UseFormReturn<any> & {
    onSubmit: (data: any) => void;
  }
>;
const Form = ({ onSubmit, children, ...props }: Props) => {
  const onSubmitWrapper = useCallback((data: any) => onSubmit(data), [
    onSubmit
  ]);
  const [visible, setVisible] = useState(true);

  const onDismiss = () => setVisible(false);
  return (
    <FormProvider {...props}>
      <form
        onSubmit={(e) => {
          props.clearErrors();
          props.handleSubmit(onSubmitWrapper)(e);
        }}
      >
        {props.formState.errors?.root?.genericError && (
          <Alert
            className="d-flex align-items-center border-top-0 border-end-0 border-bottom-0 border-4 border-red-300 bg-red-100 text-red-800 small-typography"
            isOpen={visible}
            toggle={onDismiss}
          >
            <Icon
              icon="exclamation-triangle-fill"
              width={20}
              height={20}
              className="me-2"
            />
            <span className="ms-2">
              {props.formState.errors.root.genericError.message}
            </span>
          </Alert>
        )}
        {children}
      </form>
    </FormProvider>
  );
};

export default Form;
