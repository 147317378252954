import { Button, Col, Row } from 'reactstrap';
import logo from 'assets/images/logo 3.png';
import group from 'assets/images/Group 4.png';
import Form from 'components/Form';
import TextField from 'components/TextField';
import { Link } from 'react-router-dom';
import PasswordField from 'components/PasswordField';
import useControllerLoginUserPage from 'pages/Login/LoginUserPage/_presenters/useControllerLoginUserPage/useControllerLoginUserPage';
import CheckboxField from 'components/CheckboxField';
import { ROUTES_PATHS } from 'navigations/routes';
import classNames from 'classnames';

const LoginPage = () => {
  const {
    methods,
    onSubmitHandler,
    isPending,
    buttonColor
  } = useControllerLoginUserPage();
  return (
    <Row className={classNames('d-flex align-items-center page-direction')}>
      <Col className="image-col">
        <img src={group} alt="People talking" className="image-fill" />
      </Col>
      <Col className="d-flex flex-column align-items-center">
        <img src={logo} alt="logo" className="object-fit-none m-3" />
        <div
          className={classNames(
            'shadow-lg p-3 mb-5 bg-body-tertiary rounded box-size'
          )}
        >
          <h4 className="py-2 text-center fw-bold">Acesse com a sua conta</h4>
          <Form onSubmit={onSubmitHandler} {...methods}>
            <p>E-mail</p>
            <TextField
              type="text"
              name="username"
              placeholder="Digite seu e-mail"
            />
            <p>Senha</p>
            <PasswordField
              type="password"
              name="password"
              placeholder="Digite sua senha"
            />
            <div className="d-flex justify-content-between mb-3">
              <CheckboxField
                name="remember"
                label="Relembrar dados"
                className="d-flex justify-content-end"
              />
              <Link to={ROUTES_PATHS.AUTH_PAGES.FORGOT_PASSWORD}>
                Esqueci minha senha
              </Link>
            </div>
            <Button
              color={buttonColor}
              className="w-100 text-white mb-3"
              disabled={isPending}
            >
              Acessar
            </Button>
            <Link
              className="btn btn-outline-primary w-100"
              to={ROUTES_PATHS.AUTH_PAGES.SIGNUP}
            >
              Cadastre-se
            </Link>
          </Form>
          <div></div>
        </div>
      </Col>
    </Row>
  );
};

export default LoginPage;
