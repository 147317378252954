import React, { useCallback, useMemo } from 'react';
import { useApiGet } from 'hooks/useApi';
import Pagination from 'models/Pagination';
import { Campaign } from 'models/MediaFileCampaign';

type CampaignListDragPageProps = {
  screenOrientation: string;
};

const useControllerCampaignListDragPage = ({
  screenOrientation
}: CampaignListDragPageProps) => {
  const [filteredData, setFilteredData] = React.useState({
    pageSize: 10,
    page: 1,
    search: ''
  });
  const { data: campaignsData, isLoading } = useApiGet<Pagination<Campaign>>(
    '/media-manager/campaigns',
    {
      params: filteredData
    }
  );

  const handleSearch = useCallback(
    (search: string) => {
      setFilteredData({ ...filteredData, search });
    },
    [filteredData]
  );
  const handlePageChange = useCallback(
    (page: number) => {
      setFilteredData({ ...filteredData, page });
    },
    [filteredData]
  );

  const filteredCampaigns = useMemo(() => {
    return campaignsData?.items.filter(
      (campaign) => campaign.screenOrientation === screenOrientation
    );
  }, [campaignsData, screenOrientation]);

  return {
    filteredData,
    filteredCampaigns,
    handleSearch,
    handlePageChange,
    isLoading,
    campaignsData,
    setFilteredData
  };
};

export default useControllerCampaignListDragPage;
