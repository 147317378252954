import { useCallback, useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useApiGet, useApiPost } from 'hooks/useApi';
import TypeResource from 'enum/TypeResource';
import TypeAction from 'enum/TypeAction';
import useFieldSetErrors from 'hooks/useFieldSetErrors';
import { useToast } from 'hooks/useToast';
import { Playlist } from 'models/Playlist';
import useUser from 'hooks/useUser';
import { Option } from 'components/AsyncTypeaheadField';
import ScreenOrientation from 'enum/ScreenOrientation';
import DeviceGroup from 'models/DeviceGroup';
import Pagination from 'models/Pagination';
import apiPaths from 'constants/apiPaths';

type FormValue = {
  name: string;
  active: boolean;
  deviceGroup: Option | null;
  screenOrientation: ScreenOrientation;
  endDate: string;
  startDate: string;
};

const yupSchema = yup.object({
  name: yup.string().required('O nome é obrigatório.'),
  deviceGroup: yup.object().required('O grupo é obrigatório.'),
  screenOrientation: yup
    .mixed<ScreenOrientation>()
    .oneOf(Object.values(ScreenOrientation))
    .required(),
  active: yup.boolean().default(true),
  startDate: yup.string(),
  endDate: yup.string()
});

const useControllerPlaylistCreatePage = () => {
  const params = useParams();
  const user = useUser();
  const navigate = useNavigate();
  const { addToast } = useToast();

  const { data, isLoading: isFetching } = useApiGet<Playlist>(
    `${apiPaths.playlists}`,
    {
      id: params.id,
      enabled: !!params.id
    }
  );
  const { mutate: mutateCreate, isPending: isCreating } = useApiPost<Playlist>(
    apiPaths.playlists
  );

  const {
    data: deviceGroupData,
    refetch: deviceGroupRefetch,
    isLoading: isLoadingDeviceGroup
  } = useApiGet<Pagination<DeviceGroup>>(apiPaths.deviceGroup);

  const mutate = mutateCreate;

  const userCanPerformAction = useMemo(() => {
    return user?.resources?.[TypeResource.PLAYLISTS]?.includes(
      TypeAction.CREATE
    );
  }, [user]);

  const isSaving = isCreating;

  const playlist = params.id ? data : undefined;

  const formMethods = useForm<FormValue>({
    resolver: yupResolver(yupSchema as yup.ObjectSchema<FormValue>),
    defaultValues: {
      name: '',
      active: true,
      deviceGroup: null,
      startDate: undefined,
      endDate: undefined,
      screenOrientation: ScreenOrientation.PORTRAIT
    }
  });

  const watchedFields = useWatch({
    control: formMethods.control
  });

  const canSubmit = useMemo(() => {
    const { name, deviceGroup } = watchedFields;
    return name && deviceGroup !== undefined;
  }, [watchedFields]);

  const onError = useFieldSetErrors(formMethods.setError);

  const handleOnSubmit = useCallback(
    (data: FormValue) => {
      if (!userCanPerformAction) return;
      const payload = {
        deviceGroup: data.deviceGroup?.value!,
        name: data.name,
        active: String(data.active),
        screenOrientation: data.screenOrientation,
        startDate: data.startDate || undefined,
        endDate: data.endDate || undefined
      } as any;
      mutate(payload, {
        onError,
        onSuccess: (payload) => {
          addToast('success', 'Playlist salva com sucesso');
          navigate(`/media-manager/playlists/${payload.id}`);
        }
      });
    },
    [userCanPerformAction, mutate, onError, addToast, navigate]
  );

  const deviceGroupOptions = useMemo(() => {
    const data = deviceGroupData?.items || [];
    return data.map(
      (device) =>
        ({
          label: device.name,
          value: device.id
        } as any)
    );
  }, [deviceGroupData]);

  const typeOptionsOrientation = [
    {
      value: ScreenOrientation.PORTRAIT,
      label: 'Retrato'
    },
    {
      value: ScreenOrientation.LANDSCAPE,
      label: 'Paisagem'
    }
  ];

  const typeOptionsActive = [
    { value: true, label: 'Ativo' },
    {
      value: false,
      label: 'Inativo'
    }
  ];

  useEffect(() => {
    if (playlist) {
      formMethods.reset({
        name: playlist.name,
        active: String(playlist.active),
        screenOrientation: playlist.screenOrientation,
        deviceGroup: playlist.deviceGroup
          ? {
              label: playlist.deviceGroup?.name,
              value: playlist.deviceGroup?.id
            }
          : null,
        startDate: playlist.startDate,
        endDate: playlist.endDate
      } as any);
    }
  }, [playlist, formMethods]);

  return {
    formMethods,
    isFetching,
    isSaving,
    deviceGroupOptions,
    typeOptionsOrientation,
    handleOnSubmit,
    userCanPerformAction,
    deviceGroupRefetch,
    isLoadingDeviceGroup,
    playlist,
    canSubmit,
    typeOptionsActive
  };
};

export default useControllerPlaylistCreatePage;
