import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useApiDelete, useApiGet, useApiPost, useApiPut } from 'hooks/useApi';
import TypeResource from 'enum/TypeResource';
import TypeAction from 'enum/TypeAction';
import useFieldSetErrors from 'hooks/useFieldSetErrors';
import { useToast } from 'hooks/useToast';
import DeviceGroup from 'models/DeviceGroup';
import useUser from 'hooks/useUser';
import CustomerCompanies from 'models/CustomerCompanies';
import Pagination from 'models/Pagination';
import { Option } from 'components/AsyncTypeaheadField';
import ToastType from 'enum/ToastType';
import apiPaths from 'constants/apiPaths';

type FormValue = {
  name: string;
  active: boolean;
  company: Option | null;
};

const yupSchema = yup
  .object({
    name: yup.string().required('O nome é obrigatório.'),
    active: yup.boolean().required(),
    company: yup.object().required('A empresa é obrigatória.')
  })
  .required();

const DeviceGroupFormPage = () => {
  const params = useParams();
  const user = useUser();
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const navigate = useNavigate();
  const { addToast } = useToast();
  const [filteredData, setFilteredData] = React.useState({
    pageSize: 10,
    page: 1,
    search: ''
  });

  const {
    data: companiesData,
    isLoading: companiesLoading,
    refetch: companiesRefetch
  } = useApiGet<Pagination<CustomerCompanies>>(apiPaths.customerCompanies, {
    params: filteredData
  });

  const { data, isLoading: isFetching } = useApiGet<DeviceGroup>(
    apiPaths.deviceGroup,
    { id: params.id, enabled: !!params.id }
  );
  const { mutate: mutateCreate, isPending: isCreating } = useApiPost(
    apiPaths.deviceGroup
  );
  const { mutate: mutateUpdate, isPending: isUpdating } = useApiPut(
    apiPaths.deviceGroup,
    params.id
  );

  const { mutate: mutateDelete, isPending: isDeleting } = useApiDelete(
    apiPaths.deviceGroup,
    params.id
  );

  const mutate = params.id ? mutateUpdate : mutateCreate;

  const userCanPerformAction = useMemo(() => {
    if (params.id) {
      return user?.resources?.[TypeResource.USERS]?.includes(TypeAction.UPDATE);
    } else {
      return user?.resources?.[TypeResource.USERS]?.includes(TypeAction.CREATE);
    }
  }, [user, params.id]);

  const userCanDelete = useMemo(() => {
    return user?.resources?.[TypeResource.USERS]?.includes(TypeAction.DELETE);
  }, [user]);

  const isSaving = isCreating || isUpdating;

  const deviceGroups = params.id ? data : undefined;

  const formMethods = useForm<FormValue>({
    resolver: yupResolver(yupSchema as yup.ObjectSchema<FormValue>),
    defaultValues: {
      name: '',
      active: true,
      company: null
    }
  });

  const onError = useFieldSetErrors(formMethods.setError);

  const companiesDataOptions = useMemo(() => {
    return (
      companiesData?.items.map((company) => ({
        label: company.name,
        value: company.id
      })) ?? []
    );
  }, [companiesData]);

  const handleOnSubmit = useCallback(
    (data: FormValue) => {
      if (!userCanPerformAction) return;
      const payload = {
        company: data.company?.value!,
        name: data.name,
        active: data.active
      } as any;
      mutate(payload, {
        onError,
        onSuccess: () => {
          addToast(ToastType.SUCCESS, 'Grupo de dispositivo salvo com sucesso');
          navigate('/devices?tab=device-group');
        }
      });
    },
    [userCanPerformAction, mutate, onError, addToast, navigate]
  );

  const handleOnDelete = useCallback(() => {
    if (!params.id || !userCanDelete) return;
    mutateDelete(null as any, {
      onError: (response: any) => {
        addToast(
          ToastType.ERROR,
          response.errors?.detail?.toString() ||
            'Erro ao excluir grupo de dispositivo'
        );
      },
      onSuccess: () => {
        addToast(
          ToastType.SUCCESS,
          'Grupo de dispositivos excluído com sucesso'
        );
        navigate('/devices?tab=device-group');
      }
    });
  }, [addToast, mutateDelete, navigate, params.id, userCanDelete]);

  useEffect(() => {
    if (deviceGroups) {
      formMethods.reset({
        name: deviceGroups.name,
        active: deviceGroups.active,
        company: deviceGroups.company
          ? {
              label: deviceGroups.company.name,
              value: deviceGroups.company.id
            }
          : null
      } as any);
    }
  }, [deviceGroups, formMethods]);

  const typeOptionsActive = [
    { value: true, label: 'Ativo' },
    {
      value: false,
      label: 'Inativo'
    }
  ];

  const handleSearch = (search: string) => {
    setFilteredData((prev: any) => ({
      ...prev,
      search,
      page: 1
    }));
    companiesRefetch();
  };

  return {
    formMethods,
    companiesDataOptions,
    companiesLoading,
    userCanPerformAction,
    showDeleteConfirmation,
    handleOnDelete,
    handleOnSubmit,
    isSaving,
    isFetching,
    deviceGroups,
    params,
    userCanDelete,
    isDeleting,
    setShowDeleteConfirmation,
    companiesRefetch,
    typeOptionsActive,
    handleSearch,
    filteredData
  };
};

export default DeviceGroupFormPage;
