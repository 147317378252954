import { ToastMessage } from 'types/ToastMessage';

export const toastStyles: Record<string, Omit<ToastMessage, 'message'>> = {
  success: {
    color: 'green-800',
    backgroundColor: 'green-100',
    borderColor: 'green-300',
    icon: 'check'
  },
  error: {
    color: 'red-800',
    backgroundColor: 'red-100',
    borderColor: 'red-300',
    icon: 'exclamation-triangle'
  }
};
