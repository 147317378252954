import { useContext } from 'react';
import { v4 as uuid } from 'uuid';
import { ToastStoreContext } from 'contexts/toast';
import { toastStyles } from 'constants/ToastStyles';

type ToastType = keyof typeof toastStyles;

export const useToast = () => {
  const { toasts, setToasts } = useContext(ToastStoreContext);

  const addToast = (type: ToastType, message: string) => {
    const toastStyle = toastStyles[type];
    setToasts({
      ...toasts,
      [uuid()]: {
        ...toastStyle,
        message
      }
    });
  };

  const removeToast = (id: string) => {
    const { [id]: _, ...rest } = toasts;
    setToasts(rest);
  };

  return {
    toasts,
    addToast,
    removeToast
  };
};
