import React from 'react';
import InputMask from 'react-input-mask';
import { FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import { InputProps } from 'reactstrap/types/lib/Input';
import { useController } from 'react-hook-form';

type Props = InputProps & {
  mask: string;
  label: string;
  name: string;
  placeholder?: string;
  valueDefault?: string;
};

const stripMask = (value: string) => value.replace(/\D/g, '');

const NumberMaskInputField = ({
  mask,
  label,
  name,
  placeholder,
  valueDefault = '',
  ...props
}: Props) => {
  const {
    field: { onChange, ...fieldProps },
    fieldState: { invalid, error }
  } = useController({ name, defaultValue: valueDefault });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    onChange(stripMask(value));
  };
  const inputId = `input-${name}`;
  return (
    <FormGroup>
      {label && <Label for={inputId}>{label}</Label>}
      <InputMask
        mask={mask}
        {...fieldProps}
        {...props}
        value={fieldProps.value}
        onChange={handleChange}
      >
        {() => (
          <Input
            id={inputId}
            type={props.type || 'text'}
            placeholder={placeholder}
            invalid={invalid}
          />
        )}
      </InputMask>
      {error && <FormFeedback>{error.message}</FormFeedback>}
    </FormGroup>
  );
};

export default NumberMaskInputField;
