import { Col, Row, Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { Link } from 'react-router-dom';
import Icon from 'components/Icon';
import Button from 'components/Button';
import Form from 'components/Form';
import TextField from 'components/TextField';
import { Loader } from 'react-bootstrap-typeahead';
import { MediaFileAndCampaignPlaylist } from 'models/Playlist';
import Modal from 'components/Modal';
import AsyncTypeaheadField from 'components/AsyncTypeaheadField';
import PlaylistItemsPage from './PlaylistItemsPage';
import { withDragAndDropProvider } from 'contexts/dragAndDropProvider';
import PlaylistList from 'components/Playlist/PlaylistList';
import MediaEditor from 'components/MediaEditor';
import ModalPlaylistsPreview from 'components/ModalPlaylistsPreview';
import PlayerPlaylistPreview from 'components/Playlist/PlayerPlaylistPreview';
import DateField from 'components/DateField';
import useControllerPlaylistEditPage from './_presenters/useControllerPlaylistEditPage/useControllerPlaylistEditPage';
import apiPaths from 'constants/apiPaths';
import SelectField from 'components/SelectField';

const PlaylistEditPage = () => {
  const {
    formMethods,
    isFetching,
    deviceGroupOptions,
    typeOptionsOrientation,
    handleOnSubmit,
    userCanPerformAction,
    deviceGroupRefetch,
    isLoadingDeviceGroup,
    playlist,
    isDeleting,
    handleOnDelete,
    isUpdatingPlaylistItems,
    isCreatingPlaylistItems,
    isUpdating,
    handleOnCampaignFinish,
    handleOnEditMedia,
    handleOnInternalsNewsFinish,
    handleOnUrlExternalFinish,
    handleReOrderMedias,
    openPreview,
    selectedPlaylist,
    isPreviewOpen,
    closePreview,
    mediaToPreview,
    setMediaToPreview,
    userCanDelete,
    showDeleteConfirmation,
    setShowDeleteConfirmation,
    handleOnFinish,
    refMediaEditor,
    typeOptionsActive
  } = useControllerPlaylistEditPage();

  return (
    <>
      <div className="mx-3">
        <Form onSubmit={handleOnSubmit} {...formMethods}>
          <Row className="d-flex align-items-center">
            <Col className="mb-3">
              <Breadcrumb className="mt-4">
                <BreadcrumbItem active>
                  <Link
                    to={apiPaths.tvCorporate}
                    className="text-decoration-none"
                  >
                    Tv Corporativa
                  </Link>
                </BreadcrumbItem>
                <BreadcrumbItem active>
                  <Link
                    to={apiPaths.tvCorporate}
                    className="text-decoration-none"
                  >
                    Playlist
                  </Link>
                </BreadcrumbItem>
                <BreadcrumbItem className="text-gray-600 fw-medium">
                  {playlist?.name}
                </BreadcrumbItem>
              </Breadcrumb>
              <h4 className="fw-bolder">{playlist?.name}</h4>
              <h5 className="text-gray-600 mt-4">Configurações da playlist</h5>
            </Col>
            <Col className="d-flex align-items-center justify-content-end">
              {userCanDelete && (
                <Button
                  type="button"
                  loading={isDeleting}
                  className="button-outline delete me-2"
                  onClick={() => setShowDeleteConfirmation(true)}
                >
                  Excluir
                </Button>
              )}
              {userCanPerformAction && (
                <Button
                  type="submit"
                  className="btn btn-primary text-white button-register"
                  loading={isUpdating}
                >
                  Salvar
                </Button>
              )}
            </Col>
          </Row>
          {isFetching ? (
            <Loader />
          ) : (
            <>
              <div className="shadow bg-white form">
                <Col className="mx-4 mt-3">
                  <TextField
                    name="name"
                    label="Nome"
                    placeholder="Digite o nome"
                    disabled={!userCanPerformAction}
                    defaultValue={playlist?.name}
                  />

                  <AsyncTypeaheadField
                    isLoading={isLoadingDeviceGroup}
                    name="deviceGroup"
                    label="Grupo"
                    options={deviceGroupOptions}
                    onSearch={(search: string) =>
                      deviceGroupRefetch({
                        params: { search }
                      } as any)
                    }
                    disabled={!userCanPerformAction}
                    multiple={false}
                  />

                  <DateField
                    name="startDate"
                    label="Data de inicio de execução (Opcional)"
                    type="datetime-local"
                  />

                  <DateField
                    name="endDate"
                    label="Data de fim de execução (Opcional)"
                    type="datetime-local"
                  />

                  <SelectField
                    label="Orientação visual"
                    name="screenOrientation"
                    options={typeOptionsOrientation}
                    defaultValue={playlist?.screenOrientation}
                  />
                  <SelectField
                    name="active"
                    label="Status"
                    options={typeOptionsActive}
                    defaultValue={String(playlist?.active)}
                  />
                </Col>
              </div>
              <div className="d-flex">
                <Col md={6} className="me-3">
                  <PlaylistItemsPage
                    playlistScreenOrientation={playlist?.screenOrientation!}
                  />
                </Col>
                <Col md={6} className="shadow bg-white form mt-4">
                  <div className="mx-4 mt-4">
                    <div className="pb-4 mb-5 d-flex align-items-center justify-content-between">
                      <h5 className="text-dark-secondary fw-medium">
                        Ordem de Prioridade
                      </h5>
                      <Button
                        className="white ms-2 border-0 text-white"
                        color="primary"
                        type="button"
                        onClick={() => openPreview(playlist!)}
                      >
                        <Icon
                          icon="collection-play"
                          className="me-3 text-white"
                        />
                        Reproduzir Playlist
                      </Button>
                    </div>
                    <PlaylistList
                      mediaItems={playlist?.items || []}
                      isLoading={
                        isUpdatingPlaylistItems ||
                        isCreatingPlaylistItems ||
                        isUpdating
                      }
                      onAddCampaign={handleOnCampaignFinish}
                      onAddMedia={handleOnEditMedia}
                      onAddInternalsNews={handleOnInternalsNewsFinish}
                      onAddUrlExternal={handleOnUrlExternalFinish}
                      onReOrderMedias={handleReOrderMedias}
                      playlistId={playlist?.id!}
                      onClick={(media: MediaFileAndCampaignPlaylist) =>
                        setMediaToPreview(media)
                      }
                    />
                  </div>
                  <MediaEditor onFinish={handleOnFinish} ref={refMediaEditor} />
                </Col>
              </div>
              {selectedPlaylist && (
                <PlayerPlaylistPreview
                  playlist={selectedPlaylist}
                  isOpen={isPreviewOpen}
                  onClose={closePreview}
                />
              )}
            </>
          )}
        </Form>
      </div>
      <Modal
        primaryButtonAction={handleOnDelete}
        primaryButtonLabel="Excluir"
        title="Atenção!"
        isOpen={showDeleteConfirmation}
        showCloseButton={false}
        toggle={() => setShowDeleteConfirmation((x) => !x)}
        secondaryButtonAction={() => setShowDeleteConfirmation(false)}
        secondaryButtonLabel="Cancelar"
      >
        <p>
          Deseja realmente excluir o playlist? <br />
          <small>Essa alteração não poderá ser revertida.</small>
        </p>
      </Modal>
      <ModalPlaylistsPreview
        mediaPlaylistPreview={mediaToPreview!}
        isOpen={!!mediaToPreview}
        onClose={() => setMediaToPreview(undefined)}
      />
    </>
  );
};

export default withDragAndDropProvider(PlaylistEditPage);
