import React, { useMemo } from 'react';
import { useController } from 'react-hook-form';
import { FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import { InputProps } from 'reactstrap/types/lib/Input';

type Props = {
  name: string;
  label?: string;
  yesNoOptions?: boolean;
  options?: { value: any; label: string }[];
  valueDefault?: any;
  disabled?: boolean;
} & InputProps;

const SelectField = ({
  name,
  options,
  yesNoOptions = false,
  label,
  placeholder,
  disabled,
  ...props
}: Props) => {
  const {
    field: fieldProps,
    fieldState: { error }
  } = useController({
    name,
    defaultValue: props.valueDefault
  });

  const optionsList = useMemo(() => {
    if (yesNoOptions) {
      return [
        { value: 'false', label: 'Não' },
        { value: 'true', label: 'Sim' }
      ].map(({ value, label }) => (
        <option key={value} value={value}>
          {label}
        </option>
      ));
    }
    if (options) {
      return options.map(({ value, label }) => (
        <option key={value} value={value}>
          {label}
        </option>
      ));
    }
    return null;
  }, [options, yesNoOptions]);

  return (
    <FormGroup>
      {label ? (
        <Label htmlFor={name} className="w-100">
          {label}
          <Input
            id={name}
            type="select"
            {...fieldProps}
            invalid={!!error}
            placeholder={placeholder}
            disabled={disabled}
          >
            {optionsList}
          </Input>
          {error && <FormFeedback>{error.message}</FormFeedback>}
        </Label>
      ) : (
        <Input
          id={name}
          type="select"
          {...fieldProps}
          invalid={!!error}
          placeholder={placeholder}
          disabled={disabled}
        >
          {optionsList}
        </Input>
      )}
    </FormGroup>
  );
};

export default SelectField;
