import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useNavigate, useParams } from 'react-router-dom';
import TemplateNews from 'enum/TemplateNews';
import useFieldSetErrors from 'hooks/useFieldSetErrors';
import { useToast } from 'hooks/useToast';
import TypeResource from 'enum/TypeResource';
import TypeAction from 'enum/TypeAction';
import useUser from 'hooks/useUser';
import {
  useApiDelete,
  useApiGet,
  useApiPost,
  useApiPostFile,
  useApiPut
} from 'hooks/useApi';
import InternalsNews from 'models/InternalsNews';
import ScreenOrientation from 'enum/ScreenOrientation';
import moment from 'moment';
import ToastType from 'enum/ToastType';
import apiPaths from 'constants/apiPaths';

const schema = yup
  .object({
    title: yup.string().required('O título é obrigatório.'),
    description: yup.string().required('A descrição é obrigatória.'),
    photoCredit: yup.string().required('O crédito da foto é obrigatório.'),
    template: yup
      .mixed<TemplateNews>()
      .oneOf(Object.values(TemplateNews))
      .required(),
    qrcodeUrl: yup.string().url('Digite um URL válido.'),
    startDate: yup.string().required(),
    endDate: yup.string().required(),
    screenOrientation: yup
      .mixed<ScreenOrientation>()
      .oneOf(Object.values(ScreenOrientation))
      .default(ScreenOrientation.PORTRAIT),
    isActive: yup.boolean().default(true),
    bgImage: yup.string().required('A imagem é obrigatória.'),
    logo: yup.mixed(),
    color: yup.string()
  })
  .required();

const useControllerInternalsNewsFormPage = () => {
  const params = useParams();
  const user = useUser();
  const navigate = useNavigate();
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [qrcodeUrl, setQrcodeUrl] = useState('');
  const [mediaToPreview, setMediaToPreview] = useState<string>();

  const { data, isLoading: isFetching } = useApiGet<InternalsNews>(
    `/news/internals`,
    { id: params.id, enabled: !!params.id }
  );
  const { mutateAsync: mutateCreate, isPending: isCreating } = useApiPost<
    InternalsNews
  >('/news/internals');
  const { mutateAsync: mutateUpdate, isPending: isUpdating } = useApiPut<
    InternalsNews
  >('/news/internals', params.id);

  const { mutate: mutateDelete, isPending: isDeleting } = useApiDelete(
    '/news/internals',
    params.id
  );

  const { mutateAsync: uploadLogo } = useApiPostFile(`/news/internals`);

  const mutate = params.id ? mutateUpdate : mutateCreate;

  const isSaving = isCreating || isUpdating;

  const internalsNews = params.id ? data : undefined;

  const userCanPerformAction = useMemo(() => {
    if (params.id) {
      return user?.resources?.[TypeResource.NEWS]?.includes(TypeAction.UPDATE);
    } else {
      return user?.resources?.[TypeResource.NEWS]?.includes(TypeAction.CREATE);
    }
  }, [user, params.id]);

  const userCanDelete = useMemo(() => {
    return user?.resources?.[TypeResource.NEWS]?.includes(TypeAction.DELETE);
  }, [user]);
  const formMethods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      isActive: true,
      screenOrientation: ScreenOrientation.PORTRAIT
    }
  });
  const selectedScreenOrientation = useWatch({
    control: formMethods.control,
    name: 'screenOrientation',
    defaultValue: ScreenOrientation.PORTRAIT
  });
  const selectedLogoFile = useWatch({
    control: formMethods.control,
    name: 'logo',
    defaultValue: null
  });

  const [
    selectedTemplate,
    selectedTitle,
    selectedDescription,
    selectedPhotoCredit,
    selectedBgImage,
    selectedColor,
    selectedQrCodeUrl
  ] = useWatch({
    control: formMethods.control,
    name: [
      'template',
      'title',
      'description',
      'photoCredit',
      'bgImage',
      'color',
      'qrcodeUrl'
    ]
  });

  const canSubmit = useMemo(() => {
    return formMethods.formState.isValid;
  }, [formMethods.formState.isValid]);

  const onError = useFieldSetErrors(formMethods.setError);
  const { addToast } = useToast();

  const toISOString = (date: string): string => {
    return new Date(date).toISOString();
  };

  const onSubmitHandler = useCallback(
    async (data: InternalsNews) => {
      try {
        const { logo, ...dataWithoutLogo } = data;

        const updatedData = {
          ...dataWithoutLogo,
          startDate: toISOString(data.startDate!),
          endDate: toISOString(data.endDate!),
          mediaFile: data.mediaFile?.id
        };

        const result = await mutate(updatedData as any);

        if (selectedLogoFile) {
          const formData = new FormData();
          formData.append('logo', selectedLogoFile);
          formData.append('id', `${result.id}/logo`);

          await uploadLogo(formData as any);

          addToast(ToastType.SUCCESS, 'Logo salva com sucesso');
          navigate('/news/internals');
        }
        addToast(ToastType.SUCCESS, 'Notícia salva com sucesso');
        navigate('/news/internals');
      } catch (error) {
        console.error('Erro ao chamar mutate:', error);
        onError(error);
        addToast(ToastType.ERROR, 'Erro ao salvar notícia');
      }
    },
    [mutate, uploadLogo, selectedLogoFile, addToast, navigate, onError]
  );

  const handleOnDelete = useCallback(() => {
    if (!params.id || !userCanDelete) return;
    mutateDelete(null as any, {
      onError: (response: any) => {
        addToast(
          ToastType.ERROR,
          response.errors?.detail?.toString() || 'Erro ao excluir notícia'
        );
      },
      onSuccess: () => {
        addToast(ToastType.SUCCESS, 'Notícia excluída com sucesso');
        navigate('/news/internals');
      }
    });
  }, [addToast, mutateDelete, navigate, params.id, userCanDelete]);

  const typeOptionsOrientation = [
    {
      value: ScreenOrientation.PORTRAIT,
      label: 'Retrato'
    },
    {
      value: ScreenOrientation.LANDSCAPE,
      label: 'Paisagem'
    }
  ];

  const handleUrlChange = (event: ChangeEvent<HTMLInputElement>) => {
    setQrcodeUrl(event.target.value);
  };

  const handleLogoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0] || null;
    formMethods.setValue('logo', file as Blob);
  };

  const toDateInputValue = (date: string): string => {
    return moment(date).format('YYYY-MM-DD');
  };

  const logoSrc = useMemo(() => {
    return selectedLogoFile
      ? URL.createObjectURL(new Blob([selectedLogoFile]))
      : internalsNews?.logo;
  }, [selectedLogoFile, internalsNews?.logo]);
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);

  const openPreview = () => {
    setIsPreviewOpen(true);
  };

  const closePreview = () => {
    setIsPreviewOpen(false);
  };

  const aspectRatioClass =
    selectedScreenOrientation === ScreenOrientation.PORTRAIT
      ? 'aspect-ratio-box-9-16-news'
      : 'aspect-ratio-box-16-9-news';

  const goOut = () => {
    navigate(apiPaths.internalNews);
  };

  const typeOptionsActive = [
    { value: true, label: 'Ativo' },
    {
      value: false,
      label: 'Inativo'
    }
  ];

  useEffect(() => {
    if (internalsNews) {
      formMethods.reset({
        title: internalsNews.title,
        description: internalsNews.description,
        photoCredit: internalsNews.photoCredit,
        template: internalsNews.template,
        qrcodeUrl: internalsNews.qrcodeUrl,
        color: internalsNews.color,
        startDate: toDateInputValue(internalsNews.startDate!),
        endDate: toDateInputValue(internalsNews.endDate!),
        screenOrientation: internalsNews.screenOrientation,
        isActive: internalsNews.isActive,
        bgImage: internalsNews.bgImage,
        mediaFile: internalsNews.mediaFile
      } as any);
    }
  }, [internalsNews, formMethods]);

  return {
    formMethods,
    selectedScreenOrientation,
    selectedLogoFile,
    selectedTemplate,
    selectedTitle,
    selectedDescription,
    selectedPhotoCredit,
    selectedBgImage,
    selectedColor,
    selectedQrCodeUrl,
    onError,
    addToast,
    toISOString,
    onSubmitHandler,
    handleOnDelete,
    typeOptionsOrientation,
    handleUrlChange,
    handleLogoChange,
    logoSrc,
    openPreview,
    closePreview,
    aspectRatioClass,
    isPreviewOpen,
    isSaving,
    userCanPerformAction,
    userCanDelete,
    qrcodeUrl,
    mediaToPreview,
    showDeleteConfirmation,
    setShowDeleteConfirmation,
    isFetching,
    internalsNews,
    params,
    isDeleting,
    setMediaToPreview,
    canSubmit,
    goOut,
    typeOptionsActive
  };
};

export default useControllerInternalsNewsFormPage;
