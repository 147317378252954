import React, { useCallback, useMemo } from 'react';
import { useApiGet } from 'hooks/useApi';
import Pagination from 'models/Pagination';
import InternalsNews from 'models/InternalsNews';

type InternalsNewsListDragPageProps = {
  screenOrientation: string;
};

const useControllerInternalsNewsListDragPage = ({
  screenOrientation
}: InternalsNewsListDragPageProps) => {
  const [filteredData, setFilteredData] = React.useState({
    pageSize: 10,
    page: 1,
    search: ''
  });

  const { data: internalsNews, isLoading } = useApiGet<
    Pagination<InternalsNews>
  >('/news/internals', {
    params: filteredData
  });

  const handleSearch = useCallback(
    (search: string) => {
      setFilteredData({ ...filteredData, search });
    },
    [filteredData]
  );
  const handlePageChange = useCallback(
    (page: number) => {
      setFilteredData({ ...filteredData, page });
    },
    [filteredData]
  );

  const filteredCampaigns = useMemo(() => {
    return internalsNews?.items.filter(
      (campaign) => campaign.screenOrientation === screenOrientation
    );
  }, [internalsNews, screenOrientation]);
  return {
    filteredData,
    setFilteredData,
    internalsNews,
    isLoading,
    handleSearch,
    handlePageChange,
    filteredCampaigns
  };
};

export default useControllerInternalsNewsListDragPage;
