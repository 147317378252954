import React, { useState, useEffect } from 'react';
import { TabContent, TabPane, Breadcrumb, BreadcrumbItem } from 'reactstrap';
import classNames from 'classnames';
import NavCorporateTv from './_presenters/enum/navCorporateTv';
import MediaManagerListPage from 'pages/MediaManager/MediaManagerListPage';
import CampaignListPage from 'pages/Campaign/CampaignListPage';
import { useSearchParams } from 'react-router-dom';
import UrlExternalListPage from 'pages/Url/UrlExternalListPage/index';
import PlaylistListPage from 'pages/Playlist/PlaylistListPage';

const CorporateTv = () => {
  const [activeTab, setActiveTab] = useState(NavCorporateTv.CAMPAIGNS);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const tabParam = searchParams.get('tab');
    switch (tabParam) {
      case 'campaigns':
        setActiveTab(NavCorporateTv.CAMPAIGNS);
        break;
      case 'playlists':
        setActiveTab(NavCorporateTv.PLAYLISTS);
        break;
      case 'medias':
        setActiveTab(NavCorporateTv.MEDIAS);
        break;
      case 'external-url':
        setActiveTab(NavCorporateTv.EXTERNAL_URL);
        break;
      default:
        setActiveTab(NavCorporateTv.CAMPAIGNS);
    }
  }, [searchParams]);

  return (
    <div className="mx-3">
      <div>
        <Breadcrumb className="text-gray-600 fw-medium mt-4">
          <BreadcrumbItem>Tv Corporativa</BreadcrumbItem>
        </Breadcrumb>
        <h4 className="fw-bold mt-3">Tv Corporativa</h4>
        <span className="small-typography text-gray-600">
          Realize a gestão do conteúdo da sua TV Corporativa.
        </span>
        <div className="d-flex align-items-center mt-4">
          <div className="navlink">
            <div
              className={classNames('me-5 navlink', {
                active: activeTab === NavCorporateTv.CAMPAIGNS
              })}
              onClick={() => setActiveTab(NavCorporateTv.CAMPAIGNS)}
            >
              Campanhas
            </div>
          </div>
          <div className="navlink">
            <div
              className={classNames('me-5 navlink', {
                active: activeTab === NavCorporateTv.PLAYLISTS
              })}
              onClick={() => setActiveTab(NavCorporateTv.PLAYLISTS)}
            >
              Playlists
            </div>
          </div>
          <div className="navlink">
            <div
              className={classNames('me-5 navlink', {
                active: activeTab === NavCorporateTv.MEDIAS
              })}
              onClick={() => setActiveTab(NavCorporateTv.MEDIAS)}
            >
              Mídias
            </div>
          </div>
          <div className="navlink">
            <div
              className={classNames('navlink', {
                active: activeTab === NavCorporateTv.EXTERNAL_URL
              })}
              onClick={() => setActiveTab(NavCorporateTv.EXTERNAL_URL)}
            >
              URL Externa
            </div>
          </div>
        </div>
        <hr className="color-line" />
        <TabContent activeTab={activeTab}>
          <TabPane tabId={NavCorporateTv.CAMPAIGNS}>
            <div className="mt-3">
              <CampaignListPage />
            </div>
          </TabPane>
          <TabPane tabId={NavCorporateTv.PLAYLISTS}>
            <div className="mt-2">
              <PlaylistListPage />
            </div>
          </TabPane>
          <TabPane tabId={NavCorporateTv.MEDIAS}>
            <div>
              <MediaManagerListPage />
            </div>
          </TabPane>
          <TabPane tabId={NavCorporateTv.EXTERNAL_URL}>
            <div className="mt-2">
              <UrlExternalListPage />
            </div>
          </TabPane>
        </TabContent>
      </div>
    </div>
  );
};

export default CorporateTv;
