import TextField from 'components/TextField';
import Form from 'components/Form';
import { Col, Row, BreadcrumbItem, Breadcrumb } from 'reactstrap';
import { Link } from 'react-router-dom';
import Icon from 'components/Icon';
import Button from 'components/Button';
import RadioChoicesFiled from 'components/RadioChoicesField';
import { Label1 } from '../InternalsNewsLabels';
import TemplateNews from 'enum/TemplateNews';
import QRCode from 'qrcode.react';
import DateField from 'components/DateField';
import MediaGrid from 'components/MediaGrid';
import { withDragAndDropProvider } from 'contexts/dragAndDropProvider';
import NewsImageDrop from 'components/News/InternalsNewsImageDrop';
import Modal from 'components/Modal';
import { Loader } from 'react-bootstrap-typeahead';
import SelectField from 'components/SelectField';
import ModalInternalsNewsPreview from 'components/ModalInternalsNewsPreview';
import FileField from 'components/FileField';
import InternalsNewsPreview from 'components/News/InternalsNewsPreview';
import useControllerInternalsNewsFormPage from './_presenters/useControllerInternalsNewsFormPage/useControllerInternalsNewsFormPage';
import apiPaths from 'constants/apiPaths';

const NewsFormPage = () => {
  const {
    formMethods,
    selectedScreenOrientation,
    selectedLogoFile,
    selectedTemplate,
    selectedTitle,
    selectedDescription,
    selectedPhotoCredit,
    selectedBgImage,
    selectedColor,
    selectedQrCodeUrl,
    onSubmitHandler,
    handleOnDelete,
    typeOptionsOrientation,
    handleUrlChange,
    handleLogoChange,
    logoSrc,
    openPreview,
    closePreview,
    aspectRatioClass,
    isPreviewOpen,
    isSaving,
    userCanPerformAction,
    userCanDelete,
    qrcodeUrl,
    mediaToPreview,
    showDeleteConfirmation,
    setShowDeleteConfirmation,
    isFetching,
    internalsNews,
    params,
    isDeleting,
    setMediaToPreview,
    goOut,
    canSubmit,
    typeOptionsActive
  } = useControllerInternalsNewsFormPage();

  const typeOptionsTemplate = [
    { value: TemplateNews.TEMPLATE_UM, label: <Label1 /> }
  ];

  return (
    <div className="mx-3">
      <Form onSubmit={onSubmitHandler} {...formMethods}>
        <Row className="d-flex align-items-center">
          <Col className="mb-3">
            <Breadcrumb className="mt-4">
              <BreadcrumbItem active>
                <Link
                  to={apiPaths.internalNews}
                  className="text-decoration-none"
                >
                  Notícias
                </Link>
              </BreadcrumbItem>
              <BreadcrumbItem className="text-gray-600 fw-medium">
                {params.id ? internalsNews?.title : 'Cadastrar conteúdo'}
              </BreadcrumbItem>
            </Breadcrumb>
            <h4 className="fw-bolder">
              {params.id ? internalsNews?.title : 'Conteúdo'}
            </h4>
            <span className="small-typography text-gray-600">
              {params.id
                ? 'Realize a gestão das informações sobre a notícia cadastrada.'
                : 'Cadastre o conteúdo da sua notícia'}
            </span>
          </Col>
          <Col className="d-flex align-items-center justify-content-end">
            {userCanDelete && params.id && (
              <Button
                type="button"
                loading={isDeleting}
                className="button-outline delete me-2"
                onClick={() => setShowDeleteConfirmation(true)}
              >
                Excluir
              </Button>
            )}
            {!params.id && (
              <Button className="button-outline me-2" onClick={goOut}>
                Sair
              </Button>
            )}
            {userCanPerformAction && (
              <Button
                type="submit"
                className="btn btn-primary text-white button-register"
                loading={isSaving}
                disabled={!canSubmit}
              >
                Salvar
              </Button>
            )}
          </Col>
        </Row>
        {isFetching ? (
          <Loader />
        ) : (
          <div>
            <div className="shadow bg-white form p-3">
              <h5 className="text-gray-600 fw-bold">Selecione o template</h5>
              <RadioChoicesFiled
                name="template"
                options={typeOptionsTemplate}
                classNameRadio="d-flex mt-2"
              />
            </div>
            <div className="shadow bg-white form p-3 mt-3">
              <h5 className="text-gray-600 fw-bold">Informações Gerais</h5>
              <SelectField
                name="isActive"
                label="Status"
                options={typeOptionsActive}
                defaultValue={String(internalsNews?.isActive) || String(true)}
              />

              <SelectField
                label="Orientação visual"
                name="screenOrientation"
                options={typeOptionsOrientation}
                defaultValue={
                  internalsNews?.screenOrientation || selectedScreenOrientation
                }
              />

              <TextField
                label="Título"
                name="title"
                placeholder="Digite um título"
              />

              <TextField
                label="Descrição"
                name="description"
                placeholder="Descreva o conteúdo"
                type="textarea"
              />
              <TextField
                label="Créditos da imagem"
                name="photoCredit"
                placeholder="Digite os créditos da imagem"
              />
              <div className="d-flex">
                <div className="w-100">
                  <DateField label="Data de início" name="startDate" />
                </div>
                <div className="ms-4 w-100">
                  <DateField label="Data de término" name="endDate" />
                </div>
              </div>
            </div>
            <div className="shadow bg-white form p-3 mt-3">
              <h5 className="text-gray-600 fw-bold">Configurações</h5>
              <TextField
                label="URL para gerar QR Code"
                name="qrcodeUrl"
                onChange={handleUrlChange}
              />
              {params.id && qrcodeUrl && (
                <Row className="my-2">
                  <Col>
                    <p className="h6">QR Code para o URL:</p>
                    <QRCode value={internalsNews?.qrcodeUrl!} />
                  </Col>
                </Row>
              )}
              <div className="d-flex align-items-end w-100 mt-4">
                <div className="w-50">
                  <TextField
                    label="Selecione a cor"
                    name="color"
                    type="color"
                    className="custom-file-upload"
                  />
                </div>
                <div className="w-50 d-flex align-items-center ms-4 gap-2">
                  <div className="flex-grow-1">
                    <span>Imagem da logo</span>
                    <FileField
                      name="logo"
                      accept="image/*"
                      type="file"
                      onChange={handleLogoChange}
                    />
                  </div>
                  {selectedLogoFile || internalsNews?.logo ? (
                    <img
                      className="ms-2 object-fit-contain"
                      height={100}
                      width={100}
                      src={logoSrc}
                      alt="Preview"
                    />
                  ) : null}
                </div>
              </div>
            </div>
            <Row>
              <Col md={6} className="shadow bg-white form p-3 mt-3">
                <MediaGrid title="Gerenciamento de Mídias" />
              </Col>
              <Col className="shadow bg-white form p-3 mt-3 ms-3">
                <div className="mt-4 ms-2">
                  <div className="mb-2 d-flex align-items-center justify-content-between">
                    <h5 className="text-gray-600 fw-bold">Preview da Imagem</h5>
                    <Button
                      className="white ms-2 border-0 text-white"
                      color="primary"
                      type="button"
                      onClick={() => openPreview()}
                    >
                      <Icon
                        icon="collection-play"
                        className="me-3 text-white"
                      />
                      Reproduzir
                    </Button>
                  </div>
                  <NewsImageDrop
                    nameEditedFile="bgImage"
                    nameMediaFile="mediaFile"
                    onClick={(media: string) => setMediaToPreview(media)}
                    orientation={selectedScreenOrientation}
                  />
                </div>
              </Col>
            </Row>
          </div>
        )}
      </Form>
      <ModalInternalsNewsPreview
        editedMediaPreview={mediaToPreview!}
        isOpen={!!mediaToPreview}
        onClose={() => setMediaToPreview(undefined)}
      />
      <Modal
        primaryButtonAction={handleOnDelete}
        primaryButtonLabel="Excluir"
        title="Atenção!"
        isOpen={showDeleteConfirmation}
        showCloseButton={false}
        toggle={() => setShowDeleteConfirmation((x) => !x)}
        secondaryButtonAction={() => setShowDeleteConfirmation(false)}
        secondaryButtonLabel="Cancelar"
      >
        <p>
          Deseja realmente excluir a notícia? <br />
          <small>Essa alteração não poderá ser revertida.</small>
        </p>
      </Modal>
      <InternalsNewsPreview
        isOpen={isPreviewOpen}
        onClose={closePreview}
        news={{
          ...internalsNews,
          template: selectedTemplate || internalsNews?.template,
          title: selectedTitle || internalsNews?.title!,
          description: selectedDescription || internalsNews?.description!,
          photoCredit: selectedPhotoCredit || internalsNews?.photoCredit!,
          bgImage: selectedBgImage || internalsNews?.bgImage!,
          color: selectedColor || internalsNews?.color,
          qrcodeUrl: selectedQrCodeUrl || internalsNews?.qrcodeUrl,
          logo: logoSrc
        }}
        className={aspectRatioClass}
      />
    </div>
  );
};

export default withDragAndDropProvider(NewsFormPage);
