import React, { useCallback, useEffect, useState } from 'react';
import SearchInputProps from '../props/searchInputProps';

const MS_TO_WAIT_TYPING = 1000;

const useControllerSearchInput = ({
  onSearchTextChange,
  onClearFilter,
  onSortChange
}: SearchInputProps) => {
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');
  const ref = React.useRef<HTMLInputElement>(null);
  const timeoutRef = React.useRef<any>(null);

  const handleSearch = useCallback(
    (e: any) => {
      timeoutRef.current && clearTimeout(timeoutRef.current);
      timeoutRef.current = setTimeout(() => {
        onSearchTextChange(e.target?.value ?? '');
      }, MS_TO_WAIT_TYPING);
    },
    [onSearchTextChange]
  );

  useEffect(() => {
    const input = ref.current;
    const timeout = timeoutRef.current;
    ref.current?.addEventListener('input', handleSearch);
    return () => {
      input?.removeEventListener('input', handleSearch);
      timeout && clearTimeout(timeout);
    };
  }, [handleSearch]);

  const handleClearInput = useCallback(() => {
    if (ref.current) {
      ref.current.value = '';
      onSearchTextChange('');
      onClearFilter?.();
    }
  }, [onSearchTextChange, onClearFilter]);

  const toggleSortOrder = useCallback(() => {
    const newOrder = sortOrder === 'asc' ? 'desc' : 'asc';
    setSortOrder(newOrder);
    onSortChange?.(newOrder);
  }, [sortOrder, onSortChange]);

  return {
    ref,
    sortOrder,
    handleSearch,
    handleClearInput,
    toggleSortOrder
  };
};

export default useControllerSearchInput;
