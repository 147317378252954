import React, { useCallback, useMemo } from 'react';
import { useApiGet } from 'hooks/useApi';
import { useNavigate } from 'react-router-dom';
import useUser from 'hooks/useUser';
import TypeResource from 'enum/TypeResource';
import TypeAction from 'enum/TypeAction';
import Pagination from 'models/Pagination';
import apiPaths from 'constants/apiPaths';

const useControllerCustomerUsersListPage = () => {
  const navigate = useNavigate();
  const [filteredData, setFilteredData] = React.useState({
    pageSize: 10,
    page: 1,
    search: ''
  });
  const { data: customerUsersPage, isLoading } = useApiGet<
    Pagination<{
      id: string | number;
      firstName: string;
      email: string;
      policies: any[];
    }>
  >(apiPaths.customerUsers, {
    params: filteredData
  });

  const handleSearch = useCallback(
    (search: string) => {
      setFilteredData({ ...filteredData, search, page: 1 });
    },
    [filteredData]
  );
  const handlePageChange = useCallback(
    (page: number) => {
      setFilteredData({ ...filteredData, page });
    },
    [filteredData]
  );

  const handleClearFilter = useCallback(() => {
    setFilteredData({ pageSize: 10, page: 1, search: '' });
  }, []);

  const goToEdit = useCallback(
    (row: { id: string | number }) => {
      navigate(`/customer/users/${row.id}`);
    },
    [navigate]
  );

  const user = useUser();
  const userCanAdd = useMemo(() => {
    return user?.resources?.[TypeResource.USERS]?.includes(TypeAction.CREATE);
  }, [user]);

  return {
    customerUsersPage,
    isLoading,
    handleSearch,
    handlePageChange,
    handleClearFilter,
    goToEdit,
    userCanAdd
  };
};

export default useControllerCustomerUsersListPage;
