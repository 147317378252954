import React, { useState } from 'react';
import {
  TabContent,
  TabPane,
  Row,
  Col,
  Breadcrumb,
  BreadcrumbItem
} from 'reactstrap';
import DeviceFormPage from '../DeviceFormPage';
import ResourcesPage from '../ResourcesPage';
import PlayerSettingsPage from '../PlayerSettingsPage';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import apiPaths from 'constants/apiPaths';
import Pages from './_presenters/enum/Pages';
import useControllerDevicePage from './_presenters/useControllerDevicePage/useControllerDevicePage';

const DevicePage = () => {
  const [activeTab, setActiveTab] = useState<string>(Pages.DEVICES);
  const { device } = useControllerDevicePage();
  return (
    <div className="mx-3">
      <div>
        <Breadcrumb className="mt-4">
          <BreadcrumbItem active>
            <Link to={apiPaths.devices} className="text-decoration-none">
              Dispositivos
            </Link>
          </BreadcrumbItem>
          <BreadcrumbItem className="text-gray-600 fw-medium">
            {device?.name}
          </BreadcrumbItem>
        </Breadcrumb>
        <h4 className="fw-bolder">{device?.name}</h4>
        <span className="small-typography text-gray-600">
          Informações sobre o seu dispositivo
        </span>
        <div className="d-flex align-items-center mt-4">
          <div className="navlink">
            <div
              className={classNames('me-5 navlink', {
                active: activeTab === Pages.DEVICES
              })}
              onClick={() => setActiveTab(Pages.DEVICES)}
            >
              Informações Gerais
            </div>
          </div>
          <div className="navlink">
            <div
              className={classNames('me-5 navlink', {
                active: activeTab === Pages.RESOURCES
              })}
              onClick={() => setActiveTab(Pages.RESOURCES)}
            >
              Recursos
            </div>
          </div>
          <div className="navlink">
            <div
              className={classNames('me-5 navlink', {
                active: activeTab === Pages.PLAYER_SETTINGS
              })}
              onClick={() => setActiveTab(Pages.PLAYER_SETTINGS)}
            >
              Configurações
            </div>
          </div>
        </div>
        <hr className="color-line" />
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <div className="mt-2">
              <DeviceFormPage />
            </div>
          </TabPane>
          <TabPane tabId="2">
            <div className="mt-2">
              <ResourcesPage />
            </div>
          </TabPane>
          <TabPane tabId="3">
            <Row className="mt-3">
              <Col>
                <PlayerSettingsPage />
              </Col>
            </Row>
          </TabPane>
        </TabContent>
      </div>
    </div>
  );
};

export default DevicePage;
