import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useApiGet, useApiPost, useApiPut, useApiDelete } from 'hooks/useApi';
import TypeResource from 'enum/TypeResource';
import TypeAction from 'enum/TypeAction';
import useFieldSetErrors from 'hooks/useFieldSetErrors';
import { useToast } from 'hooks/useToast';
import Device from 'models/Device';
import useUser from 'hooks/useUser';
import { Option } from 'components/AsyncTypeaheadField';
import ScreenOrientation from 'enum/ScreenOrientation';
import DeviceGroup from 'models/DeviceGroup';
import Pagination from 'models/Pagination';
import ToastType from 'enum/ToastType';
import apiPaths from 'constants/apiPaths';

type FormValue = {
  name: string;
  active: boolean;
  group: Option | null;
  screenOrientation: ScreenOrientation;
  local: string;
};

const yupSchema = yup.object({
  name: yup.string().required('O nome é obrigatório.'),
  group: yup.object().required('O grupo é obrigatório.'),
  screenOrientation: yup
    .mixed<ScreenOrientation>()
    .oneOf(Object.values(ScreenOrientation))
    .required(),
  local: yup.string().required('O local é obrigatório.'),
  active: yup.boolean()
});

const useControllerDeviceFormPage = () => {
  const params = useParams();
  const user = useUser();
  const navigate = useNavigate();
  const { addToast } = useToast();
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const { data, isLoading: isFetching } = useApiGet<Device>(`/device`, {
    id: params.id,
    enabled: !!params.id
  });
  const { mutate: mutateCreate, isPending: isCreating } = useApiPost<Device>(
    apiPaths.device
  );
  const { mutate: mutateUpdate, isPending: isUpdating } = useApiPut<Device>(
    apiPaths.device,
    params.id
  );

  const { mutate: mutateDelete, isPending: isDeleting } = useApiDelete(
    apiPaths.device,
    params.id
  );

  const {
    data: deviceGroupData,
    refetch: deviceGroupRefetch,
    isLoading: isLoadingDeviceGroup
  } = useApiGet<Pagination<DeviceGroup>>(apiPaths.deviceGroup);

  const mutate = params.id ? mutateUpdate : mutateCreate;

  const userCanPerformAction = useMemo(() => {
    if (params.id) {
      return user?.resources?.[TypeResource.USERS]?.includes(TypeAction.UPDATE);
    } else {
      return user?.resources?.[TypeResource.USERS]?.includes(TypeAction.CREATE);
    }
  }, [user, params.id]);

  const userCanDelete = useMemo(() => {
    return user?.resources?.[TypeResource.USERS]?.includes(TypeAction.DELETE);
  }, [user]);

  const isSaving = isCreating || isUpdating;

  const device = params.id ? data : undefined;

  const formMethods = useForm<FormValue>({
    resolver: yupResolver(yupSchema as yup.ObjectSchema<FormValue>),
    defaultValues: {
      name: '',
      active: true,
      group: null,
      local: '',
      screenOrientation: ScreenOrientation.PORTRAIT
    }
  });

  const watchedFields = useWatch({
    control: formMethods.control
  });

  const canSubmit = useMemo(() => {
    const { name, local } = watchedFields;
    return name && local !== undefined;
  }, [watchedFields]);

  const onError = useFieldSetErrors(formMethods.setError);

  const handleOnSubmit = useCallback(
    (data: FormValue) => {
      if (!userCanPerformAction) return;
      const payload = {
        group: data.group?.value!,
        name: data.name,
        active: data.active,
        screenOrientation: data.screenOrientation,
        local: data.local
      } as any;
      mutate(payload, {
        onError,
        onSuccess: () => {
          addToast(ToastType.SUCCESS, 'Dispositivo salvo com sucesso');
          navigate('/devices?tab=device-list');
        }
      });
    },
    [userCanPerformAction, mutate, onError, addToast, navigate]
  );

  const handleOnDelete = useCallback(() => {
    if (!params.id || !userCanDelete) return;
    mutateDelete(null as any, {
      onError: (response: any) => {
        addToast(
          ToastType.ERROR,
          response.errors?.detail?.toString() || 'Erro ao excluir dispositivo'
        );
      },
      onSuccess: () => {
        addToast(ToastType.SUCCESS, 'Dispositivo excluído com sucesso');
        navigate(apiPaths.devices);
      }
    });
  }, [addToast, mutateDelete, navigate, params.id, userCanDelete]);

  const deviceGroupOptions = useMemo(() => {
    const data = deviceGroupData?.items || [];
    return data.map(
      (deviceGroup) =>
        ({
          label: deviceGroup.name,
          value: deviceGroup.id
        } as any)
    );
  }, [deviceGroupData]);

  const typeOptionsOrientation = [
    {
      value: ScreenOrientation.PORTRAIT,
      label: 'Retrato'
    },
    {
      value: ScreenOrientation.LANDSCAPE,
      label: 'Paisagem'
    }
  ];

  const typeOptionsActive = [
    { value: true, label: 'Ativo' },
    {
      value: false,
      label: 'Inativo'
    }
  ];

  const goOut = () => {
    navigate(apiPaths.devices);
  };

  useEffect(() => {
    if (device) {
      formMethods.reset({
        name: device.name,
        local: device.local,
        active: device.active,
        screenOrientation: device.screenOrientation,
        group: device.group
          ? {
              label: device.group?.name,
              value: device.group?.id
            }
          : null
      } as any);
    }
  }, [device, formMethods]);

  return {
    params,
    user,
    navigate,
    addToast,
    device,
    isFetching,
    mutateCreate,
    isCreating,
    mutateUpdate,
    isUpdating,
    deviceGroupData,
    deviceGroupRefetch,
    isLoadingDeviceGroup,
    formMethods,
    isSaving,
    handleOnSubmit,
    deviceGroupOptions,
    typeOptionsOrientation,
    userCanPerformAction,
    canSubmit,
    typeOptionsActive,
    showDeleteConfirmation,
    setShowDeleteConfirmation,
    handleOnDelete,
    userCanDelete,
    isDeleting,
    goOut
  };
};

export default useControllerDeviceFormPage;
