import { useCallback, useMemo } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { useApiPost } from 'hooks/useApi';
import ScreenOrientation, {
  screenOrientationDisplay
} from 'enum/ScreenOrientation';
import CampaignLayout from 'enum/CampaignLayout';
import { MediaFileCampaign } from 'models/MediaFileCampaign';
import useFieldSetErrors from 'hooks/useFieldSetErrors';
import { useToast } from 'hooks/useToast';
import apiPaths from 'constants/apiPaths';

const schema = yup
  .object({
    name: yup.string().required('O nome é obrigatório.'),
    screenOrientation: yup
      .mixed<ScreenOrientation>()
      .oneOf(Object.values(ScreenOrientation))
      .required(),
    layoutType: yup
      .mixed<CampaignLayout>()
      .oneOf(Object.values(CampaignLayout))
      .required(),
    active: yup.boolean().required()
  })
  .required();

const useControllerCampaignCreatePage = () => {
  const navigate = useNavigate();
  const { mutate: mutateCreate } = useApiPost<MediaFileCampaign>(
    apiPaths.campaigns
  );
  const formMethods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: '',
      screenOrientation: ScreenOrientation.PORTRAIT,
      layoutType: CampaignLayout.FULL_SCREEN,
      active: true
    }
  });

  const watchedFields = useWatch({
    control: formMethods.control
  });

  const canSubmit = useMemo(() => {
    const { name } = watchedFields;
    return !!name;
  }, [watchedFields]);

  const onError = useFieldSetErrors(formMethods.setError);
  const { addToast } = useToast();

  const onSubmitHandler = useCallback(
    (data: MediaFileCampaign) => {
      mutateCreate(data, {
        onError,
        onSuccess: (data) => {
          addToast('success', 'Campanha salva com sucesso');
          navigate(`/media-manager/campaigns/${data.id}`);
        }
      });
    },
    [addToast, mutateCreate, navigate, onError]
  );

  const typeOptionsOrientation = [
    {
      value: ScreenOrientation.PORTRAIT,
      label: screenOrientationDisplay(ScreenOrientation.PORTRAIT)
    },
    {
      value: ScreenOrientation.LANDSCAPE,
      label: screenOrientationDisplay(ScreenOrientation.LANDSCAPE)
    }
  ];

  const goOut = () => {
    navigate(apiPaths.tvCorporate);
  };

  return {
    navigate,
    formMethods,
    onError,
    addToast,
    onSubmitHandler,
    typeOptionsOrientation,
    goOut,
    canSubmit
  };
};

export default useControllerCampaignCreatePage;
