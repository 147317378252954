import React, { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import PlayerViewMode from 'enum/PlayerViewMode';
import TypeResource from 'enum/TypeResource';
import TypeAction from 'enum/TypeAction';
import useUser from 'hooks/useUser';
import { useApiPost } from 'hooks/useApi';
import PlayerSettings from 'models/PlayerSettings';
import PlayerRemoteAction from 'models/PlayerRemoteAction';
import PlayerRemoteActionsTypes from 'enum/PlayerRemoteActionsTypes';
import useFieldSetErrors from 'hooks/useFieldSetErrors';
import { useToast } from 'hooks/useToast';

const yupSchemaPlayer = yup.object().shape({
  viewMode: yup
    .mixed<PlayerViewMode>()
    .oneOf(Object.values(PlayerViewMode))
    .required(),
  allowMove: yup.boolean().notRequired(),
  allowResize: yup.boolean().notRequired(),
  height: yup.string().notRequired(),
  width: yup.string().notRequired(),
  xPosition: yup.string().notRequired(),
  yPosition: yup.string().notRequired()
});

const useControllerPlayerSettingsPage = () => {
  const params = useParams();
  const user = useUser();
  const { addToast } = useToast();
  const [isLoadingFilesCache, setIsLoadingFilesCache] = useState(false);
  const [
    isLoadingFilesAndPlayerCache,
    setIsLoadingFilesAndPlayerCache
  ] = useState(false);
  const [isLoadingPlayerCache, setIsLoadingPlayerCache] = useState(false);

  const {
    mutate: mutatePlayerSettings,
    isPending: isPlayerSettings
  } = useApiPost<PlayerSettings>(`/device/${params.id}/settings`);

  const { mutate: mutatePlayerRemoteAction } = useApiPost<PlayerRemoteAction>(
    `/device/${params.id}/action`
  );

  const playerViewModeOptions = [
    { value: PlayerViewMode.FULL_SCREEN, label: 'Tela Cheia' },
    { value: PlayerViewMode.WINDOW, label: 'Janela' }
  ];

  const options = [
    { value: true, label: 'Sim' },
    { value: false, label: 'Não' }
  ];

  const userCanPerformAction = useMemo(() => {
    if (params.id) {
      return user?.resources?.[TypeResource.USERS]?.includes(TypeAction.UPDATE);
    } else {
      return user?.resources?.[TypeResource.USERS]?.includes(TypeAction.CREATE);
    }
  }, [user, params.id]);

  const formMethodsPlayer = useForm({
    resolver: yupResolver(yupSchemaPlayer)
  });

  const playerViewModeValue = useWatch({
    control: formMethodsPlayer.control,
    name: 'viewMode'
  });

  const onError = useFieldSetErrors(formMethodsPlayer.setError);

  const handleOnSubmitPlayer = useCallback(
    (data: PlayerSettings) => {
      if (!userCanPerformAction) return;
      let payload;
      if (playerViewModeValue !== PlayerViewMode.FULL_SCREEN) {
        payload = {
          viewMode: data.viewMode,
          allowMove: data.allowMove,
          allowResize: data.allowResize,
          height: data.height,
          width: data.width,
          xPosition: data.xPosition,
          yPosition: data.yPosition
        } as any;
      } else {
        payload = {
          viewMode: data.viewMode,
          allowMove: false,
          allowResize: false,
          height: '',
          width: '',
          xPosition: '',
          yPosition: ''
        } as any;
      }
      mutatePlayerSettings(payload, {
        onError,
        onSuccess: () => {
          addToast('success', 'Configurações do Player salvas com sucesso');
        }
      });
    },
    [
      userCanPerformAction,
      mutatePlayerSettings,
      onError,
      addToast,
      playerViewModeValue
    ]
  );

  const handleCacheAction = useCallback(
    (
      actionType: PlayerRemoteActionsTypes,
      successMessage: string,
      setLoading: React.Dispatch<React.SetStateAction<boolean>>
    ) => {
      if (!userCanPerformAction) return;
      const dataPayload = {
        action: actionType,
        payload: null
      } as any;
      setLoading(true);
      mutatePlayerRemoteAction(dataPayload, {
        onError: (error) => {
          onError(error);
          setLoading(false);
        },
        onSuccess: () => {
          addToast('success', successMessage);
          setLoading(false);
        }
      });
    },
    [userCanPerformAction, mutatePlayerRemoteAction, onError, addToast]
  );

  return {
    formMethodsPlayer,
    handleOnSubmitPlayer,
    playerViewModeOptions,
    options,
    userCanPerformAction,
    handleCacheAction,
    isLoadingFilesCache,
    setIsLoadingFilesCache,
    isLoadingFilesAndPlayerCache,
    setIsLoadingFilesAndPlayerCache,
    isLoadingPlayerCache,
    setIsLoadingPlayerCache,
    playerViewModeValue,
    isPlayerSettings
  };
};

export default useControllerPlayerSettingsPage;
