import { Col, Row, Spinner } from 'reactstrap';
import SearchInput from 'components/SearchInput';
import PaginationNav from 'components/Pagination';
import InternalsNewsItem from 'components/News/InternalsNewsItem';
import useControllerInternalsNewsListDragPage from './_presenters/useControllerInternalsNewsListDragPage/useControllerInternalsNewsListDragPage';

type InternalsNewsListDragPageProps = {
  screenOrientation: string;
};

const InternalsNewsListDragPage = ({
  screenOrientation
}: InternalsNewsListDragPageProps) => {
  const {
    internalsNews,
    isLoading,
    handleSearch,
    handlePageChange,
    filteredCampaigns
  } = useControllerInternalsNewsListDragPage({
    screenOrientation
  });

  return (
    <div>
      <Row className="d-flex flex-column flex-md-row">
        <Col className="col-12 col-md mb-3 mt-3">
          <SearchInput
            onSearchTextChange={handleSearch}
            placeholder="Pesquisar notícias"
            optionalsButtons
          />
        </Col>
      </Row>
      <div className="table-campaign-drag">
        {isLoading && (
          <tr>
            <td colSpan={2} className="text-center p-5">
              <Spinner />
            </td>
          </tr>
        )}
        {filteredCampaigns?.map((news) => (
          <InternalsNewsItem key={news.id} internalsNews={news} />
        ))}
      </div>
      <div className="d-flex justify-content-between align-items-center flex-column flex-md-row mt-3">
        {internalsNews && (
          <PaginationNav
            onChange={handlePageChange}
            totalPages={internalsNews.totalPages}
            currentPage={internalsNews.page}
          />
        )}
      </div>
    </div>
  );
};

export default InternalsNewsListDragPage;
