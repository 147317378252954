import React, { useCallback, useMemo, useState } from 'react';
import { useApiGet } from 'hooks/useApi';
import Pagination from 'models/Pagination';
import { useNavigate } from 'react-router-dom';
import { Playlist } from 'models/Playlist';
import useUser from 'hooks/useUser';
import TypeResource from 'enum/TypeResource';
import TypeAction from 'enum/TypeAction';
import ScreenOrientation from 'enum/ScreenOrientation';
import DeviceGroup from 'models/DeviceGroup';
import apiPaths from 'constants/apiPaths';

const useControllerPlaylistListPage = () => {
  const navigate = useNavigate();
  const [filteredData, setFilteredData] = React.useState({
    pageSize: 10,
    page: 1,
    search: ''
  });
  const { data: playlistsData, isLoading } = useApiGet<
    Pagination<{
      id: string | number;
      name: string;
      deviceGroup: DeviceGroup;
      screenOrientation: ScreenOrientation;
      playlist: Playlist;
      active: boolean;
    }>
  >(apiPaths.playlists, {
    params: filteredData
  });

  const [selectedPlaylist, setSelectedPlaylist] = useState<Playlist | null>(
    null
  );
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);

  const handleSearch = useCallback(
    (search: string) => {
      setFilteredData({ ...filteredData, search });
    },
    [filteredData]
  );
  const handleClearFilter = useCallback(() => {
    setFilteredData({ pageSize: 10, page: 1, search: '' });
  }, []);
  const handlePageChange = useCallback(
    (page: number) => {
      setFilteredData({ ...filteredData, page });
    },
    [filteredData]
  );
  const goToEdit = useCallback(
    (row: { id: string | number }) => {
      navigate(`/media-manager/playlists/${row.id}`);
    },
    [navigate]
  );
  const user = useUser();
  const userCanAdd = useMemo(() => {
    return user?.resources?.[TypeResource.PLAYLISTS]?.includes(
      TypeAction.CREATE
    );
  }, [user]);

  const openPreview = useCallback((playlist: Playlist) => {
    setSelectedPlaylist(playlist);
    setIsPreviewOpen(true);
  }, []);

  const closePreview = useCallback(() => {
    setIsPreviewOpen(false);
    setSelectedPlaylist(null);
  }, []);

  return {
    playlistsData,
    isLoading,
    filteredData,
    handleSearch,
    handlePageChange,
    goToEdit,
    userCanAdd,
    openPreview,
    closePreview,
    selectedPlaylist,
    isPreviewOpen,
    setFilteredData,
    handleClearFilter
  };
};

export default useControllerPlaylistListPage;
