import { Alert, Button, Col, Row } from 'reactstrap';
import logo from 'assets/images/logo 3.png';
import group from 'assets/images/Group 4.png';
import Form from 'components/Form';
import TextField from 'components/TextField';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import useControllerForgotPassword from './_presenters/useControllerForgotPassword/useControllerForgotPassword';
import { ROUTES_PATHS } from 'navigations/routes';
import Icon from 'components/Icon';

const ForgotPasswordPage = () => {
  const {
    showMessage,
    methods,
    isPending,
    onSubmitHandler,
    visible,
    onDismiss
  } = useControllerForgotPassword();

  return (
    <Row className={classNames('d-flex align-items-center page-direction')}>
      <Col className="image-col">
        <img src={group} alt="People talking" className="image-fill" />
      </Col>
      <Col className="d-flex flex-column align-items-center">
        <img src={logo} alt="logo" className="object-fit-none m-3" />
        <div
          className={classNames(
            'shadow-lg p-3 mb-5 bg-body-tertiary rounded box-size'
          )}
        >
          <h4 className="py-2 fw-bold">
            Digite seu endereço de e-mail para recuperar a sua senha
          </h4>
          <Form onSubmit={onSubmitHandler} {...methods}>
            <TextField
              type="text"
              name="email"
              placeholder="Digite seu email"
            />
            {showMessage && (
              <Alert
                className={classNames(
                  'd-flex align-items-center border-top-0 border-end-0 border-bottom-0 border-4 border-green-300 bg-green-100 text-green-800 small-typography'
                )}
                isOpen={visible}
                toggle={onDismiss}
              >
                <Icon
                  icon="exclamation-triangle-fill"
                  width={20}
                  height={20}
                  className="me-3"
                />
                Enviamos um e-mail com instruções para recuperar sua senha
              </Alert>
            )}
            <div className="py-1" />
            <Button
              color={methods.formState.isValid ? 'primary' : 'light-primary'}
              className="w-100 text-white mb-3"
              disabled={isPending}
            >
              Recuperar senha
            </Button>
            <Link
              to={ROUTES_PATHS.AUTH_PAGES.LOGIN}
              className="btn btn-outline-primary w-100 text-indigo-500"
            >
              Retornar
            </Link>
          </Form>
        </div>
      </Col>
    </Row>
  );
};

export default ForgotPasswordPage;
