// @ts-ignore
import { useDrag } from 'react-dnd';
import UrlExternal from 'models/UrlExternal';

type UrlExternalItemProps = {
  urlExternal: UrlExternal;
};

const UrlExternalItem = ({ urlExternal }: UrlExternalItemProps) => {
  const [{ isDragging }, drag] = useDrag({
    type: 'UrlExternal',
    item: { id: urlExternal.id },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  });

  return (
    <div ref={drag}>
      <div>
        {!isDragging ? (
          <div className="d-flex justify-content-between align-items-center border-top p-4">
            <div className="mt-2 w-100 me-4">
              <small>URL </small>
              <br />
              <b>{urlExternal.url}</b>
            </div>
            <div className="mt-2 w-100 me-4">
              <small>Duração </small>
              <br />
              <b>
                {urlExternal.isIndefinite
                  ? 'Tempo indeterminado'
                  : urlExternal.duration + ' segundos'}
              </b>
            </div>
          </div>
        ) : (
          <div className="campaign-medias-list-item">
            <div className="bg-dark-subtle"></div>
          </div>
        )}
      </div>
    </div>
  );
};

export default UrlExternalItem;
