const apiPaths = {
  login: '/auth/login',
  signUp: '/auth/signup',
  recoverPassword: '/auth/recover-password',
  customerUsers: '/customer/users',
  customerCompanies: '/customer/companies',
  userProfile: '/customer/profile',
  campaigns: '/media-manager/campaigns',
  tvCorporate: '/media-manager/corporate-tv',
  urlExternal: '/media-manager/url-external',
  playlists: '/media-manager/playlists',
  deviceGroup: '/device/group',
  device: '/device',
  devices: '/devices',
  internalNews: '/news/internals',
  accessPolicies: '/authorization/access-policies',
  accessPoliciesCreate: '/access-policies/create'
};

export default apiPaths;
