import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useApiDelete, useApiGet, useApiPost, useApiPut } from 'hooks/useApi';
import TypeResource from 'enum/TypeResource';
import TypeAction from 'enum/TypeAction';
import useFieldSetErrors from 'hooks/useFieldSetErrors';
import { useToast } from 'hooks/useToast';
import useUser from 'hooks/useUser';
import AccessPolicy from 'models/AccessPolicy';
import Pagination from 'models/Pagination';
import CustomerUser from 'models/CustomerUser';
import NavUsersFormPage from 'pages/CustomerUsers/CustomerUsersFormPage/_presenters/enum/navUsersFormPage';
import apiPaths from 'constants/apiPaths';
import ToastType from 'enum/ToastType';

const objetcSchema = {
  email: yup.string().email().required(),
  firstName: yup.string().required('O nome é obrigatório.'),
  lastName: yup.string().required('O sobrenome é obrigatório.'),
  principalUser: yup.mixed().oneOf(['false', 'true']),
  policies: yup.array(),
  password: yup.string().default(''),
  confirmPassword: yup.string().default(''),
  isActive: yup.mixed().oneOf(['false', 'true'])
};
const yupSchemaEdit = yup.object(objetcSchema).required();

const yupSchemaCreate = yup.object({
  ...objetcSchema,
  password: yup.string().required('A senha é obrigatória.'),
  confirmPassword: yup
    .string()
    .required('A confirmação da senha é obrigatória.')
});

const useControllerUsersFormPage = () => {
  const params = useParams();
  const user = useUser();
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const navigate = useNavigate();
  const { addToast } = useToast();
  const [filteredData] = React.useState({
    pageSize: 10,
    page: 1,
    search: ''
  });
  const { data, isLoading: isFetching, refetch } = useApiGet<CustomerUser>(
    apiPaths.customerUsers,
    { id: params.id, enabled: !!params.id }
  );
  const { mutate: mutateCreate, isPending: isCreating } = useApiPost<
    CustomerUser
  >(apiPaths.customerUsers);
  const { mutate: mutateUpdate, isPending: isUpdating } = useApiPut<
    CustomerUser
  >(apiPaths.customerUsers, params.id);

  const { mutate: mutateDelete, isPending: isDeleting } = useApiDelete(
    apiPaths.customerUsers,
    params.id
  );

  const {
    data: accessPolicyData,
    refetch: accessPolicyRefetch,
    isLoading: isLoadingPolicies
  } = useApiGet<Pagination<AccessPolicy>>('/authorization/access-policies', {
    params: filteredData
  });

  const mutate = params.id ? mutateUpdate : mutateCreate;

  const userCanPerformAction = useMemo(() => {
    if (params.id) {
      return user?.resources?.[TypeResource.USERS]?.includes(TypeAction.UPDATE);
    } else {
      return user?.resources?.[TypeResource.USERS]?.includes(TypeAction.CREATE);
    }
  }, [user, params.id]);

  const userCanDelete = useMemo(() => {
    return user?.resources?.[TypeResource.USERS]?.includes(TypeAction.DELETE);
  }, [user]);

  const isSaving = isCreating || isUpdating;

  const customerUsers = params.id ? data : undefined;

  const formMethods = useForm({
    resolver: yupResolver(params.id ? yupSchemaEdit : yupSchemaCreate),
    defaultValues: {
      isActive: 'false'
    }
  });

  const controlPrincipalUser = useWatch({
    control: formMethods.control,
    name: 'principalUser',
    defaultValue: 'false'
  });

  const onError = useFieldSetErrors(formMethods.setError);

  const handleOnSuccess = useCallback(() => {
    if (!params.id) {
      addToast(ToastType.SUCCESS, 'Usuário cadastrado');
      navigate(apiPaths.customerUsers);
    } else {
      addToast(ToastType.SUCCESS, 'Usuário atualizado');
    }
  }, [params.id, navigate, addToast]);

  const handleOnSubmit = useCallback(
    (data: any) => {
      if (!userCanPerformAction) return;
      const dataPolicies =
        data.policies && Array.isArray(data.policies)
          ? data.policies.map((policy: any) => policy.value)
          : [];

      const combinedData = {
        ...data,
        principalUser: String(data.principalUser) === 'true',
        isActive: String(data.isActive) === 'true',
        policies: String(data.principalUser) === 'true' ? [] : dataPolicies
      };

      mutate(combinedData as any, {
        onError,
        onSuccess: handleOnSuccess
      });
    },
    [userCanPerformAction, mutate, onError, handleOnSuccess]
  );

  const handleOnDelete = useCallback(() => {
    if (!params.id || !userCanDelete) return;
    mutateDelete(null as any, {
      onError: (response: any) => {
        addToast(
          ToastType.ERROR,
          response.errors?.detail?.toString() || 'Erro ao excluir usuário'
        );
      },
      onSuccess: () => {
        addToast(ToastType.SUCCESS, 'Usuário excluído');
        navigate(apiPaths.customerUsers);
      }
    });
  }, [addToast, mutateDelete, navigate, params.id, userCanDelete]);

  const accessPoliciesOptions = useMemo(() => {
    const data = accessPolicyData?.items || [];
    return data.map((policy) => ({
      label: policy.name,
      value: policy.id
    }));
  }, [accessPolicyData]);

  useEffect(() => {
    if (customerUsers) {
      formMethods.reset({
        firstName: customerUsers.firstName,
        lastName: customerUsers.lastName,
        email: customerUsers.email,
        isActive: String(customerUsers.isActive),
        principalUser: String(customerUsers.principalUser),
        policies:
          String(customerUsers.principalUser) === 'true'
            ? []
            : customerUsers.policies.map((policy: any) => ({
                label: policy.name,
                value: policy.id
              }))
      } as any);
    }
  }, [customerUsers, formMethods]);

  const [activeTab, setActiveTab] = useState<string>(
    NavUsersFormPage.INFORMATIONS
  );

  return {
    formMethods,
    userCanPerformAction,
    userCanDelete,
    isSaving,
    isDeleting,
    isFetching,
    customerUsers,
    accessPoliciesOptions,
    handleOnSubmit,
    handleOnDelete,
    showDeleteConfirmation,
    setShowDeleteConfirmation,
    accessPolicyRefetch,
    isLoadingPolicies,
    params,
    controlPrincipalUser,
    refetch,
    activeTab,
    setActiveTab
  };
};

export default useControllerUsersFormPage;
