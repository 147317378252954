import { useApiGet } from 'hooks/useApi';
import { useParams } from 'react-router-dom';
import Device from 'models/Device';

const useControllerDevicePage = () => {
  const params = useParams();

  const { data, isLoading: isFetching } = useApiGet<Device>(`/device`, {
    id: params.id,
    enabled: !!params.id
  });

  return {
    device: data,
    isLoading: isFetching
  };
};

export default useControllerDevicePage;
