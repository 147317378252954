import React, { useCallback, useMemo, useState, useEffect } from 'react';
import TextField from 'components/TextField';
import Form from 'components/Form';
import { useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Col, Row } from 'reactstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Icon from 'components/Icon';
import Button from 'components/Button';
import useFieldSetErrors from 'hooks/useFieldSetErrors';
import { useToast } from 'hooks/useToast';
import {
  useApiGet,
  useApiPost,
  useApiPut,
  useApiDelete,
  useApiPostFile
} from 'hooks/useApi';
import Modal from 'components/Modal';
import { Loader } from 'react-bootstrap-typeahead';
import FileField from 'components/FileField';
import AdminNotification from 'models/AdminNotification';
import ToastType from 'enum/ToastType';

const schema = yup
  .object({
    title: yup.string().required('O título é obrigatório.'),
    message: yup.string().required('A descrição é obrigatória.'),
    image: yup.mixed()
  })
  .required();

const AdminNotificationsFormPage = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const { data, isLoading: isFetching } = useApiGet<AdminNotification>(
    `/admin/notifications`,
    { id: params.id, enabled: !!params.id }
  );
  const { mutateAsync: mutateCreate, isPending: isCreating } = useApiPost<
    AdminNotification
  >('/admin/notifications');
  const { mutateAsync: mutateUpdate, isPending: isUpdating } = useApiPut<
    AdminNotification
  >('/admin/notifications', params.id);

  const { mutate: mutateDelete, isPending: isDeleting } = useApiDelete(
    '/admin/notifications',
    params.id
  );

  const { mutateAsync: uploadImage } = useApiPostFile(`/admin/notifications`);

  const mutate = params.id ? mutateUpdate : mutateCreate;

  const isSaving = isCreating || isUpdating;

  const adminNotifications = params.id ? data : undefined;

  const formMethods = useForm({
    resolver: yupResolver(schema)
  });

  const selectedImageFile = useWatch({
    control: formMethods.control,
    name: 'image',
    defaultValue: null
  });

  const onError = useFieldSetErrors(formMethods.setError);
  const { addToast } = useToast();

  const onSubmitHandler = useCallback(
    async (data: AdminNotification) => {
      try {
        const { image, ...dataWithoutImage } = data;

        const updatedData = {
          ...dataWithoutImage
        };

        const result = await mutate(updatedData as any);

        if (selectedImageFile) {
          const formData = new FormData();
          formData.append('image', selectedImageFile);
          formData.append('id', `${result.id}/image`);

          await uploadImage(formData as any);

          addToast(ToastType.SUCCESS, 'Logo salva com sucesso');
          navigate('/admin/notifications');
        }
        addToast(ToastType.SUCCESS, 'Notícia salva com sucesso');
        navigate('/admin/notifications');
      } catch (error) {
        console.error('Erro ao chamar mutate:', error);
        onError(error);
        addToast(ToastType.ERROR, 'Erro ao salvar notícia');
      }
    },
    [mutate, uploadImage, selectedImageFile, addToast, navigate, onError]
  );

  const handleOnDelete = useCallback(() => {
    if (!params.id) return;
    mutateDelete(null as any, {
      onError: (response: any) => {
        addToast(
          ToastType.ERROR,
          response.errors?.detail?.toString() || 'Erro ao excluir notícia'
        );
      },
      onSuccess: () => {
        addToast(ToastType.SUCCESS, 'Notícia excluída com sucesso');
        navigate('/admin/notifications');
      }
    });
  }, [addToast, mutateDelete, navigate, params.id]);

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0] || null;
    formMethods.setValue('image', file as Blob);
  };

  const imageSrc = useMemo(() => {
    return selectedImageFile
      ? URL.createObjectURL(new Blob([selectedImageFile]))
      : adminNotifications?.image;
  }, [selectedImageFile, adminNotifications?.image]);

  useEffect(() => {
    if (adminNotifications) {
      formMethods.reset({
        title: adminNotifications.title,
        message: adminNotifications.message
      } as any);
    }
  }, [adminNotifications, formMethods]);

  return (
    <div className="bg-white p-2 h-100">
      <Form onSubmit={onSubmitHandler} {...formMethods}>
        <Row className="d-flex align-items-center bg-white mb-2">
          <Col className="bg-white">
            <p className="mt-2 d-flex align-items-center">
              <Link
                to={'/admin/notifications'}
                className="btn btn-link text-dark text-decoration-none me-2"
              >
                <Icon icon="chevron-left" />
              </Link>
              <span className="h5 fw-bolder mb-0">Notificações</span>
            </p>
          </Col>
          <Col className="text-end">
            {params.id && (
              <Button
                type="button"
                color="danger"
                icon="trash"
                className="me-2"
                loading={isDeleting}
                onClick={() => setShowDeleteConfirmation(true)}
              >
                Excluir
              </Button>
            )}
            <Button color="primary" icon="floppy" loading={isSaving}>
              Salvar
            </Button>
          </Col>
        </Row>
        {isFetching ? (
          <Loader />
        ) : (
          <Row>
            <hr className="mb-4" />
            <TextField label="Título:" name="title" className="w-50" />
            <>
              <TextField
                label="Descrição:"
                name="message"
                placeholder="Escreva o texto da notícia aqui"
                type="textarea"
                className="w-50"
              />
              <div>
                <p className="mt-2">Imagem: </p>
                <div className="d-flex align-items-center">
                  <FileField
                    name="image"
                    accept="image/*"
                    type="file"
                    onChange={handleImageChange}
                  />
                  {selectedImageFile || adminNotifications?.image ? (
                    <img
                      className="ms-2 mb-2"
                      width={100}
                      src={imageSrc}
                      alt="Preview"
                    />
                  ) : null}
                </div>
              </div>
            </>
          </Row>
        )}
      </Form>
      <Modal
        primaryButtonAction={handleOnDelete}
        primaryButtonLabel="Excluir"
        title="Atenção!"
        isOpen={showDeleteConfirmation}
        showCloseButton={false}
        toggle={() => setShowDeleteConfirmation((x) => !x)}
        secondaryButtonAction={() => setShowDeleteConfirmation(false)}
        secondaryButtonLabel="Cancelar"
      >
        <p>
          Deseja realmente excluir a notificação? <br />
          <small>Essa alteração não poderá ser revertida.</small>
        </p>
      </Modal>
    </div>
  );
};

export default AdminNotificationsFormPage;
