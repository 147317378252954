import React, { useCallback, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useApiPost } from 'hooks/useApi';
import useFieldSetErrors from 'hooks/useFieldSetErrors';
import apiPaths from 'constants/apiPaths';

const useControllerForgotPassword = () => {
  const [showMessage, setShowMessage] = React.useState(false);
  const [visible, setVisible] = useState(true);

  const onDismiss = () => setVisible(false);
  const schema = useMemo(() => {
    return yup
      .object({
        email: yup.string().email().required()
      })
      .required();
  }, []);

  const methods = useForm({
    resolver: yupResolver(schema)
  });

  const onError = useFieldSetErrors(methods.setError);

  const { mutate: loginMutation, isPending } = useApiPost(
    apiPaths.recoverPassword
  );

  const onSubmitHandler = useCallback(
    (data: any) => {
      loginMutation(
        { ...data, web: true },
        { onError, onSuccess: () => setShowMessage(true) }
      );
    },
    [loginMutation, onError]
  );

  return {
    showMessage,
    methods,
    isPending,
    onSubmitHandler,
    visible,
    onDismiss
  };
};

export default useControllerForgotPassword;
