import TextField from 'components/TextField';
import Form from 'components/Form';
import { Col, Row, Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { Link } from 'react-router-dom';
import Icon from 'components/Icon';
import Button from 'components/Button';
import RadioChoicesFiled from 'components/RadioChoicesField';
import CampaignLayout from 'enum/CampaignLayout';
import {
  LabelFullScreen,
  LabelHalfSplit,
  LabelHorizontallySplit,
  LabelVerticallySplit
} from 'pages/Campaign/CampaignLabels';
import SelectField from 'components/SelectField';
import MediaGrid from 'components/MediaGrid';
import { withDragAndDropProvider } from 'contexts/dragAndDropProvider';
import { Loader } from 'react-bootstrap-typeahead';
import Modal from 'components/Modal';
import MediaEditor from 'components/MediaEditor';
import MediaCampaignPosition from 'enum/MediaCampaignPosition';
import CampaignPositionList from 'components/Campaign/CampaignPositionList';
import ModalCampaignsPreview from 'components/ModalCampaignsPreview';
import PlayerCampaignPreview from 'components/Campaign/PlayerCampaignPreview';
import useControllerCampaignEditPage from './_presenters/useControllerCampaignEditPage/useControllerCampaignEditPage';
import apiPaths from 'constants/apiPaths';

const CampaignEditPage = () => {
  const {
    formMethods,
    onSubmitHandler,
    userCanEdit,
    userCanDelete,
    campaign,
    typeOptionsOrientation,
    mediasCampaignB1,
    mediasCampaignB2,
    handleOnFinish,
    handleOnEditMedia,
    handleReOrderMedias,
    handleMediaClick,
    showDeleteConfirmation,
    setShowDeleteConfirmation,
    handleOnDelete,
    mediaToPreview,
    setMediaToPreview,
    openPreview,
    closePreview,
    isPreviewOpen,
    selectedCampaign,
    handleCampaignContinuosOnEnded,
    params,
    isFetching,
    isUpdating,
    isUpdatingMedia,
    isDeleting,
    isCreatingMedia,
    refMediaEditor
  } = useControllerCampaignEditPage();

  const typeOptionsScreen = [
    { value: CampaignLayout.FULL_SCREEN, label: <LabelFullScreen /> },
    {
      value: CampaignLayout.HORIZONTALLY_SPLIT,
      label: <LabelHorizontallySplit />
    },
    { value: CampaignLayout.VERTICALLY_SPLIT, label: <LabelVerticallySplit /> },
    { value: CampaignLayout.HALF_SCREEN, label: <LabelHalfSplit /> }
  ];

  const typeOptionsActive = [
    { value: true, label: 'Ativo' },
    {
      value: false,
      label: 'Inativo'
    }
  ];

  if (!params.id) return null;

  return (
    <div className="mx-3">
      <Form onSubmit={onSubmitHandler} {...formMethods}>
        <Row className="d-flex align-items-center">
          <Col className="mb-3">
            <Breadcrumb className="mt-4">
              <BreadcrumbItem active>
                <Link
                  to={apiPaths.tvCorporate}
                  className="text-decoration-none"
                >
                  Tv Corporativa
                </Link>
              </BreadcrumbItem>
              <BreadcrumbItem active>
                <Link
                  to={apiPaths.tvCorporate}
                  className="text-decoration-none"
                >
                  Campanha
                </Link>
              </BreadcrumbItem>
              <BreadcrumbItem className="text-gray-600 fw-medium">
                {campaign?.name}
              </BreadcrumbItem>
            </Breadcrumb>
            <h4 className="fw-bolder">{campaign?.name}</h4>
            <h5 className="text-gray-600 mt-4">Configurações da campanha</h5>
          </Col>
          <Col className="d-flex align-items-center justify-content-end">
            {userCanDelete && (
              <Button
                type="button"
                loading={isDeleting}
                className="button-outline delete me-2"
                onClick={() => setShowDeleteConfirmation(true)}
              >
                Excluir
              </Button>
            )}
            {userCanEdit && (
              <Button
                type="submit"
                className="btn btn-primary text-white button-register"
                loading={isUpdating}
              >
                Salvar
              </Button>
            )}
          </Col>
        </Row>
        {isFetching ? (
          <Loader />
        ) : (
          <Row className="me-0 pe-0">
            <div className="shadow bg-white form">
              <Col className="mx-4 mt-3">
                <TextField
                  label="Nome"
                  name="name"
                  defaultValue={campaign?.name}
                />
                <RadioChoicesFiled
                  label="Tipo de tela"
                  name="layoutType"
                  options={typeOptionsScreen}
                  classNameRadio="d-flex flex-wrap justify-content-between"
                  disabled
                  defaultValue={campaign?.layoutType}
                />
                <SelectField
                  label="Orientação visual"
                  name="screenOrientation"
                  options={typeOptionsOrientation}
                  disabled
                  defaultValue={campaign?.screenOrientation}
                />
                <SelectField
                  name="active"
                  label="Status"
                  options={typeOptionsActive}
                  defaultValue={String(campaign?.active)}
                />
              </Col>
            </div>
            <Row className="me-0 pe-0">
              <p className="mt-3 mb-2 d-flex align-items-center justify-content-between">
                <h5 className="text-gray-600">Montagem da Campanha</h5>
                <Button
                  className="white ms-2 border-0 text-white"
                  color="primary"
                  type="button"
                  onClick={() => openPreview(campaign!)}
                >
                  <Icon icon="collection-play" className="me-3 text-white" />
                  Reproduzir Campanha
                </Button>
              </p>
              <Col className="mt-3 ps-0 ms-0" md={6}>
                <div className="shadow bg-white form">
                  <div className="p-3">
                    <h5 className="text-gray-600">Gerenciamento de Mídias</h5>
                    <hr />
                    <MediaGrid title="Gerenciamento de Mídias" />
                  </div>
                </div>
              </Col>
              <Col className="mt-3 pe-0 me-0" md={6}>
                <div className="shadow bg-white form">
                  <div className="p-3">
                    <h5 className="text-gray-600">Posição B1</h5>
                    <CampaignPositionList
                      position={MediaCampaignPosition.B1}
                      idCampaign={params.id}
                      mediasCampaign={mediasCampaignB1}
                      onAddMedia={handleOnEditMedia}
                      onReOrderMedias={handleReOrderMedias}
                      isLoading={
                        isUpdatingMedia || isUpdating || isCreatingMedia
                      }
                      onClick={handleMediaClick}
                    />
                  </div>
                </div>
                {campaign?.layoutType !== CampaignLayout.FULL_SCREEN && (
                  <>
                    <div className="shadow bg-white form mt-3">
                      <div className="p-3">
                        <h5 className="text-gray-600">Posição B2</h5>
                        <CampaignPositionList
                          position={MediaCampaignPosition.B2}
                          idCampaign={params.id}
                          mediasCampaign={mediasCampaignB2}
                          onAddMedia={handleOnEditMedia}
                          onReOrderMedias={handleReOrderMedias}
                          isLoading={
                            isUpdatingMedia || isUpdating || isCreatingMedia
                          }
                          onClick={handleMediaClick}
                        />
                      </div>
                    </div>
                  </>
                )}
                <MediaEditor onFinish={handleOnFinish} ref={refMediaEditor} />
              </Col>
            </Row>
          </Row>
        )}
      </Form>
      <Modal
        primaryButtonAction={handleOnDelete}
        primaryButtonLabel="Excluir"
        title="Atenção!"
        isOpen={showDeleteConfirmation}
        showCloseButton={false}
        toggle={() => setShowDeleteConfirmation((x: any) => !x)}
        secondaryButtonAction={() => setShowDeleteConfirmation(false)}
        secondaryButtonLabel="Cancelar"
      >
        <p>
          Desaja realmente excluir a campanha? <br />
          <small>Essa alteração não poderá ser revertida.</small>
        </p>
      </Modal>
      <ModalCampaignsPreview
        mediaCampaingPreview={mediaToPreview!}
        isOpen={!!mediaToPreview}
        onClose={() => setMediaToPreview(undefined)}
      />
      {selectedCampaign && (
        <PlayerCampaignPreview
          isOpen={isPreviewOpen}
          onClose={closePreview}
          campaign={selectedCampaign}
          onEnded={handleCampaignContinuosOnEnded}
        />
      )}
    </div>
  );
};

export default withDragAndDropProvider(CampaignEditPage);
