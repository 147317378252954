import { Row, Col } from 'reactstrap';
import PlayerViewMode from 'enum/PlayerViewMode';
import SelectField from 'components/SelectField';
import NumberField from 'components/NumberField';
import Form from 'components/Form';
import Button from 'components/Button';
import PlayerRemoteActionsTypes from 'enum/PlayerRemoteActionsTypes';
import useControllerPlayerSettingsPage from './_presenters/useControllerPlayerSettingsPage/useControllerPlayerSettingsPage';

const PlayerSettingsPage = () => {
  const {
    isLoadingFilesCache,
    isLoadingFilesAndPlayerCache,
    isLoadingPlayerCache,
    playerViewModeValue,
    handleCacheAction,
    handleOnSubmitPlayer,
    formMethodsPlayer,
    playerViewModeOptions,
    options,
    isPlayerSettings,
    setIsLoadingFilesCache,
    setIsLoadingFilesAndPlayerCache,
    setIsLoadingPlayerCache
  } = useControllerPlayerSettingsPage();

  return (
    <div>
      <Form onSubmit={handleOnSubmitPlayer} {...formMethodsPlayer}>
        <Row className="shadow bg-white form">
          <Col sm="12">
            <div className="mt-2">
              <SelectField
                label="Exibição:"
                name="viewMode"
                options={playerViewModeOptions}
              />
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <div className="mt-2 w-100 me-2">
                <SelectField
                  label="Mover a Janela:"
                  name="allowMove"
                  options={options}
                  disabled={playerViewModeValue !== PlayerViewMode.WINDOW}
                />
              </div>
              <div className="mt-2 w-100">
                <SelectField
                  label="Redimensionar:"
                  name="allowResize"
                  options={options}
                  disabled={playerViewModeValue !== PlayerViewMode.WINDOW}
                />
              </div>
            </div>

            <div className="mt-2 d-flex align-items-center justify-content-between">
              <div className="w-100">
                <NumberField
                  label="Altura:"
                  name="height"
                  disabled={playerViewModeValue !== PlayerViewMode.WINDOW}
                />
              </div>
              <div className="ms-3 w-100">
                <NumberField
                  label="Largura:"
                  name="width"
                  disabled={playerViewModeValue !== PlayerViewMode.WINDOW}
                />
              </div>
            </div>
            <div className="mt-2 d-flex align-items-center justify-content-between">
              <div className="w-100">
                <NumberField
                  label="Posição X:"
                  name="xPosition"
                  disabled={playerViewModeValue !== PlayerViewMode.WINDOW}
                />
              </div>
              <div className="ms-3 w-100">
                <NumberField
                  label="Posição Y:"
                  name="yPosition"
                  disabled={playerViewModeValue !== PlayerViewMode.WINDOW}
                />
              </div>
            </div>
          </Col>
        </Row>
      </Form>
      <Row className="mt-3">
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <Button
              color="primary"
              className="me-2 text-white btn btn-primary"
              onClick={() =>
                handleCacheAction(
                  PlayerRemoteActionsTypes.CLEAN_FILES_CACHE,
                  'Caches de Arquivos excluídos com sucesso',
                  setIsLoadingFilesCache
                )
              }
              loading={isLoadingFilesCache}
            >
              Limpar Cache de Arquivos
            </Button>
            <Button
              color="primary"
              className="me-2 text-white btn btn-primary"
              onClick={() =>
                handleCacheAction(
                  PlayerRemoteActionsTypes.CLEAN_FILES_AND_PLAYER_CACHE,
                  'Caches de Arquivos e do Player excluídos com sucesso',
                  setIsLoadingFilesAndPlayerCache
                )
              }
              loading={isLoadingFilesAndPlayerCache}
            >
              Limpar Cache de Arquivos e do Player
            </Button>
            <Button
              color="primary"
              className="me-2 text-white btn btn-primary"
              onClick={() =>
                handleCacheAction(
                  PlayerRemoteActionsTypes.CLEAN_PLAYER_CACHE,
                  'Caches do Player excluídos com sucesso',
                  setIsLoadingPlayerCache
                )
              }
              loading={isLoadingPlayerCache}
            >
              Limpar Cache do Player
            </Button>
          </div>
          <div className="text-end">
            <Button
              color="primary"
              type="submit"
              className="text-white btn btn-primary"
              isLoading={isPlayerSettings}
            >
              Salvar as alterações
            </Button>
          </div>
        </div>
      </Row>
    </div>
  );
};

export default PlayerSettingsPage;
