import { Col, Row, Spinner } from 'reactstrap';
import SearchInput from 'components/SearchInput';
import PaginationNav from 'components/Pagination';
import UrlExternalItem from 'components/UrlExternal/UrlExternalItem';
import useControllerUrlExternalListDragPage from './_presenters/useControllerUrlExternalListDragPage/useControllerUrlExternalListDragPage';

const UrlExternalListDragPage = () => {
  const {
    urlExternal,
    isLoading,
    handleSearch,
    handlePageChange
  } = useControllerUrlExternalListDragPage();

  return (
    <div>
      <Row className="d-flex flex-column flex-md-row">
        <Col className="col-12 col-md mb-3 mt-3">
          <SearchInput
            onSearchTextChange={handleSearch}
            placeholder="Buscar"
            optionalsButtons
          />
        </Col>
      </Row>
      <div className="table-campaign-drag">
        {isLoading && (
          <tr>
            <td colSpan={2} className="text-center p-5">
              <Spinner />
            </td>
          </tr>
        )}
        {urlExternal?.items?.map((url) => (
          <UrlExternalItem key={url.id} urlExternal={url} />
        ))}
      </div>
      <div className="d-flex justify-content-between align-items-center flex-column flex-md-row mt-3">
        {urlExternal && (
          <PaginationNav
            onChange={handlePageChange}
            totalPages={urlExternal.totalPages}
            currentPage={urlExternal.page}
          />
        )}
      </div>
    </div>
  );
};

export default UrlExternalListDragPage;
