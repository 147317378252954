import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useNavigate, useParams } from 'react-router-dom';

import { useApiDelete, useApiGet, useApiPost, useApiPut } from 'hooks/useApi';
import ScreenOrientation, {
  screenOrientationDisplay
} from 'enum/ScreenOrientation';
import CampaignLayout from 'enum/CampaignLayout';
import { Campaign, MediaFileCampaign } from 'models/MediaFileCampaign';
import useFieldSetErrors from 'hooks/useFieldSetErrors';
import { useToast } from 'hooks/useToast';
import useUser from 'hooks/useUser';
import TypeResource from 'enum/TypeResource';
import TypeAction from 'enum/TypeAction';
import { EditedMedia, MediaEditorRef } from 'components/MediaEditor';
import MediaCampaignPosition from 'enum/MediaCampaignPosition';
import MediaFile from 'models/MediaFile';
import apiPaths from 'constants/apiPaths';
import ToastType from 'enum/ToastType';

const schema = yup
  .object({
    name: yup.string().required('O nome é obrigatório.'),
    screenOrientation: yup
      .mixed<ScreenOrientation>()
      .oneOf(Object.values(ScreenOrientation))
      .required(),
    layoutType: yup
      .mixed<CampaignLayout>()
      .oneOf(Object.values(CampaignLayout))
      .required(),
    active: yup.boolean().required('A campanha deve estar ativa ou inativa.')
  })
  .required();

const useControllerCampaignEditPage = () => {
  const navigate = useNavigate();
  const params = useParams();
  const user = useUser();
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const refMediaEditor = useRef<MediaEditorRef>(null);
  const { data, isLoading: isFetching, refetch } = useApiGet<Campaign>(
    `/media-manager/campaigns`,
    { id: params.id, enabled: !!params.id }
  );
  const { mutate: mutateEditCampaign, isPending: isUpdating } = useApiPut<
    Campaign
  >(apiPaths.campaigns, params.id);

  const { mutate: mutateMediaCampaign, isPending: isUpdatingMedia } = useApiPut<
    Campaign
  >(`/media-manager/campaigns/${params.id}/medias/orders`, '', {
    onSuccess: refetch
  });

  const { mutate: mutateDelete, isPending: isDeleting } = useApiDelete(
    apiPaths.campaigns,
    params.id
  );

  const { mutate: mutateCreateMedia, isPending: isCreatingMedia } = useApiPost<
    MediaFileCampaign & { mediaFile: any }
  >(`/media-manager/campaigns/${params.id}/medias`);

  const userCanEdit = useMemo(() => {
    return user?.resources?.[TypeResource.CAMPAIGNS]?.includes(
      TypeAction.UPDATE
    );
  }, [user]);

  const userCanDelete = useMemo(() => {
    return user?.resources?.[TypeResource.CAMPAIGNS]?.includes(
      TypeAction.DELETE
    );
  }, [user]);

  const formMethods = useForm({
    resolver: yupResolver(schema)
  });
  const onError = useFieldSetErrors(formMethods.setError);
  const { addToast } = useToast();

  const campaign = params.id ? data : undefined;

  const onSubmitHandler = useCallback(
    (data: Campaign) => {
      mutateEditCampaign(data, {
        onError,
        onSuccess: () => {
          addToast(ToastType.SUCCESS, 'Campanha atualizada com sucesso.');
          navigate('/media-manager/corporate-tv?tab=campaigns');
        }
      });
    },
    [addToast, mutateEditCampaign, navigate, onError]
  );

  const handleOnDelete = useCallback(() => {
    if (!userCanDelete) return;
    mutateDelete(null as any, {
      onError: (response: any) => {
        addToast(
          ToastType.ERROR,
          response.errors?.detail?.toString() || 'Erro ao excluir a campanha'
        );
      },
      onSuccess: () => {
        addToast(ToastType.SUCCESS, 'Campanha excluída com sucesso');
        navigate('/media-manager/corporate-tv?tab=campaigns');
      }
    });
  }, [addToast, mutateDelete, navigate, userCanDelete]);

  const typeOptionsOrientation = [
    {
      value: ScreenOrientation.PORTRAIT,
      label: screenOrientationDisplay(ScreenOrientation.PORTRAIT)
    },
    {
      value: ScreenOrientation.LANDSCAPE,
      label: screenOrientationDisplay(ScreenOrientation.LANDSCAPE)
    }
  ];

  useEffect(() => {
    if (campaign) {
      formMethods.reset({
        name: campaign.name,
        active: campaign.active,
        layoutType: campaign.layoutType,
        screenOrientation: campaign.screenOrientation
      } as any);
    }
  }, [campaign, formMethods]);

  const mediasCampaignB1 = useMemo(() => {
    return campaign?.medias?.filter(
      (media) => media.position === MediaCampaignPosition.B1
    );
  }, [campaign?.medias]);

  const mediasCampaignB2 = useMemo(() => {
    return campaign?.medias?.filter(
      (media) => media.position === MediaCampaignPosition.B2
    );
  }, [campaign?.medias]);

  const handleOnFinish = useCallback(
    (
      mediaFile: MediaFile,
      mediaEdited: EditedMedia,
      position?: MediaCampaignPosition
    ) => {
      const { url, cloudinaryJson } = mediaEdited;
      const newOrder =
        position === MediaCampaignPosition.B1
          ? mediasCampaignB1?.length
          : mediasCampaignB2?.length;
      mutateCreateMedia(
        {
          editedFile: url,
          order: newOrder || 0,
          duration: 5,
          cloudinaryJson,
          active: true,
          position: position,
          mediaFile: mediaFile.id
        },
        {
          onSuccess: () => {
            refetch();
          }
        }
      );
    },
    [mutateCreateMedia, refetch, mediasCampaignB1, mediasCampaignB2]
  );

  const handleOnEditMedia = useCallback(
    (mediaFile: MediaFile, position: MediaCampaignPosition) => {
      refMediaEditor?.current?.showEditor(mediaFile, {
        orientation: campaign?.screenOrientation!,
        position: position,
        layout: campaign?.layoutType!
      });
    },
    [campaign?.layoutType, campaign?.screenOrientation]
  );

  const handleReOrderMedias = useCallback(
    (medias: MediaFileCampaign[]) => {
      mutateMediaCampaign(
        medias.map((media) => ({
          mediaId: media.id,
          order: media.order
        })) as any
      );
    },
    [mutateMediaCampaign]
  );

  const [mediaToPreview, setMediaToPreview] = useState<MediaFileCampaign>();

  const handleMediaClick = useCallback((media: MediaFileCampaign) => {
    setMediaToPreview(media);
  }, []);

  const [selectedCampaign, setSelectedCampaign] = useState<Campaign | null>(
    null
  );

  const [isPreviewOpen, setIsPreviewOpen] = useState(false);

  const openPreview = (campaign: Campaign) => {
    setSelectedCampaign(campaign);
    setIsPreviewOpen(true);
  };

  const closePreview = () => {
    setIsPreviewOpen(false);
    setSelectedCampaign(null);
  };

  const handleCampaignContinuosOnEnded = () => {
    setIsPreviewOpen(true);
  };

  return {
    formMethods,
    onSubmitHandler,
    onError,
    userCanEdit,
    userCanDelete,
    campaign,
    typeOptionsOrientation,
    mediasCampaignB1,
    mediasCampaignB2,
    handleOnFinish,
    handleOnEditMedia,
    handleReOrderMedias,
    handleMediaClick,
    showDeleteConfirmation,
    setShowDeleteConfirmation,
    handleOnDelete,
    mediaToPreview,
    setMediaToPreview,
    openPreview,
    closePreview,
    isPreviewOpen,
    selectedCampaign,
    handleCampaignContinuosOnEnded,
    params,
    isFetching,
    isUpdating,
    isUpdatingMedia,
    isDeleting,
    isCreatingMedia,
    refMediaEditor
  };
};

export default useControllerCampaignEditPage;
