import { Col } from 'reactstrap';
import classnames from 'classnames';
import Menu from '../Menu';
import SidebarProps from './_presenters/props/sidebarProps';
import logo1 from 'assets/images/logo 3.png';

const Sidebar = ({ isOpen, toggleSidebar, routes }: SidebarProps) => {
  return (
    <Col
      className={classnames(
        'bg-white',
        'z-2',
        'pt-4',
        'pe-0',
        'ps-0',
        'app-sidebar',
        isOpen ? 'opened' : 'closed'
      )}
    >
      <img
        className={classnames(
          'd-none d-lg-block',
          !isOpen
            ? 'display-block position-absolute top-0 mt-4'
            : 'display-block position-absolute top-0 ms-5 mt-3'
        )}
        width={isOpen ? 129 : 100}
        src={logo1}
        alt="logo"
      />

      <Menu
        isOpen={isOpen}
        toggleSidebar={toggleSidebar}
        routes={routes}
        data-bs-popper="static"
      />
    </Col>
  );
};

export default Sidebar;
