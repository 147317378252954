import { Col, Row } from 'reactstrap';
import SearchInput from 'components/SearchInput';
import PaginationNav from 'components/Pagination';
import { Link } from 'react-router-dom';
import Table from 'components/Table';
import { Column } from 'components/Table/_presenters/props/tableComponentProps';
import Icon from 'components/Icon';
import useControllerCustomerUsersListPage from './_pretenders/useControllerCustomerUsersListPage/useControllerCustomerUsersListPage';

const CustomerUsersListPage = () => {
  const {
    customerUsersPage,
    isLoading,
    handleSearch,
    handlePageChange,
    handleClearFilter,
    goToEdit,
    userCanAdd
  } = useControllerCustomerUsersListPage();

  const columns = [
    {
      accessor: 'firstName',
      label: 'Nome',
      render: (name: string) => <span>{name}</span>
    },
    {
      accessor: 'email',
      label: 'E-mail',
      render: (email: string) => <span>{email}</span>
    },
    {
      accessor: 'policies',
      label: 'Política',
      render: (policies: any) =>
        policies && policies.length ? (
          <span className="text-primary fw-semibold bg-light-primary rounded p-1">
            {policies.map((policy: { name: string }) => policy.name).join(', ')}
          </span>
        ) : (
          <span className="text-primary fw-semibold bg-light-primary rounded p-1">
            Super usuário
          </span>
        )
    },
    {
      accessor: 'actions',
      label: 'Ações',
      render: () => <Icon icon="eye" className="text-primary" />
    }
  ] as Column<{
    id: string | number;
    firstName: string;
    email: string;
    policies: any[];
  }>[];

  return (
    <div className="padding-page">
      <Row className="d-flex flex-column flex-md-row">
        <Col className="col-12 col-md">
          <div>
            <p className="text-gray-600">Usuários</p>
            <h4 className="fw-bold">Usuários</h4>
            <span className="small-typography text-gray-600">
              Cadastre e veja as informações sobre os seus usuários.
            </span>
          </div>
        </Col>
        {userCanAdd && (
          <Col className="col-12 col-md align-text mt-3 mt-md-0">
            <Link
              to={'/customer/users/create'}
              className="btn btn-primary text-white button-register"
            >
              Cadastrar
            </Link>
          </Col>
        )}
      </Row>
      <Row className="d-flex align-items-center mt-2 flex-column flex-md-row">
        <Col className="col-12 col-md">
          <SearchInput
            onClearFilter={handleClearFilter}
            onSearchTextChange={handleSearch}
            placeholder="Buscar"
          />
        </Col>
      </Row>
      <Table
        data={customerUsersPage?.items || []}
        columns={columns}
        isLoading={isLoading}
        onRowClick={goToEdit}
      />
      <div className="d-flex justify-content-between align-items-center flex-column flex-md-row mt-3">
        {customerUsersPage && (
          <PaginationNav
            onChange={handlePageChange}
            totalPages={customerUsersPage.totalPages}
            currentPage={customerUsersPage.page}
          />
        )}
      </div>
    </div>
  );
};

export default CustomerUsersListPage;
