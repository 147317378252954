import React, { useCallback } from 'react';
import { ButtonProps } from 'reactstrap/types/lib/Button';
import Button from 'components/Button';

type FileInputButtonProps = {
  onFilesChange: (files: File[] | null) => void;
  loading?: boolean;
  icon?: string;
  accept?: string;
} & ButtonProps;
const FileInputButton = ({
  onFilesChange,
  accept,
  ...props
}: FileInputButtonProps) => {
  const fileInputRef = React.useRef<HTMLInputElement>(null);

  const handleFileChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const files = event.target.files;
      onFilesChange(files ? Array.from(files) : files);
    },
    [onFilesChange]
  );
  const handleOnClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      props.onClick?.(event);
      fileInputRef.current?.click();
    },
    [props]
  );

  return (
    <>
      <input
        type="file"
        ref={fileInputRef}
        className="d-none"
        onChange={handleFileChange}
        accept={accept}
        multiple
        data-testid="file-input"
      />
      <Button onClick={handleOnClick} {...props}>
        Anexar Arquivo
      </Button>
    </>
  );
};

export default FileInputButton;
