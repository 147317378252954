import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useApiPost } from 'hooks/useApi';
import useFieldSetErrors from 'hooks/useFieldSetErrors';
import SessionContext from 'contexts/session';
import { User } from 'models/User';
import { ROUTES_PATHS } from 'navigations/routes';
import apiPaths from 'constants/apiPaths';
import * as yup from 'yup';

declare const document: any;

const useControllerLoginUserPage = () => {
  const { setUser } = useContext(SessionContext);
  const [buttonColor, setButtonColor] = useState('light-primary');
  const schema = useMemo(() => {
    return yup
      .object({
        username: yup.string().required('O email é obrigatório.'),
        password: yup.string().required('A senha é obrigatória.')
      })
      .required();
  }, []);
  const methods = useForm({
    resolver: yupResolver(schema)
  });

  const { watch, setValue } = methods;

  const onError = useFieldSetErrors(methods.setError);

  const { mutate: loginMutation, isPending } = useApiPost<User>(apiPaths.login);
  useEffect(() => {
    const savedUsername = localStorage.getItem('username');
    const savedPassword = localStorage.getItem('password');
    if (savedUsername && savedPassword) {
      setValue('username', savedUsername);
      setValue('password', savedPassword);
      setButtonColor('primary');
    }
  }, [setValue]);

  const username = watch('username');
  const password = watch('password');

  useEffect(() => {
    if (username && password) {
      setButtonColor('primary');
    } else {
      setButtonColor('light-primary');
    }
  }, [username, password]);

  const onSuccess = useCallback(
    (response: User) => {
      setUser(response);
    },
    [setUser]
  );
  const onSubmitHandler = useCallback(
    (data: any) => {
      if (data.remember) {
        localStorage.setItem('username', data.username);
        localStorage.setItem('password', data.password);
      } else {
        localStorage.removeItem('username');
        localStorage.removeItem('password');
      }
      loginMutation(data, { onError, onSuccess });
    },
    [loginMutation, onError, onSuccess]
  );

  useEffect(() => {
    if (document.getUniqueMachineId) {
      window.location.href = ROUTES_PATHS.AUTH_PAGES.LOGIN_DEVICE;
    }
  }, []);

  return {
    methods,
    onSubmitHandler,
    isPending,
    buttonColor
  };
};

export default useControllerLoginUserPage;
