import {
  Col,
  Row,
  UncontrolledDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Spinner
} from 'reactstrap';
import SearchInput from 'components/SearchInput';
import PaginationNav from 'components/Pagination';
import { Link } from 'react-router-dom';
import Icon from 'components/Icon';
import useControllerCustomerCompanies from './_presenters/useControllerCustomerCompanies/useControllerCustomerCompanies';
import Table from 'components/Table';
import { Column } from 'components/Table/_presenters/props/tableComponentProps';
import Modal from 'components/Modal';
import groupImage from 'assets/images/Group 12.svg';
import apiPaths from 'constants/apiPaths';

function CustomerCompaniesListPage() {
  const {
    companiesData,
    isLoading,
    handleSearch,
    handlePageChange,
    goToEdit,
    userCanAdd,
    openDropdownId,
    toggleMenu,
    showDeleteConfirmation,
    setShowDeleteConfirmation,
    setCompanyIdToDelete,
    handleOnDelete,
    userCanDelete
  } = useControllerCustomerCompanies();

  const columns = [
    {
      accessor: 'name',
      label: 'Nome Fantasia',
      render: (name: string) => <span>{name}</span>
    },
    {
      accessor: 'razaoSocial',
      label: 'Razão Social',
      render: (razaoSocial: string) => <span>{razaoSocial}</span>
    },
    {
      accessor: 'cnpj',
      label: 'CNPJ',
      render: (cnpj: string) => <span>{cnpj || '-'}</span>
    },
    {
      accessor: 'actions',
      label: 'Ações',
      render: (_, row) => (
        <UncontrolledDropdown
          className="dropdown-table"
          isOpen={openDropdownId === row.id}
          toggle={() => toggleMenu(row.id)}
        >
          <DropdownToggle
            color="none"
            className="d-flex align-items-center border-0"
            onClick={(e) => e.stopPropagation()}
          >
            {openDropdownId === row.id ? (
              <Icon icon="chevron-up" className="text-primary ms-2" />
            ) : (
              <Icon icon="chevron-down" className="text-primary ms-2" />
            )}
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem header>AÇÕES</DropdownItem>
            <DropdownItem onClick={() => goToEdit(row)}>
              Informações
            </DropdownItem>
            {userCanDelete && (
              <DropdownItem
                onClick={() => {
                  setCompanyIdToDelete(row.id);
                  setShowDeleteConfirmation(true);
                }}
              >
                Excluir
              </DropdownItem>
            )}
          </DropdownMenu>
        </UncontrolledDropdown>
      )
    }
  ] as Column<{
    id: string;
    name: string;
    razaoSocial: string;
    cnpj: string;
  }>[];

  return (
    <div className="padding-page">
      <Row className="d-flex flex-column flex-md-row">
        <Col className="col-12 col-md">
          <p className="mt-2">
            <div>
              <p className="text-gray-600">Empresas</p>
              <h4 className="fw-bold">Empresas</h4>
              <span className="small-typography text-gray-600">
                Cadastre e veja as informações sobre as empresas.
              </span>
            </div>
          </p>
        </Col>
        {userCanAdd && companiesData && companiesData.items.length !== 0 && (
          <Col className="col-12 col-md align-text mt-3 mt-md-0">
            <Link
              to={`${apiPaths.customerCompanies}/create`}
              className="btn btn-primary text-white button-register"
            >
              Cadastrar
            </Link>
          </Col>
        )}
      </Row>
      <Row className="d-flex align-items-center mt-2 flex-column flex-md-row">
        <Col className="col-12 col-md">
          <SearchInput
            onSearchTextChange={handleSearch}
            placeholder="Buscar"
            disabled={!companiesData || companiesData.items.length === 0}
          />
        </Col>
      </Row>
      {isLoading ? (
        <tr>
          <td colSpan={2} className="text-center p-5">
            <Spinner />
          </td>
        </tr>
      ) : companiesData && companiesData.items.length !== 0 ? (
        <Table
          data={companiesData?.items || []}
          columns={columns}
          isLoading={isLoading}
        />
      ) : (
        <div
          className="d-flex flex-column align-items-center justify-content-center position-absolute top-50 start-50 translate-middle 
        top-md-40 start-md-50 top-sm-25 start-sm-50"
        >
          <img
            src={groupImage}
            alt="Nenhum resultado encontrado"
            width={151}
            height={94.61}
            className="mb-3"
          />
          <h4 className="text-primary fw-bold mb-2">Cadastre a empresa</h4>
          <span className="small-typography text-gray-600  mb-1">
            Cadastre e veja as informações sobre as empresas.
          </span>
          <Link
            to={'/customer/companies/create'}
            className="btn btn-primary text-white button-register"
          >
            Cadastrar
          </Link>
        </div>
      )}
      <div className="d-flex justify-content-between align-items-center flex-column flex-md-row mt-3">
        {companiesData && companiesData.items.length !== 0 && (
          <PaginationNav
            onChange={handlePageChange}
            totalPages={companiesData.totalPages}
            currentPage={companiesData.page}
          />
        )}
      </div>
      <Modal
        isOpen={showDeleteConfirmation}
        title="Confirmar Exclusão"
        toggle={() => setShowDeleteConfirmation(false)}
        primaryButtonLabel="Excluir"
        primaryButtonAction={handleOnDelete}
        secondaryButtonLabel="Cancelar"
        secondaryButtonAction={() => setShowDeleteConfirmation(false)}
      >
        <p>
          Tem certeza de que deseja excluir esta empresa? Essa ação não pode ser
          desfeita.
        </p>
      </Modal>
    </div>
  );
}

export default CustomerCompaniesListPage;
