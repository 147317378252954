import React from 'react';
import { FormFeedback, FormGroup, Input } from 'reactstrap';
import { InputProps } from 'reactstrap/types/lib/Input';
import { useController } from 'react-hook-form';
import Icon from 'components/Icon';

type Props = InputProps & {
  name: string;
  label?: string;
  valueDefault?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const FileField = ({
  name,
  label,
  valueDefault = '',
  onChange,
  ...props
}: Props) => {
  const {
    field: { value, ...field },
    fieldState: { invalid, error }
  } = useController({ name, defaultValue: valueDefault });

  return (
    <FormGroup>
      <Input
        id="file-upload"
        type={props.type || 'text'}
        {...props}
        {...field}
        invalid={invalid}
        onChange={(e) => {
          field.onChange(e);
          onChange && onChange(e);
        }}
        className="d-none"
      />
      <label htmlFor="file-upload" className="custom-file-label w-100 mt-1">
        <div className="d-flex justify-content-between w-100">
          <span className="text-primary">Escolher ficheiro</span>
          <Icon icon="upload" className="me-2 upload-icon" />
        </div>
      </label>
      {error && <FormFeedback>{error.message}</FormFeedback>}
    </FormGroup>
  );
};

export default FileField;
