import React, { ChangeEvent, useCallback } from 'react';
import { useToast } from 'hooks/useToast';
import { useApiPostFile } from 'hooks/useApi';
import { ImageUploadProps } from '../props/imageUploadProps';
import ToastType from 'enum/ToastType';

const useControllerImageUpload = ({
  onSuccess,
  url,
  keyName
}: ImageUploadProps) => {
  const { addToast } = useToast();
  const ref = React.useRef<HTMLInputElement>(null);

  const { mutate, isPending } = useApiPostFile(url);

  const handleResetInput = useCallback(() => {
    if (ref.current) {
      ref.current.value = '';
      ref.current.type = 'text';
      ref.current.type = 'file';
    }
  }, []);

  const handleOnSuccess = useCallback(
    (data: any) => {
      onSuccess(data);
      handleResetInput();
    },
    [handleResetInput, onSuccess]
  );

  const handleImageChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files && event.target.files[0];
      const formData = new FormData();
      formData.append(keyName, file as Blob);
      mutate(formData as any, {
        onSuccess: handleOnSuccess,
        onError: (error: any) => {
          const { errors } = error || ({} as any);
          const message =
            errors?.status === 422 ? errors?.detail?.[keyName] : errors?.detail;
          addToast(ToastType.ERROR, message || 'Erro no upload da imagem');
          handleResetInput();
        }
      });
    },
    [addToast, handleOnSuccess, handleResetInput, mutate, keyName]
  );

  return {
    handleImageChange,
    ref,
    isPending
  };
};

export default useControllerImageUpload;
