import { Button, Col, Row } from 'reactstrap';
import group from 'assets/images/Group 4.png';
import Form from 'components/Form';
import TextField from 'components/TextField';
import { Link } from 'react-router-dom';
import PasswordField from 'components/PasswordField';
import RadioChoicesFiled from 'components/RadioChoicesField';
import CheckboxField from 'components/CheckboxField';
import Modal from 'components/Modal';
import Icon from 'components/Icon';
import useControllerSignUp from 'pages/SignUp/_presenters/useControllerSignUp/useControllerSignUp';
import TypeCustomer from 'enum/TypeCustomer';
import { ROUTES_PATHS } from 'navigations/routes';
import classNames from 'classnames';
import NumberMaskInputField from 'components/NumberMaskInputField';

const SignupPage = () => {
  const {
    methods,
    onSubmitHandler,
    isPending,
    showSuccessModal,
    toggleSuccessModal,
    typeOptions,
    cpfCnpjType,
    redirectUser
  } = useControllerSignUp();
  return (
    <Row className={classNames('d-flex align-items-center page-direction')}>
      <Col className="image-col">
        <img src={group} alt="People talking" className="image-fill" />
      </Col>
      <Col className="d-flex flex-column align-items-center">
        <div
          className={classNames(
            'shadow-lg p-3 mb-5 bg-body-tertiary rounded box-size'
          )}
        >
          <h4 className="py-2 fw-bold">Crie sua conta</h4>
          <Form onSubmit={onSubmitHandler} {...methods}>
            <p className="me-5">Qual o seu perfil?</p>
            <div className="d-flex justify-content-between">
              <div>
                <RadioChoicesFiled
                  name="type"
                  options={typeOptions}
                  classNameRadio="d-flex"
                />
              </div>
            </div>
            <TextField
              label="Nome Completo"
              type="text"
              name="name"
              placeholder="Digite seu nome completo"
            />
            <TextField
              label="E-mail"
              type="text"
              name="email"
              placeholder="Digite seu e-mail"
            />
            <NumberMaskInputField
              label={cpfCnpjType === TypeCustomer.PF ? 'CPF' : 'CNPJ'}
              type="text"
              name="cpfCnpj"
              placeholder={
                cpfCnpjType === TypeCustomer.PF
                  ? 'Digite seu CPF'
                  : 'Digite seu CNPJ'
              }
              maxLength={cpfCnpjType === TypeCustomer.PF ? 11 : 14}
              mask={
                cpfCnpjType === TypeCustomer.PF
                  ? '999.999.999-99'
                  : '99.999.999/9999-99'
              }
            />
            <PasswordField
              label="Senha"
              type="password"
              name="password"
              placeholder="Digite sue senha"
            />
            <PasswordField
              label="Confirme sua senha"
              type="password"
              name="confirmPassword"
              placeholder="Confirme sua senha"
            />
            <div className="py-2">
              <CheckboxField
                name="check"
                label={
                  <span>
                    Ao se cadastrar você concorda com os nossos{' '}
                    <a href="*">termos de uso e privacidade</a>
                  </span>
                }
              />
            </div>
            <div className="py-1">
              <Button
                color={methods.formState.isValid ? 'primary' : 'light-primary'}
                className="w-100 text-white mb-3"
                disabled={isPending}
              >
                Acessar
              </Button>

              <Link
                to={ROUTES_PATHS.AUTH_PAGES.LOGIN}
                className="btn btn-outline-primary w-100 text-indigo-500"
              >
                Retornar
              </Link>
            </div>
          </Form>
        </div>

        <div>
          <Modal
            classNameHeader="d-flex justify-content-center text-center"
            classNameFooter="d-flex justify-content-center"
            classNameBody="text-center"
            isOpen={showSuccessModal}
            toggle={toggleSuccessModal}
            title={
              <>
                <Icon
                  icon="check-circle"
                  width={50}
                  height={50}
                  className="text-success"
                />
                <p className="m-1">Sucesso!</p>
              </>
            }
            primaryButtonLabel="Continuar"
            primaryButtonAction={redirectUser}
            showCloseButton={false}
          >
            Seu usuário foi criado com sucesso.
          </Modal>
        </div>
      </Col>
    </Row>
  );
};

export default SignupPage;
