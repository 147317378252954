import { useCallback, useContext, useState } from 'react';

import SessionContext from 'contexts/session';

const useControllerDashboardLayout = () => {
  const { user, logout } = useContext(SessionContext);

  const onLogoutHandler = useCallback(() => {
    logout();
  }, [logout]);

  const [isOpen, setIsOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);
  const toggleMenu = () => setDropdownOpen((prevState) => !prevState);

  return {
    user,
    onLogoutHandler,
    isOpen,
    toggle,
    dropdownOpen,
    toggleMenu
  };
};

export default useControllerDashboardLayout;
