import React, { useState, useMemo } from 'react';
// @ts-ignore
import { useDrag } from 'react-dnd';
import { Campaign } from 'models/MediaFileCampaign';
import { campaignLayoutDisplay } from 'enum/CampaignLayout';
import { screenOrientationDisplay } from 'enum/ScreenOrientation';
import Button from 'components/Button';
import PlayerCampaignPreview from 'components/Campaign/PlayerCampaignPreview';

type CampaignsItemProps = {
  campaign: Campaign;
};

const CampaignsItem = ({ campaign }: CampaignsItemProps) => {
  const [{ isDragging }, drag] = useDrag({
    type: 'Campaign',
    item: { id: campaign.id },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  });

  const [selectedCampaign, setSelectedCampaign] = useState<Campaign | null>(
    null
  );

  const [isPreviewOpen, setIsPreviewOpen] = useState(false);

  const openPreview = (campaign: Campaign) => {
    setSelectedCampaign(campaign);
    setIsPreviewOpen(true);
  };

  const closePreview = () => {
    setIsPreviewOpen(false);
    setSelectedCampaign(null);
  };

  const handleCampaignContinuosOnEnded = () => {
    setIsPreviewOpen(true);
  };

  const totalDuration = useMemo(() => {
    return campaign.medias?.reduce(
      (total, media) => total + (media.duration || 0),
      0
    );
  }, [campaign.medias]);

  return (
    <div ref={drag}>
      <div>
        {!isDragging ? (
          <div className="d-flex justify-content-between align-items-center border-top p-3">
            <div className="mt-2 w-100 me-4">
              <small>Nome </small>
              <br />
              <b>{campaign.name}</b>
            </div>
            <div className="mt-2 w-100 me-4">
              <small>Duração </small>
              <br />
              <b>{totalDuration} segundos</b>
            </div>
            <div className="mt-2 w-100 me-4">
              <small>Layout </small>
              <br />
              <b>{campaignLayoutDisplay(campaign.layoutType)}</b>
            </div>
            <div className="mt-2 w-100 me-4">
              <small>Orientação </small>
              <br />
              <b>{screenOrientationDisplay(campaign.screenOrientation)}</b>
            </div>
            <div className="mt-2 w-100 ms-4">
              <small>Campanha </small>
              <br />
              <Button
                type="button"
                color="transparent"
                icon="collection-play"
                className="text-primary"
                onClick={() => openPreview(campaign)}
              />
            </div>
          </div>
        ) : (
          <div className="campaign-medias-list-item">
            <div className="bg-dark-subtle"></div>
          </div>
        )}
      </div>
      {selectedCampaign && (
        <PlayerCampaignPreview
          isOpen={isPreviewOpen}
          onClose={closePreview}
          campaign={selectedCampaign}
          onEnded={handleCampaignContinuosOnEnded}
        />
      )}
    </div>
  );
};

export default CampaignsItem;
