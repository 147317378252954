import { Col, Row, Spinner } from 'reactstrap';
import SearchInput from 'components/SearchInput';
import PaginationNav from 'components/Pagination';
import CampaignsItem from 'components/Campaign/CampaignsItem';
import useControllerCampaignListDragPage from './_presenters/useControllerCampaignListDragPage/useControllerCampaignListDragPage';

type CampaignListDragPageProps = {
  screenOrientation: string;
};

const CampaignListDragPage = ({
  screenOrientation
}: CampaignListDragPageProps) => {
  const {
    filteredCampaigns,
    handleSearch,
    handlePageChange,
    isLoading,
    campaignsData
  } = useControllerCampaignListDragPage({ screenOrientation });

  return (
    <div>
      <Row className="d-flex flex-column flex-md-row">
        <Col className="col-12 col-md mb-3 mt-3">
          <SearchInput
            onSearchTextChange={handleSearch}
            placeholder="Buscar"
            optionalsButtons
          />
        </Col>
      </Row>
      <div className="table-campaign-drag">
        {isLoading && (
          <tr>
            <td colSpan={2} className="text-center p-5">
              <Spinner />
            </td>
          </tr>
        )}
        {filteredCampaigns?.map((campaign) => (
          <CampaignsItem key={campaign.id} campaign={campaign} />
        ))}
      </div>
      <div className="d-flex justify-content-between align-items-center flex-column flex-md-row mt-3">
        {campaignsData && (
          <PaginationNav
            onChange={handlePageChange}
            totalPages={campaignsData.totalPages}
            currentPage={campaignsData.page}
          />
        )}
      </div>
    </div>
  );
};

export default CampaignListDragPage;
