import React from 'react';
import { Table, Spinner } from 'reactstrap';
import TableComponentProps from './_presenters/props/tableComponentProps';

const TableComponent = <T extends { id: string | number }>({
  data,
  columns,
  isLoading,
  onRowClick
}: TableComponentProps<T>) => {
  return (
    <Table hover responsive size="lg" className="shadow mt-3">
      <thead>
        <tr>
          {columns.map((col) => (
            <th
              key={String(col.accessor)}
              className="fw-medium text-title-color"
            >
              {col.label}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {isLoading ? (
          <tr>
            <td colSpan={columns.length} className="text-center p-5">
              <Spinner />
            </td>
          </tr>
        ) : (
          data.map((row) => (
            <tr key={row.id} role="button" onClick={() => onRowClick?.(row)}>
              {columns.map((col) => (
                <td key={String(col.accessor)}>
                  {col.accessor === 'actions'
                    ? col.render?.(undefined, row)
                    : col.render
                    ? col.render(row[col.accessor as keyof T], row)
                    : String(row[col.accessor as keyof T] ?? '')}
                </td>
              ))}
            </tr>
          ))
        )}
      </tbody>
    </Table>
  );
};

export default TableComponent;
