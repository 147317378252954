import React, { useState, useEffect } from 'react';
import { TabContent, TabPane, Breadcrumb, BreadcrumbItem } from 'reactstrap';
import classNames from 'classnames';
import NavDevices from './_presenters/enum/navDevices';
import { useSearchParams } from 'react-router-dom';
import DeviceGroupListPage from 'pages/DeviceGroup/DeviceGroupListPage';
import DeviceListPage from 'pages/DeviceList/DeviceListPage';

const Devices = () => {
  const [activeTab, setActiveTab] = useState(NavDevices.DEVICES_LIST);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const tabParam = searchParams.get('tab');
    switch (tabParam) {
      case 'device-list':
        setActiveTab(NavDevices.DEVICES_LIST);
        break;
      case 'device-group':
        setActiveTab(NavDevices.DEVICES_GROUP);
        break;
      default:
        setActiveTab(NavDevices.DEVICES_LIST);
    }
  }, [searchParams]);

  return (
    <div className="mx-3">
      <div>
        <Breadcrumb className="text-gray-600 fw-medium mt-4">
          <BreadcrumbItem>Dispositivos</BreadcrumbItem>
        </Breadcrumb>
        <h4 className="fw-bold mt-3">Dispositivos</h4>
        <span className="small-typography text-gray-600">
          Realize a gestão do conteúdo de seus dispositivos.
        </span>
        <div className="d-flex align-items-center mt-4">
          <div className="navlink">
            <div
              className={classNames('me-5 navlink', {
                active: activeTab === NavDevices.DEVICES_LIST
              })}
              onClick={() => setActiveTab(NavDevices.DEVICES_LIST)}
            >
              Lista de Dispositivos
            </div>
          </div>
          <div className="navlink">
            <div
              className={classNames('me-5 navlink', {
                active: activeTab === NavDevices.DEVICES_GROUP
              })}
              onClick={() => setActiveTab(NavDevices.DEVICES_GROUP)}
            >
              Grupo de Dispositivos
            </div>
          </div>
        </div>
        <hr className="color-line" />
        <TabContent activeTab={activeTab}>
          <TabPane tabId={NavDevices.DEVICES_LIST}>
            <div className="mt-3">
              <DeviceListPage />
            </div>
          </TabPane>
          <TabPane tabId={NavDevices.DEVICES_GROUP}>
            <div className="mt-2">
              <DeviceGroupListPage />
            </div>
          </TabPane>
        </TabContent>
      </div>
    </div>
  );
};

export default Devices;
