import { useCallback, useContext, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useApiPost } from 'hooks/useApi';
import useFieldSetErrors from 'hooks/useFieldSetErrors';
import SessionContext from 'contexts/session';
import { useNavigate } from 'react-router-dom';
import TypeCustomer from 'enum/TypeCustomer';
import { User } from 'models/User';
import apiPaths from 'constants/apiPaths';

const useControllerLoginUserPage = () => {
  const yupSchema = yup
    .object({
      email: yup.string().email().required(),
      password: yup
        .string()
        .required('A senha é obrigatória.')
        .test(
          'password-strength',
          'A senha deve ter no mínimo 8 caracteres e conter ao menos uma letra.',
          (value) => {
            if (!value) return false;
            const hasMinLength = value.length >= 8;
            const hasLetter = /[a-zA-Z]/.test(value);
            return hasMinLength && hasLetter;
          }
        ),
      name: yup.string().required('O nome é obrigatório.'),
      cpfCnpj: yup
        .string()
        .required('O CPF ou CNPJ é obrigatório.')
        .min(
          11,
          'Se for CPF, deve ter no mínimo 11 caracteres. E se for CNPJ, deve ter no mínimo 14 caracteres.'
        ),
      confirmPassword: yup
        .string()
        .required('Confirmar a senha é obrigatório.'),
      check: yup.boolean().oneOf([true], 'Este campo é obrigatório').required(),
      type: yup
        .mixed<TypeCustomer>()
        .oneOf(Object.values(TypeCustomer))
        .required()
    })
    .required();
  const { setUser } = useContext(SessionContext);
  const navigate = useNavigate();
  const [localUser, setLocalUser] = useState<User>();
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const toggleSuccessModal = () => setShowSuccessModal(!showSuccessModal);
  const typeOptions = [
    { value: TypeCustomer.PF, label: 'Pessoa Física' },
    { value: TypeCustomer.PJ, label: 'Pessoa Jurídica' }
  ];
  const methods = useForm({
    resolver: yupResolver(yupSchema)
  });
  const { control } = methods;

  const cpfCnpjType = useWatch({
    control,
    name: 'type',
    defaultValue: TypeCustomer.PF
  });

  const onError = useFieldSetErrors(methods.setError);

  const { mutate: loginMutation, isPending } = useApiPost<User>(
    apiPaths.signUp
  );

  const onSubmitHandler = useCallback(
    (data: any) => {
      loginMutation(data, {
        onError,
        onSuccess: (response) => {
          setLocalUser(response);
          setShowSuccessModal(true);
        }
      });
    },
    [loginMutation, onError]
  );

  const redirectUser = useCallback(() => {
    setUser(localUser);
    navigate('/');
  }, [setUser, localUser, navigate]);

  return {
    onSubmitHandler,
    methods,
    cpfCnpjType,
    typeOptions,
    showSuccessModal,
    toggleSuccessModal,
    redirectUser,
    isPending
  };
};

export default useControllerLoginUserPage;
