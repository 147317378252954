import { Input, InputGroup, InputGroupText } from 'reactstrap';
import Icon from 'components/Icon';
import Button from '../Button';
import SearchInputProps from './_presenters/props/searchInputProps';
import useControllerSearchInput from './_presenters/useControllerSearchInput/useControllerSearchInput';

const SearchInput = ({
  onSearchTextChange,
  onSortChange,
  onClearFilter,
  onFilterClick,
  placeholder,
  disabled,
  optionalsButtons
}: SearchInputProps) => {
  const {
    sortOrder,
    ref,
    handleClearInput,
    toggleSortOrder
  } = useControllerSearchInput({
    onSearchTextChange,
    onClearFilter,
    onSortChange
  });

  return (
    <div
      className={`d-flex align-items-center w-100 ${
        disabled ? 'search-input-disabled' : ''
      }`}
    >
      <div>
        <InputGroup
          className={
            optionalsButtons ? 'optionalsButtons' : 'input-group-search'
          }
        >
          <InputGroupText
            className={`text-primary bg-white ${
              disabled ? 'text-gray-500' : ''
            }`}
          >
            <Icon icon="search" width={20} height={20} />
          </InputGroupText>
          <Input
            className="form-control-search border-start-0 bg-white"
            id={'search'}
            type="text"
            placeholder={placeholder}
            innerRef={ref}
            defaultValue=""
            disabled={disabled}
          />
        </InputGroup>
      </div>
      <div className={optionalsButtons ? 'd-none' : 'd-block'}>
        <Button
          className="button-search filter d-flex align-items-center"
          onClick={onFilterClick}
        >
          <span
            className={`d-none d-sm-block ${disabled ? 'text-gray-500' : ''}`}
          >
            {' '}
            Filtrar por
          </span>
          <Icon
            icon="filter"
            width={20}
            height={20}
            className={`text-primary ms-1 ${disabled ? 'text-gray-500' : ''}`}
          />
        </Button>
      </div>
      <div className={optionalsButtons ? 'd-none' : 'd-block'}>
        <Button
          className="button-search order d-flex align-items-center"
          onClick={toggleSortOrder}
        >
          <span
            className={`d-none d-sm-block ${disabled ? 'text-gray-500' : ''}`}
          >
            Ordenar por
          </span>
          <Icon
            icon={sortOrder === 'asc' ? 'chevron-down' : 'chevron-up'}
            width={20}
            height={20}
            className={`text-primary ms-1 ${disabled ? 'text-gray-500' : ''}`}
          />
        </Button>
      </div>
      <div className={optionalsButtons ? 'd-none' : 'd-block'}>
        <Button
          color="link"
          onClick={handleClearInput}
          className="filter-clean d-flex align-items-center"
        >
          <Icon
            icon="x-circle"
            width={15}
            height={15}
            className={`text-primary me-1 ${
              disabled ? 'text-gray-500' : 'text-red-500'
            }`}
          />
          <span
            className={`d-none d-sm-block ${disabled ? 'text-gray-500' : ''}`}
          >
            Limpar filtros
          </span>
        </Button>
      </div>
    </div>
  );
};

export default SearchInput;
