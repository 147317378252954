import React, { useCallback } from 'react';

type InternalsNewsImageItemProps = {
  editedMedia: string;
  onClick?: (url: string) => void;
};

const InternalsNewsImageItem = ({
  editedMedia,
  onClick
}: InternalsNewsImageItemProps) => {
  const handleMediaClick = useCallback(() => {
    onClick?.(editedMedia);
  }, [editedMedia, onClick]);

  return (
    <div className="h-100 position-relative">
      <div
        role="button"
        onClick={handleMediaClick}
        className="img-thumbnail border-0 position-absolute top-50 start-50 translate-middle"
      >
        <img
          alt="Imagem editada"
          src={editedMedia}
          className="w-100 m-auto d-block"
        />
      </div>
    </div>
  );
};

export default InternalsNewsImageItem;
